import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NothingSelected } from '../NothingSelected'
import { Sidebarlist } from '../../Sidebarlist'
import { NavBarAddress } from '../NavBar'
import { AddresScreen1 } from '../../address/AddresScreen'
import { SidebarAddress } from './SidebarAddress'
import { Modals } from '../../modal/Modal'

export const AddresScreen = () => {

    const { active } = useSelector(state => state.address)
    const ui = useSelector(state => state.ui);
    const { darkMode } = useSelector(state => state.ui);

    useEffect(() => {
        darkMode ? document.body.style = 'background: #121212;' : document.body.style = 'background: white;';
    }, [darkMode])


    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBarAddress />
                <main>
                    {
                        (active)
                            ? (<AddresScreen1 />)
                            : (<NothingSelected type="address" />)
                    }
                </main>
                <Modals type="address" />
                {/* <SidebarAddress /> */}
            </div>
        </div>
    )
}
