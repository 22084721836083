import { types } from "../types/types"

const initialState = {
    emails: [],
    active: null
}

export const emailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.emailsActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.emailsAddNew:
            return {
                ...state,
                emails: [action.payload, ...state.emails]
            }
        case types.emailsLoad:
            return {
                ...state,
                emails: [...action.payload]
            }
        case types.emailsUpdated:
            return {
                ...state,
                emails: state.emails.map(
                    email => email.id === action.payload.id
                        ? action.payload.email
                        : email
                ),
                active: action.payload.email
            }
        case types.emailsDelete:
            return {
                ...state,
                active: null,
                emails: state.emails.filter(email => email.id !== action.payload)
            }
        case types.emailsLogoutCleaning:
            return {
                ...state,
                active: null,
                notes: []
            }

        case types.setActiveEmail:
            return {
                ...state,
                active: null,
            }
            
        default:
            return state
    }

}

