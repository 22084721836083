import { db } from '../firebase/firebase-config'

export const loadVideos = async ( uid ) => {
    const videosSnap = await db.collection(`${ uid }/global/videos`).get();
    const videos = [];
    videosSnap.forEach ( snapChild => {
        videos.push({
            id: snapChild.id,
            ...snapChild.data()
        })
    });

    console.log(videos);
    
    return videos;
}