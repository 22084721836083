import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { en, es } from '../types/traslate';
import Switch from "react-switch";

import { useHistory } from 'react-router';

import { setActiveVideo } from '../actions/videos';
import { setActivePhoto } from '../actions/photos';
import { setActivePassword } from '../actions/passwords';
import { setActiveNotes } from '../actions/notes';
import { setActiveEmail } from '../actions/emails';
import { setActiveAddress } from '../actions/address';
import { setModalOpen, setPath, setRutas } from '../actions/helpers';
import { startLogout } from '../actions/auh';


export const SidebarData = ({ handleDarkMode }) => {

    const ui = useSelector(state => state.ui);
    const { isEdit, isNew } = useSelector(state => state.helpers);
    const dispatch = useDispatch();
    const { modalIsOpen } = useSelector(state => state.helpers);
    const [rutaSeleccionada, setRutaSeleccionada] = useState('');
    const history = useHistory();

    const SidebarData = [
        {
            title: ui.spanish ? es.photoVault.id : en.photoVault.id,
            path: '/',
            icons: <FaIcons.FaCamera />,
            cName: 'nav-text'
        },
          {
             title: ui.spanish ? es.emails.id : en.emails.id,
             path: '/emails',
             icons: <FaIcons.FaEnvelope />,
             cName: 'nav-text'
         }, 
          {
             title: ui.spanish ? es.password.id : en.password.id,
             path: '/password',
             icons: <FaIcons.FaLock />,
             cName: 'nav-text'
         }, 
        {
            title: ui.spanish ? es.videoVault.id : en.videoVault.id,
            path: '/videos',
            icons: <FaIcons.FaVideo />,
            cName: 'nav-text'
        },
        {
            title: ui.spanish ? es.notes.id : en.notes.id,
            path: '/notes',
            icons: <FaIcons.FaStickyNote />,
            cName: 'nav-text'
        },
        {
            title: ui.spanish ? es.address.id : en.address.id,
            path: '/address',
            icons: <FaIcons.FaMapMarkedAlt />,
            cName: 'nav-text'
        },
        {
            title: ui.spanish ? es.settings : en.settings,
            path: '/settings',
            icons: <FaIcons.FaCog />,
            cName: 'nav-text'
        },
        {
            title: ui.spanish ? es.darkMode : en.darkMode,
            path: '/settings',
            icons: <FaIcons.FaCog />,
            cName: 'nav-text',
            disable: true,
        },
        {
            title: ui.spanish ? es.logout : en.logout,
            path: '/logout',
            icons: <FaIcons.FaSignOutAlt />,
            cName: 'nav-text',
            disable: true,
        },
    ]

    const onClickChanges = (event, path) => {
        event.preventDefault();
        dispatch(setPath(path));

        if (isEdit) {
            dispatch(setRutas(true));
        } else {
            history.push(path);
            dispatch(setActiveVideo());
            dispatch(setActivePhoto());
            dispatch(setActivePassword());
            dispatch(setActiveNotes());
            dispatch(setActiveEmail());
            dispatch(setActiveAddress());
        }
    }


    const hanleLogout = () => {
        dispatch(startLogout());
    }

    return (
        <>
            <div className={ui.darkMode ? "global__nav-menu_darkMode" : "global__nav-menu"}>
                <ul>
                    {SidebarData.map((item, index) => {
                        if (item.title === en.darkMode || item.title === es.darkMode) {
                            return (
                                <li key={index} className={item.cName}>
                                    {item.disable ?? false ?
                                        <Link to={item.path} onClick={(event) => event.preventDefault()}>
                                            <>
                                                <Switch
                                                    onColor="#0F6BDA"
                                                    checked={ui.darkMode ? true : false}
                                                    onChange={handleDarkMode}
                                                    checkedIcon={
                                                        <i className="fa fa-moon"
                                                            style={{ transform: "translate(25%, 25%)" }} />
                                                    }
                                                    uncheckedHandleIcon={
                                                        <i className="fa fa-sun"
                                                            style={{ transform: "translate(25%, 25%)" }} />
                                                    }

                                                />
                                                <span style={{ marginLeft: '10px' }}>{item.title}</span>
                                            </>
                                        </Link> :
                                        <Link to={item.path}>
                                            <>
                                                <Switch
                                                    onColor="#0F6BDA"
                                                    checked={ui.darkMode ? true : false}
                                                    onChange={handleDarkMode}
                                                    checkedIcon={
                                                        <i className="fa fa-moon"
                                                            style={{ transform: "translate(25%, 25%)" }} />
                                                    }
                                                    uncheckedHandleIcon={
                                                        <i className="fa fa-sun"
                                                            style={{ transform: "translate(25%, 25%)" }} />
                                                    }

                                                />
                                                <span style={{ marginLeft: '10px' }}>{item.title}</span>
                                            </>
                                        </Link>}
                                </li>
                            )
                        } else if (item.title === en.logout || item.title === es.logout) {
                            return (
                                <li key={index} className={item.cName}>
                                    {item.disable ?? false ?
                                        <Link to={item.path} onClick={() => hanleLogout()}>
                                            <>
                                                {item.icons}
                                                <span style={{ marginLeft: '10px' }}>{item.title}</span>
                                            </>
                                        </Link> :
                                        <Link to={item.path}>
                                            <>
                                                {item.icons}
                                                <span style={{ marginLeft: '10px' }}>{item.title}</span>
                                            </>
                                        </Link>}
                                </li>
                            )
                        } else {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path} onClick={(event) => onClickChanges(event, item.path)}>
                                        {item.icons}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            )
                        }

                    })}
                </ul>
            </div>
        </>
    )
}


/* export const SidebarData = [
    {
        title: proc.ui.spanish ? 'PhotoV' : en.photoVault.id,
        path: '/',
        icons: <FaIcons.FaCamera />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Email' : en.emails.id,
        path: '/emails',
        icons: <FaIcons.FaEnvelope />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Contraseña' : en.password.id,
        path: '/password',
        icons: <FaIcons.FaLock />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Vv' : en.videoVault.id,
        path: '/videos',
        icons: <FaIcons.FaVideo />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Notas' : en.notes.id,
        path: '/notes',
        icons: <FaIcons.FaStickyNote />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Direccion' : en.address.id,
        path: '/address',
        icons: <FaIcons.FaMapMarkedAlt />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Opciones' : en.settings,
        path: '/settings',
        icons: <FaIcons.FaCog />,
        cName: 'nav-text'
    },
    {
        title: proc.ui.spanish ? 'Modo Oscuro' : en.darkMode,
        path: '',
        icons: <FaIcons.FaCog />,
        cName: 'nav-text'
    },
]
 */