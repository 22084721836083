import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { activeAddress } from '../../../actions/address';
import 'moment/locale/es';
import 'moment/locale/en-au';
import { removeError } from '../../../actions/ui';
import { setHelperEdit, setIsNew, setModalOpen } from '../../../actions/helpers';

export const AddressEntry = ({ id, date, title, body, url, secondname, lastname, username, business, address1, address2, address3, citytown }) => {

    const addresDate = moment(date).locale('en-au');
    const addresDateEs = moment(date).locale('es');


    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    const handleEntryClick = () => {
        dispatch(
            activeAddress(id, {
                date, title, body, url, secondname, lastname, username, business, address1, address2, address3, citytown
            })
        );

        dispatch(removeError());
        //dispatch(setHelperEdit(true));
        dispatch(setModalOpen(false));
        dispatch(setIsNew(false));


    }
    //console.log(photoDate)
    //console.log(id,date,title,body,url)
    return (
        <div
            className={ui.darkMode ? "global__entry global__entry__darkMode  pointer animate__animated animate__fadeIn" : "global__entry  pointer animate__animated animate__fadeIn"}
            onClick={handleEntryClick}
        >
            {
                url &&
                <div
                    className="global__entry-picture"
                    style={{
                        backgroundSize: 'cover',
                        backgroundImage: `url(${url})`
                    }}
                >
                </div>
            }
            <div className="w150">
                <div className="global__entry-body">
                    <p className={ui.darkMode ? "global__entry-title__darkMode" : "global__entry-title"}>
                        {title}
                    </p>
                    <p className="global__entry-content">
                        {address1}
                    </p>
                </div>
                {/*  <div className={ui.darkMode ? "global__entry-date-box__darkMode" : "global__entry-date-box"}>
                    <span>{ui.spanish ? addresDateEs.format('dddd') : addresDate.format('dddd')}</span>
                    <h4>{ui.spanish ? addresDateEs.format('Do') : addresDate.format('Do')}</h4>
                </div> */}
            </div>
        </div>
    )
}
