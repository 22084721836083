import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { startDeleting, startSaveVideo, activeVideos, startUploading, setActiveVideo } from '../../actions/videos'
import { VideosAppBar1 } from './VideosAppbar'
import { en, es } from '../../types/traslate'
import { removeError, setError } from '../../actions/ui';
import 'moment/locale/es';
import 'moment/locale/en-au';
import moment from "moment";
import { setHelperEdit, setModalCancel, setModalOpen } from '../../actions/helpers'


export const VideoScreen1 = () => {

    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const { active } = useSelector(state => state.videos);
    const { videos } = useSelector(state => state.videos);
    const [formValues, handleInputChange, reset] = useForm(active);
    const { body, title, id } = formValues;
    const { msgError } = useSelector(state => state.ui);
    const { isNew, videoUrl } = useSelector(state => state.helpers);

    const activeId = useRef(active.id);
    const ui = useSelector(state => state.ui);
    const [err, setErr] = useState({
        title: false,
        file: false,
    });

    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');

    useEffect(() => {
        if (active.id !== activeId.current) {
            reset(active);
            activeId.current = active.id
        }
    }, [active, reset])

    useEffect(() => {
        dispatch(activeVideos(formValues.id, { ...formValues }));
    }, [formValues, dispatch])

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const handleSave = () => {
        if (isFormValid()) {
            dispatch(startSaveVideo(active));
            /*  dispatch(startUploading(file)); */
        }
    }

    const isFormValid = () => {
        if (title.trim().length === 0) {
            setErr({ ...err, title: true });
            dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))
            return false;
        }
        if (!active.url && videoUrl === "") {
            setErr({ ...err, file: true });
            dispatch(setError(ui.spanish ? 'Se requiere video' : 'Video is required'))
            return false;
        }
        dispatch(removeError());
        return true;
    }

    const handleVolver = () => {

        if (isNew) {
            if (title.trim().length === 0) {
                setErr({ ...err, title: true });
                dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))
            } else if (!active.url && videoUrl === "") {
                setErr({ ...err, file: true });
                dispatch(setError(ui.spanish ? 'Se requiere video' : 'Video is required'))
            }

            dispatch(setModalCancel(true));


        } else if (videos.length > 0) {
            const filterVideos = videos.filter(video => video.id === active.id)[0];

            if (filterVideos?.title !== active.title || filterVideos?.body !== active.body || filterVideos?.url !== active.url) {
                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));
            } else {
                dispatch(setActiveVideo());
            }


        }

    }


    useEffect(() => {
        if (videoUrl !== "") {
            dispatch(setError(ui.spanish ? 'Video subido' : 'Uploaded video'))
        }

    }, [videoUrl, dispatch, ui.spanish])

    useEffect(() => {

        if (videos.length > 0) {
            const filterVideos = videos.filter(video => video.id === active.id)[0];

            if (filterVideos?.title !== active.title || filterVideos?.body !== active.body || filterVideos?.url !== active.url || (videoUrl !== "" && active.url)) {
                dispatch(setHelperEdit(true));
            }


        }

    }, [active.id, videos, active, dispatch, videoUrl])

    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>

            {/* <VideosAppBar1 date={active.date} /> */}

            <div className="notes__content">
                <div className="notes__content-col">
                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ border: err.title && active.title === "" ? '1px solid red' : '' }} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.videoVault.form.name : en.videoVault.form.name}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="title"
                            value={title}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.videoVault.form.name : en.videoVault.form.name}</label>

                    </div>
                    <br />
                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <textarea
                            placeholder={ui.spanish ? es.videoVault.form.notes : en.videoVault.form.notes}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            name="body"
                            value={body}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.videoVault.form.notes : en.videoVault.form.notes}</label>
                    </div>
                </div>
                {
                    (active.url && videoUrl === "")
                        ? <div className="notes__image">
                            <video controls
                                src={active.url}
                                alt="video"
                            />
                        </div>
                        : (active.url && videoUrl)

                            ? <div className="notes__image">
                                <video controls
                                    src={videoUrl}
                                    alt="video"
                                />
                            </div> : (videoUrl && !active.url)
                                ? <div className="notes__image">
                                    <video controls
                                        src={videoUrl}
                                        alt="video"
                                    />
                                </div>
                                : null

                }

            </div>

            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"} style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }


            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3  >
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.videoVault.form.save : en.videoVault.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}
                    >
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.videoVault.form.delete : en.videoVault.form.delete} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <VideosAppBar1 /* setFile={setFile} date={active.date} */ />
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>


            </div>


            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>


        </div>
    )
}
