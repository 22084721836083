import { db } from '../firebase/firebase-config'

export const loadAddress = async ( uid ) => {
    const addressSnap = await db.collection(`${ uid }/global/address`).get();
    const address = [];
    addressSnap.forEach ( snapChild => {
        address.push({
            id: snapChild.id,
            ...snapChild.data()
        })
    });

    console.log(address);
    
    return address;
}