import { types } from "../types/types";

export const authReducer = (state = {} , action, image) => {
    switch ( action.type ) {
        case types.login :
            return {
                uid: action.payload.uid,
                name: action.payload.displayName,
                image: action.payload.image
            }
        case types.logout :
            return { }
            
        default:
            return state;     
    }
}
