import React from 'react'
import { useSelector } from 'react-redux'
import { NothingSelected } from '../NothingSelected'
import { Sidebarlist } from '../../Sidebarlist'
import { NavBarNotes } from '../NavBar'
import { NoteScreen1 } from '../../notes1/NoteScreen1'
import { SidebarNotes } from './SidebarNotes'
import { Modals } from '../../modal/Modal'

export const NoteScreen = () => {

    const { active } = useSelector(state => state.notes)
    const ui = useSelector(state => state.ui);

    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBarNotes />
                <main>
                    {
                        (active)
                            ? (<NoteScreen1 />)
                            : (<NothingSelected type="notes" />)
                    }
                </main>
                <Modals type="note" />
                {/* <SidebarNotes /> */}
            </div>
        </div>
    )
}
