import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { startSaveVideo, startUploading } from '../../actions/videos'
import { en, es } from '../../types/traslate';
import 'moment/locale/es';
import 'moment/locale/en-au';

export const VideosAppBar1 = ({ date, setFile }) => {

    const videoDate = moment(date).locale('en-au');
    const videoDateEs = moment(date).locale('es');


    const ui = useSelector(state => state.ui);
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.photos)

  /*   const handleSave = () => {
        dispatch(startSaveVideo(active));
    }
 */
    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        /* setFile(file); */
        if (file) {
            dispatch(startUploading(file));
        }
    }

    return (
        /*   <div className={ui.darkMode ? "notes__appbar_dark" : "notes__appbar"}>
              <span>{ui.spanish ? videoDateEs.format('dddd') + ' ' + videoDateEs.format('Do') : videoDate.format('dddd') + ' ' + videoDate.format('Do')}</span> */

        <div>
            <input
                id="fileSelector"
                type="file"
                name="file"
                accept="video/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <div>
                <button
                    /*style={{ backgroundColor: ui.darkMode ? '#7367f0' : '#fff', color: ui.darkMode ? '#fff' : '#000' }}*/
                    className="btn btn-picture"
                    onClick={handlePictureClick}
                >
                    <h3>
                        <i class="fas fa-camera"></i>
                        <span> {ui.spanish ? es.video : en.video}</span>
                    </h3>
                </button>
            </div>
        </div>

    )
}
