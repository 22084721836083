import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { activeVideos } from '../../../actions/videos';
import 'moment/locale/es';
import 'moment/locale/en-au';
import { removeError } from '../../../actions/ui';
import { setHelperEdit, setIsNew, setModalOpen } from '../../../actions/helpers';

export const VideoEntry = ({ id, date, title, body, url }) => {

    const videoDate = moment(date).locale('en-au');
    const videoDateEs = moment(date).locale('es');


    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    const handleEntryClick = () => {
        dispatch(
            activeVideos(id, {
                date, title, body, url
            })
        );

        dispatch(removeError());
        //dispatch(setHelperEdit(true));
        dispatch(setModalOpen(false));
        dispatch(setIsNew(false));


    }
    //console.log(id,date,title,body,url)
    return (
        <div
            className={ui.darkMode ? "global__entry global__entry__darkMode  pointer animate__animated animate__fadeIn" : "global__entry  pointer animate__animated animate__fadeIn"}
            onClick={handleEntryClick}
        >
            {/* {
                url &&
                <div style={{ textAlign: 'center' }}>
                    <video controls
                        style={{
                            width: '50%',
                            height: '100%',

                        }}
                        width="100%"
                        src={url}
                        alt="video"
                    />
                </div>
            } */}

            
            <div className="w150">
                <div className="global__entry-body">
                    <p className={ui.darkMode ? "global__entry-title__darkMode" : "global__entry-title"}>
                        {title}
                    </p>
                    <p className="global__entry-content">
                        {body}
                    </p>
                </div>
                {/* <div className={ui.darkMode ? "global__entry-date-box__darkMode" : "global__entry-date-box"}>
                    <span>{ui.spanish ? videoDateEs.format('dddd') : videoDate.format('dddd')}</span>
                    <h4>{ui.spanish ? videoDateEs.format('Do') : videoDate.format('Do')}</h4>
                </div> */}
            </div>
        </div>
    )
}
