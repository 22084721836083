//Function to sort alphabetically
export const sortCountry = (countrys) => {
    return countrys.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });
};

//Function to sort alphabetically
export const sortStateUSA = (stateUSA) => {
    return stateUSA.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });
};

//countries in spanish
export const countrysES = [
    { value: 'AD', label: 'Andorra' },
    { value: 'AE', label: 'Emiratos Árabes Unidos' },
    { value: 'AF', label: 'Afganistán' },
    { value: 'AG', label: 'Antigua y Barbuda' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AL', label: 'Albania' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AN', label: 'Antillas Holandesas' },
    { value: 'AO', label: 'Angola' },
    { value: 'AQ', label: 'Antártida' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AS', label: 'Samoa Americana' },
    { value: 'AT', label: 'Austria' },
    { value: 'AU', label: 'Australia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AZ', label: 'Azerbayán' },
    { value: 'BA', label: 'Bosnia- Herzegovina' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BE', label: 'Bélgica' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BI', label: 'Burundi' },
    { value: 'BJ', label: 'Benín' },
    { value: 'BM', label: 'Islas Bermudas' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BR', label: 'Brasil' },
    { value: 'BT', label: 'Bahamas' },
    { value: 'BS', label: 'Bután' },
    { value: 'BV', label: 'Islas Buvet' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BY', label: 'Bielorrusia' },
    { value: 'BZ', label: 'Belice' },
    { value: 'CA', label: 'Canadá' },
    { value: 'CC', label: 'Isla de Cocos' },
    { value: 'CD', label: 'República Democrática del Congo' },
    { value: 'CF', label: 'República Centroafricana' },
    { value: 'CG', label: 'República del Congo' },
    { value: 'CH', label: 'Suiza' },
    { value: 'CI', label: 'Costa de marfil' },
    { value: 'CK', label: 'Islas Cook' },
    { value: 'CL', label: 'Chile' },
    { value: 'CM', label: 'Camerún' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CS', label: 'Checoslovaquia(antiguo país)' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CV', label: 'Cabo Verde' },
    { value: 'CX', label: 'Islas Christmas' },
    { value: 'CY', label: 'Chipre' },
    { value: 'CZ', label: 'República Checa' },
    { value: 'DE', label: 'Alemania' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DK', label: 'Dinamarca' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'República Dominicana' },
    { value: 'DZ', label: 'Argelia' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EE', label: 'Estonia' },
    { value: 'EG', label: 'Egipto' },
    { value: 'EH', label: 'Sáhara Occidental' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'ES', label: 'España' },
    { value: 'ET', label: 'Etiopía' },
    { value: 'FI', label: 'Finlandia' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FK', label: 'Islas Malvinas' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'FO', label: 'Islas Feroe' },
    { value: 'FR', label: 'Francia' },
    { value: 'GA', label: 'Gabón' },
    { value: 'GD', label: 'Granada' },
    { value: 'GE', label: 'Georgia' },
    { value: 'GF', label: 'Guyana Francesa' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GL', label: 'Groenlandia' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GP', label: 'Guadalupe' },
    { value: 'GQ', label: 'Guinea Ecuatorial' },
    { value: 'GR', label: 'Grecia' },
    { value: 'GS', label: 'Islas Georgias y Sandwich del Sur' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GU', label: 'Guam' },
    { value: 'GW', label: 'Guinea - Bissau' },
    { value: 'GY', label: 'Guayana' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HM', label: 'Islas Heard y McDonald' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HR', label: 'Croacia' },
    { value: 'HT', label: 'Haití' },
    { value: 'HU', label: 'Hungría' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IE', label: 'Irlanda' },
    { value: 'IL', label: 'Israel' },
    { value: 'IM', label: 'Isla de Man' },
    { value: 'IN', label: 'India' },
    { value: 'IO', label: 'Territorio británico del Océano Índico' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IR', label: 'Irán' },
    { value: 'IS', label: 'Islandia' },
    { value: 'IT', label: 'Italia' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordania' },
    { value: 'JP', label: 'Japón' },
    { value: 'KE', label: 'Kenia' },
    { value: 'KG', label: 'Kyrgystán' },
    { value: 'KH', label: 'Camboya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KM', label: 'Islas Comores' },
    { value: 'KN', label: 'San Kitts y Nevis' },
    { value: 'KP', label: 'Corea del Norte' },
    { value: 'KR', label: 'Corea del Sur' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KY', label: 'Islas Caimán' },
    { value: 'KZ', label: 'Kazajistán' },
    { value: 'LA', label: 'Laos' },
    { value: 'LB', label: 'Líbano' },
    { value: 'LC', label: 'Santa Lucía' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LS', label: 'Lesoto' },
    { value: 'LT', label: 'Lituania' },
    { value: 'LU', label: 'Luxemburgo' },
    { value: 'LV', label: 'Letonia' },
    { value: 'LY', label: 'Libia' },
    { value: 'MA', label: 'Marruecos' },
    { value: 'MC', label: 'Mónaco' },
    { value: 'MD', label: 'Moldavia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MK', label: 'Macedonia' },
    { value: 'MH', label: 'Islas Marshall' },
    { value: 'ML', label: 'Mali' },
    { value: 'MM', label: 'Birmania' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MO', label: 'Macao' },
    { value: 'MP', label: 'Islas Marianas' },
    { value: 'MQ', label: 'Martinica' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MT', label: 'Malta' },
    { value: 'MU', label: 'Mauricio' },
    { value: 'MV', label: 'Maldivas' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MX', label: 'México' },
    { value: 'MY', label: 'Malasia' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NC', label: 'Nueva Caledonia' },
    { value: 'NE', label: 'Níger' },
    { value: 'NF', label: 'Islas Norfolk' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NL', label: 'Países Bajos' },
    { value: 'NO', label: 'Noruega' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NT', label: 'Zona Neutral' },
    { value: 'NU', label: 'Niue' },
    { value: 'NZ', label: 'Nueva Zelanda' },
    { value: 'OM', label: 'Omán' },
    { value: 'PA', label: 'Panamá' },
    { value: 'PE', label: 'Perú' },
    { value: 'PF', label: 'Polinesia Francesa' },
    { value: 'PG', label: 'Papúa Nueva Guinea' },
    { value: 'PH', label: 'Filipinas' },
    { value: 'PK', label: 'Pakistán' },
    { value: 'PL', label: 'Polonia' },
    { value: 'PM', label: 'San Pedro y Miquelón' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'PS', label: 'Territorios Palestinos' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PW', label: 'Palau' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Isla Reunión' },
    { value: 'RO', label: 'Rumanía' },
    { value: 'RU', label: 'Rusia' },
    { value: 'RW', label: 'Ruanda' },
    { value: 'SA', label: 'Arabia Saudí' },
    { value: 'SB', label: 'Islas Salomón' },
    { value: 'SC', label: 'Islas Seychelles' },
    { value: 'SD', label: 'Sudán' },
    { value: 'SE', label: 'Suecia' },
    { value: 'SG', label: 'Singapur' },
    { value: 'SH', label: 'Santa Helena' },
    { value: 'SI', label: 'Eslovenia' },
    { value: 'SJ', label: 'Islas Svalbard y Jan Mayens' },
    { value: 'SK', label: 'Eslovaquia' },
    { value: 'SL', label: 'Sierra Leona' },
    { value: 'SM', label: 'San Marino' },
    { value: 'SN', label: 'Senegal' },
    { value: 'SO', label: 'Somalia' },
    { value: 'SR', label: 'Surinam' },
    { value: 'SU', label: 'URSS' },
    { value: 'ST', label: 'Santo Tomé y Príncipe' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'SY', label: 'Siria' },
    { value: 'SZ', label: 'Suazilandia' },
    { value: 'TC', label: 'Islas Turks y Caicos' },
    { value: 'TD', label: 'Chad' },
    { value: 'TF', label: 'Tierras Australes y Antárticas Francesas' },
    { value: 'TG', label: 'Togo' },
    { value: 'TH', label: 'Tailandia' },
    { value: 'TJ', label: 'Tayikistán' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TM', label: 'Turkmenistán' },
    { value: 'TN', label: 'Túnez' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TP', label: 'Timor Oriental' },
    { value: 'TR', label: 'Turquía' },
    { value: 'TT', label: 'Trinidad y Tobago' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'TW', label: 'Taiwán' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'UA', label: 'Ucrania' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UK', label: 'Reino Unido' },
    { value: 'UM', label: 'Islas Ultramarinas de Estados Unidos' },
    { value: 'US', label: 'Estados Unidos de América' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistán' },
    { value: 'VA', label: 'Vaticano' },
    { value: 'VC', label: 'San Vicente y las Granadinas' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VG', label: 'Islas Vírgenes Británicas' },
    { value: 'VI', label: 'Islas Vírgenes Americanas' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'WF', label: 'Islas Wallis y Futuna' },
    { value: 'WS', label: 'Samoa' },
    { value: 'YE', label: 'Yemen' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'YU', label: 'Yugoslavia(antiguo país)' },
    { value: 'ZA', label: 'Sudáfrica' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZR', label: 'Zaire(antiguo país)' },
    { value: 'ZW', label: 'Zimbabwe', },
]

//countries in english
export const countrys = [
    { value: 'AD', label: 'Andorra' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AL', label: 'Albania' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AN', label: 'Netherlands Antilles' },
    { value: 'AO', label: 'Angola' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AT', label: 'Austria' },
    { value: 'AU', label: 'Australia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BA', label: 'Bosnia- Herzegovina' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BI', label: 'Burundi' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda Islands' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BR', label: 'Brazil' },
    { value: 'BT', label: 'Bahamas' },
    { value: 'BS', label: 'Bhutan' },
    { value: 'BV', label: 'Buvet Islands' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BZ', label: 'Belize' },
    { value: 'CA', label: 'Canada' },
    { value: 'CC', label: 'Isla de Cocos' },
    { value: 'CD', label: 'Democratic Republic of the Congo' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'CG', label: 'Republic of the Congo' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'CI', label: 'Ivory Coast' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CL', label: 'Chile' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CS', label: 'Czechoslovakia (old country)' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'CX', label: 'Christmas Islands' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DE', label: 'Germany' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EE', label: 'Estonia' },
    { value: 'EG', label: 'Egypt' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'ES', label: 'Spain' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FI', label: 'Finland' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FK', label: 'Islas Malvinas' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FR', label: 'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GD', label: 'Granada' },
    { value: 'GE', label: 'Georgia' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GP', label: 'Guadalupe' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'GR', label: 'Greece' },
    { value: 'GS', label: 'Georgia and the South Sandwich Islands' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GU', label: 'Guam' },
    { value: 'GW', label: 'Guinea - Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HM', label: 'Heard and McDonald Islands' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HR', label: 'Croatia' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HU', label: 'Hungary' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IN', label: 'India' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IR', label: 'Iran' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IT', label: 'Italia' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'JP', label: 'Japan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KG', label: 'Kyrgystán' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KM', label: 'Islas Comores' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'KP', label: 'North Korea' },
    { value: 'KR', label: 'South Korea' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LC', label: 'Santa Lucía' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LY', label: 'Libya' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MK', label: 'Macedonia' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'ML', label: 'Mali' },
    { value: 'MM', label: 'Burma' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MO', label: 'Macao' },
    { value: 'MP', label: 'Mariana Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MT', label: 'Malta' },
    { value: 'MU', label: 'Mauricio' },
    { value: 'MV', label: 'Maldives' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MX', label: 'Mexico' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NE', label: 'Niger' },
    { value: 'NF', label: 'Norfolk Islands' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NO', label: 'Norway' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NT', label: 'Neutral Zone' },
    { value: 'NU', label: 'Niue' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'OM', label: 'Oman' },
    { value: 'PA', label: 'Panama' },
    { value: 'PE', label: 'Peru' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PL', label: 'Poland' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'PS', label: 'Palestinian Territories' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PW', label: 'Palau' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion Island' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SC', label: 'Seychelles Islands' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SE', label: 'Sweden' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SH', label: 'Santa Helena' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SJ', label: 'Svalbard and Jan Mayens Islands' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SM', label: 'San Marino' },
    { value: 'SN', label: 'Senegal' },
    { value: 'SO', label: 'Somalia' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SU', label: 'USSR' },
    { value: 'ST', label: 'São Tomé and Príncipe' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'SY', label: 'Syria' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TD', label: 'Chad' },
    { value: 'TF', label: 'French Southern and Antarctic Lands' },
    { value: 'TG', label: 'Togo' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TP', label: 'East Timor' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UK', label: 'United Kingdom' },
    { value: 'UM', label: 'United States Overseas Islands' },
    { value: 'US', label: 'United States of America' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VA', label: 'Vatican' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VG', label: 'British Virgin Islands' },
    { value: 'VI', label: 'US Virgin Islands' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'WF', label: 'Wallis and Futuna Islands' },
    { value: 'WS', label: 'Samoa' },
    { value: 'YE', label: 'Yemen' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'YU', label: 'Yugoslavia (old country)' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZR', label: 'Zaire (old country)' },
    { value: 'ZW', label: 'Zimbabwe', }
]


//united states cities
/* export const stateUS = [
    { value: 'AL', label: 'Montgomery (Alabama)' },
    { value: 'AK', label: 'Juneau (Alaska)', },
    { value: 'AZ', label: 'Phoenix (Arizona)', },
    { value: 'AR', label: 'Little Rock (Arkansas)', },
    { value: 'CA', label: 'Sacramento (California)', },
    { value: 'CO', label: 'Denver (Colorado)', capital: '' },
    { value: 'CT', label: 'Hartford (Connecticut)', capital: '' },
    { value: 'DE', label: 'Dover (Delaware)', capital: '' },
    { value: 'FL', label: 'Tallahassee (Florida)', capital: '' },
    { value: 'GA', label: 'Atlanta (Georgia)', capital: '' },
    { value: 'HI', label: 'Honolulu (Hawaii)', capital: '' },
    { value: 'ID', label: 'Boise (Idaho)', capital: '' },
    { value: 'IL', label: 'Springfield (Illinois)', capital: '' },
    { value: 'IN', label: 'Indianapolis (Indiana)', capital: '' },
    { value: 'IA', label: 'Des Moines (Iowa)', capital: '' },
    { value: 'KS', label: 'Topeka (Kansas)', capital: '' },
    { value: 'KY', label: 'Frankfort (Kentucky)', capital: '' },
    { value: 'LA', label: 'Baton Rouge (Louisiana)', capital: '' },
    { value: 'ME', label: 'Augusta (Maine)', capital: '' },
    { value: 'MD', label: 'Annapolis (Maryland)', capital: '' },
    { value: 'MA', label: 'Boston (Massachusetts)', capital: '' },
    { value: 'MI', label: 'Lansing (Michigan)', capital: '' },
    { value: 'MN', label: 'St. Paul (Minnesota)', capital: '' },
    { value: 'MS', label: 'Jackson (Mississippi)', capital: '' },
    { value: 'MO', label: 'Jefferson City (Missouri)', capital: '' },
    { value: 'MT', label: 'Helena (Montana)', capital: '' },
    { value: 'NE', label: 'Lincoln (Nebraska)', capital: '' },
    { value: 'NV', label: 'Carson City (Nevada)', capital: '' },
    { value: 'NH', label: 'Concord (New Hampshire)', capital: '' },
    { value: 'NJ', label: 'Trenton (New Jersey)', capital: '' },
    { value: 'NM', label: 'Santa Fe (New Mexico)', capital: '' },
    { value: 'NY', label: 'Albany (New York)', capital: '' },
    { value: 'NC', label: 'Raleigh (North Carolina)', capital: '' },
    { value: 'ND', label: 'Bismarck (North Dakota)', capital: '' },
    { value: 'OH', label: 'Columbus (Ohio)', capital: '' },
    { value: 'OK', label: 'Oklahoma City (Oklahoma)', capital: '' },
    { value: 'OR', label: 'Salem (Oregon)', capital: '' },
    { value: 'PA', label: 'Harrisburg (Pennsylvania)', capital: '' },
    { value: 'RI', label: 'Providence (Rhode Island)', capital: '' },
    { value: 'SC', label: 'Columbia (South Carolina)', capital: '' },
    { value: 'SD', label: 'Pierre (South Dakota)', capital: '' },
    { value: 'TN', label: 'Nashville (Tennessee)', capital: '' },
    { value: 'TX', label: 'Austin (Texas)', capital: '' },
    { value: 'UT', label: 'Salt Lake City (Utah)', capital: '' },
    { value: 'VT', label: 'Montpelier (Vermont)', capital: '' },
    { value: 'VA', label: 'Richmond (Virginia)', capital: '' },
    { value: 'WA', label: 'Olympia (Washington)', capital: '' },
    { value: 'WV', label: 'Charleston (West Virginia)', },
    { value: 'WI', label: 'Madison (Wisconsin)', },
    { value: 'WY', label: 'Cheyenne (Wyoming)', },
]
 */
export const stateES = [
    { value: 'AL', label: 'Montgomery (Alabama)' },
    { value: 'AK', label: 'Juneau (Alaska)', },
    { value: 'AZ', label: 'Phoenix (Arizona)', },
    { value: 'AR', label: 'Little Rock (Arkansas)', },
    { value: 'CA', label: 'Sacramento (California)', },
    { value: 'CO', label: 'Denver (Colorado)', capital: '' },
    { value: 'CT', label: 'Hartford (Connecticut)', capital: '' },
    { value: 'DE', label: 'Dover (Delaware)', capital: '' },
    { value: 'FL', label: 'Tallahassee (Florida)', capital: '' },
    { value: 'GA', label: 'Atlanta (Georgia)', capital: '' },
    { value: 'HI', label: 'Honolulu (Hawái)', capital: '' },
    { value: 'ID', label: 'Boise (Idaho)', capital: '' },
    { value: 'IL', label: 'Springfield (Illinois)', capital: '' },
    { value: 'IN', label: 'Indianápolis (Indiana)', capital: '' },
    { value: 'IA', label: 'Des Moines (Iowa)', capital: '' },
    { value: 'KS', label: 'Topeka (Kansas)', capital: '' },
    { value: 'KY', label: 'Frankfort (Kentucky)', capital: '' },
    { value: 'LA', label: 'Baton Rouge (Luisiana)', capital: '' },
    { value: 'YO', label: 'Augusta (Maine)', capital: '' },
    { value: 'MD', label: 'Annapolis (Maryland)', capital: '' },
    { value: 'MA', label: 'Boston (Massachusetts)', capital: '' },
    { value: 'MI', label: 'Lansing (Michigan)', capital: '' },
    { value: 'MN', label: 'St. Paul (Minnesota) ', capital: ' ' },
    { value: 'MS', label: 'Jackson (Mississippi)', capital: '' },
    { value: 'MO', label: 'Jefferson City (Misuri)', capital: '' },
    { value: 'MT', label: 'Helena (Montana)', capital: '' },
    { value: 'NE', label: 'Lincoln (Nebraska)', capital: '' },
    { value: 'NV', label: 'Carson City (Nevada)', capital: '' },
    { value: 'NH', label: 'Concord (New Hampshire)', capital: '' },
    { value: 'NJ', label: 'Trenton (Nueva Jersey)', capital: '' },
    { value: 'NM', label: 'Santa Fe (Nuevo México)', capital: '' },
    { value: 'NY', label: 'Albany (Nueva York)', capital: '' },
    { value: 'NC', label: 'Raleigh (Carolina del Norte)', capital: '' },
    { value: 'ND', label: 'Bismarck (Dakota del Norte)', capital: '' },
    { value: 'OH', label: 'Columbus (Ohio)', capital: '' },
    { value: 'OK', label: 'Oklahoma City (Oklahoma)', capital: '' },
    { value: 'O', label: 'Salem (Oregón)', capital: '' },
    { value: 'PA', label: 'Harrisburg (Pensilvania)', capital: '' },
    { value: 'RI', label: 'Providence (Rhode Island)', capital: '' },
    { value: 'SC', label: 'Columbia (Carolina del Sur)', capital: '' },
    { value: 'SD', label: 'Pierre (Dakota del Sur)', capital: '' },
    { value: 'TN', label: 'Nashville (Tennessee)', capital: '' },
    { value: 'TX', label: 'Austin (Texas)', capital: '' },
    { value: 'UT', label: 'Salt Lake City (Utah)', capital: '' },
    { value: 'VT', label: 'Montpelier (Vermont)', capital: '' },
    { value: 'VA', label: 'Richmond (Virginia)', capital: '' },
    { value: 'WA', label: 'Olympia (Washington)', capital: '' },
    { value: 'WV', label: 'Charleston (Virginia Occidental)', },
    { value: 'WI', label: 'Madison (Wisconsin)', },
    { value: 'WY', label: 'Cheyenne (Wyoming)', },
]

//States of USA

export const stateUS = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawái', label: 'Hawái' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' }
];

//United States cities

export const citysAlabama = [
    { value: 'Alexander City', label: 'Alexander City' },
    { value: 'Andalusia', label: 'Andalusia' },
    { value: 'Anniston', label: 'Anniston' },
    { value: 'Athens', label: 'Athens' },
    { value: 'Atmore', label: 'Atmore' },
    { value: 'Auburn', label: 'Auburn' },
    { value: 'Bessemer', label: 'Bessemer' },
    { value: 'Birmingham', label: 'Birmingham' },
    { value: 'Chickasaw', label: 'Chickasaw' },
    { value: 'Clanton', label: 'Clanton' },
    { value: 'Cullman', label: 'Cullman' },
    { value: 'Decatur', label: 'Decatur' },
    { value: 'Demopolis', label: 'Demopolis' },
    { value: 'Dothan', label: 'Dothan' },
    { value: 'Enterprise', label: 'Enterprise' },
    { value: 'Eufaula', label: 'Eufaula' },
    { value: 'Florence', label: 'Florence' },
    { value: 'Fort Payne', label: 'Fort Payne' },
    { value: 'Gadsden', label: 'Gadsden' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Guntersville', label: 'Guntersville' },
    { value: 'Huntsville', label: 'Huntsville' },
    { value: 'Jasper', label: 'Jasper' },
    { value: 'Marion', label: 'Marion' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Montgomery', label: 'Montgomery' },
    { value: 'Opelika', label: 'Opelika' },
    { value: 'Ozark', label: 'Ozark' },
    { value: 'Phenix City', label: 'Phenix City' },
    { value: 'Prichard', label: 'Prichard' },
    { value: 'Scottsboro', label: 'Scottsboro' },
    { value: 'Selma', label: 'Selma' },
    { value: 'Sheffield', label: 'Sheffield' },
    { value: 'Sylacauga', label: 'Sylacauga' },
    { value: 'Talladega', label: 'Talladega' },
    { value: 'Troy', label: 'Troy' },
    { value: 'Tuscaloosa', label: 'Tuscaloosa' },
    { value: 'Tuscumbia', label: 'Tuscumbia' },
    { value: 'Tuskegee', label: 'Tuskegee' },
]

export const citysAlaska = [
    { value: 'Anchorage', label: 'Anchorage' },
    { value: 'Cordova', label: 'Cordova' },
    { value: 'Fairbanks', label: 'Fairbanks' },
    { value: 'Haines', label: 'Haines' },
    { value: 'Homer', label: 'Homer' },
    { value: 'Juneau', label: 'Juneau' },
    { value: 'Ketchikan', label: 'Ketchikan' },
    { value: 'Kodiak', label: 'Kodiak' },
    { value: 'Kotzebue', label: 'Kotzebue' },
    { value: 'Nome', label: 'Nome' },
    { value: 'Palmer', label: 'Palmer' },
    { value: 'Seward', label: 'Seward' },
    { value: 'Sitka', label: 'Sitka' },
    { value: 'Skagway', label: 'Skagway' },
    { value: 'Valdez', label: 'Valdez' },
]

export const citysArizona = [
    { value: 'Ajo', label: 'Ajo' },
    { value: 'Avondale', label: 'Avondale' },
    { value: 'Bisbee', label: 'Bisbee' },
    { value: 'Casa Grande', label: 'Casa Grande' },
    { value: 'Chandler', label: 'Chandler' },
    { value: 'Clifton', label: 'Clifton' },
    { value: 'Douglas', label: 'Douglas' },
    { value: 'Flagstaff', label: 'Flagstaff' },
    { value: 'Florence', label: 'Florence' },
    { value: 'Gila Bend', label: 'Gila Bend' },
    { value: 'Glendale', label: 'Glendale' },
    { value: 'Globe', label: 'Globe' },
    { value: 'Kingman', label: 'Kingman' },
    { value: 'Lake Havasu City', label: 'Lake Havasu City' },
    { value: 'Mesa', label: 'Mesa' },
    { value: 'Nogales', label: 'Nogales' },
    { value: 'Oraibi', label: 'Oraibi' },
    { value: 'Phoenix', label: 'Phoenix' },
    { value: 'Prescott', label: 'Prescott' },
    { value: 'Scottsdale', label: 'Scottsdale' },
    { value: 'Sierra Vista', label: 'Sierra Vista' },
    { value: 'Tempe', label: 'Tempe' },
    { value: 'Tombstone', label: 'Tombstone' },
    { value: 'Tucson', label: 'Tucson' },
    { value: 'Walpi', label: 'Walpi' },
    { value: 'Window Rock', label: 'Window Rock' },
    { value: 'Winslow', label: 'Winslow' },
    { value: 'Yuma', label: 'Yuma' },
]

export const citysArkansas = [
    { value: 'Arkadelphia', label: 'Arkadelphia' },
    { value: 'Arkansas Post', label: 'Arkansas Post' },
    { value: 'Batesville', label: 'Batesville' },
    { value: 'Benton', label: 'Benton' },
    { value: 'Blytheville', label: 'Blytheville' },
    { value: 'Camden', label: 'Camden' },
    { value: 'Conway', label: 'Conway' },
    { value: 'Crossett', label: 'Crossett' },
    { value: 'El Dorado', label: 'El Dorado' },
    { value: 'Fayetteville', label: 'Fayetteville' },
    { value: 'Forrest City', label: 'Forrest City' },
    { value: 'Fort Smith', label: 'Fort Smith' },
    { value: 'Harrison', label: 'Harrison' },
    { value: 'Helena', label: 'Helena' },
    { value: 'Hope', label: 'Hope' },
    { value: 'Hot Springs', label: 'Hot Springs' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Jonesboro', label: 'Jonesboro' },
    { value: 'Little Rock', label: 'Little Rock' },
    { value: 'Magnolia', label: 'Magnolia' },
    { value: 'Morrilton', label: 'Morrilton' },
    { value: 'Newport', label: 'Newport' },
    { value: 'North Little Rock', label: 'North Little Rock' },
    { value: 'Osceola', label: 'Osceola' },
    { value: 'Pine Bluff', label: 'Pine Bluff' },
    { value: 'Rogers', label: 'Rogers' },
    { value: 'Searcy', label: 'Searcy' },
    { value: 'Stuttgart', label: 'Stuttgart' },
    { value: 'Van Buren', label: 'Van Buren' },
    { value: 'West Memphis', label: 'West Memphis' },
];


export const citysCalifornia = [
    { value: 'Alameda', label: 'Alameda' },
    { value: 'Alhambra', label: 'Alhambra' },
    { value: 'Anaheim', label: 'Anaheim' },
    { value: 'Antioch', label: 'Antioch' },
    { value: 'Arcadia', label: 'Arcadia' },
    { value: 'Bakersfield', label: 'Bakersfield' },
    { value: 'Barstow', label: 'Barstow' },
    { value: 'Belmont', label: 'Belmont' },
    { value: 'Berkeley', label: 'Berkeley' },
    { value: 'Beverly Hills', label: 'Beverly Hills' },
    { value: 'Brea', label: 'Brea' },
    { value: 'Buena Park', label: 'Buena Park' },
    { value: 'Burbank', label: 'Burbank' },
    { value: 'Calexico', label: 'Calexico' },
    { value: 'Calistoga', label: 'Calistoga' },
    { value: 'Carlsbad', label: 'Carlsbad' },
    { value: 'Carmel', label: 'Carmel' },
    { value: 'Chico', label: 'Chico' },
    { value: 'Chula Vista', label: 'Chula Vista' },
    { value: 'Claremont', label: 'Claremont' },
    { value: 'Compton', label: 'Compton' },
    { value: 'Concord', label: 'Concord' },
    { value: 'Corona', label: 'Corona' },
    { value: 'Coronado', label: 'Coronado' },
    { value: 'Costa Mesa', label: 'Costa Mesa' },
    { value: 'Culver City', label: 'Culver City' },
    { value: 'Daly City', label: 'Daly City' },
    { value: 'Davis', label: 'Davis' },
    { value: 'Downey', label: 'Downey' },
    { value: 'El Centro', label: 'El Centro' },
    { value: 'El Cerrito', label: 'El Cerrito' },
    { value: 'El Monte', label: 'El Monte' },
    { value: 'Escondido', label: 'Escondido' },
    { value: 'Eureka', label: 'Eureka' },
    { value: 'Fairfield', label: 'Fairfield' },
    { value: 'Fontana', label: 'Fontana' },
    { value: 'Fremont', label: 'Fremont' },
    { value: 'Fresno', label: 'Fresno' },
    { value: 'Fullerton', label: 'Fullerton' },
    { value: 'Garden Grove', label: 'Garden Grove' },
    { value: 'Glendale', label: 'Glendale' },
    { value: 'Hayward', label: 'Hayward' },
    { value: 'Hollywood', label: 'Hollywood' },
    { value: 'Huntington Beach', label: 'Huntington Beach' },
    { value: 'Indio', label: 'Indio' },
    { value: 'Inglewood', label: 'Inglewood' },
    { value: 'Irvine', label: 'Irvine' },
    { value: 'La Habra', label: 'La Habra' },
    { value: 'Laguna Beach', label: 'Laguna Beach' },
    { value: 'Lancaster', label: 'Lancaster' },
    { value: 'Livermore', label: 'Livermore' },
    { value: 'Lodi', label: 'Lodi' },
    { value: 'Lompoc', label: 'Lompoc' },
    { value: 'Long Beach', label: 'Long Beach' },
    { value: 'Los Angeles', label: 'Los Angeles' },
    { value: 'Malibu', label: 'Malibu' },
    { value: 'Martinez', label: 'Martinez' },
    { value: 'Marysville', label: 'Marysville' },
    { value: 'Menlo Park', label: 'Menlo Park' },
    { value: 'Merced', label: 'Merced' },
    { value: 'Modesto', label: 'Modesto' },
    { value: 'Monterey', label: 'Monterey' },
    { value: 'Mountain View', label: 'Mountain View' },
    { value: 'Napa', label: 'Napa' },
    { value: 'Needles', label: 'Needles' },
    { value: 'Newport Beach', label: 'Newport Beach' },
    { value: 'Norwalk', label: 'Norwalk' },
    { value: 'Novato', label: 'Novato' },
    { value: 'Oakland', label: 'Oakland' },
    { value: 'Oceanside', label: 'Oceanside' },
    { value: 'Ojai', label: 'Ojai' },
    { value: 'Ontario', label: 'Ontario' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Oroville', label: 'Oroville' },
    { value: 'Oxnard', label: 'Oxnard' },
    { value: 'Pacific Grove', label: 'Pacific Grove' },
    { value: 'Palm Springs', label: 'Palm Springs' },
    { value: 'Palmdale', label: 'Palmdale' },
    { value: 'Palo Alto', label: 'Palo Alto' },
    { value: 'Pasadena', label: 'Pasadena' },
    { value: 'Petaluma', label: 'Petaluma' },
    { value: 'Pomona', label: 'Pomona' },
    { value: 'Port Hueneme', label: 'Port Hueneme' },
    { value: 'Rancho Cucamonga', label: 'Rancho Cucamonga' },
    { value: 'Red Bluff', label: 'Red Bluff' },
    { value: 'Redding', label: 'Redding' },
    { value: 'Redlands', label: 'Redlands' },
    { value: 'Redondo Beach', label: 'Redondo Beach' },
    { value: 'Redwood City', label: 'Redwood City' },
    { value: 'Richmond', label: 'Richmond' },
    { value: 'Riverside', label: 'Riverside' },
    { value: 'Roseville', label: 'Roseville' },
    { value: 'Sacramento', label: 'Sacramento' },
    { value: 'Salinas', label: 'Salinas' },
    { value: 'San Bernardino', label: 'San Bernardino' },
    { value: 'San Clemente', label: 'San Clemente' },
    { value: 'San Diego', label: 'San Diego' },
    { value: 'San Fernando', label: 'San Fernando' },
    { value: 'San Francisco', label: 'San Francisco' },
    { value: 'San Gabriel', label: 'San Gabriel' },
    { value: 'San Jose', label: 'San Jose' },
    { value: 'San Juan Capistrano', label: 'San Juan Capistrano' },
    { value: 'San Leandro', label: 'San Leandro' },
    { value: 'San Luis Obispo', label: 'San Luis Obispo' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'San Mateo', label: 'San Mateo' },
    { value: 'San Pedro', label: 'San Pedro' },
    { value: 'San Rafael', label: 'San Rafael' },
    { value: 'San Simeon', label: 'San Simeon' },
    { value: 'Santa Ana', label: 'Santa Ana' },
    { value: 'Santa Barbara', label: 'Santa Barbara' },
    { value: 'Santa Clara', label: 'Santa Clara' },
    { value: 'Santa Clarita', label: 'Santa Clarita' },
    { value: 'Santa Cruz', label: 'Santa Cruz' },
    { value: 'Santa Monica', label: 'Santa Monica' },
    { value: 'Santa Rosa', label: 'Santa Rosa' },
    { value: 'Sausalito', label: 'Sausalito' },
    { value: 'Simi Valley', label: 'Simi Valley' },
    { value: 'Sonoma', label: 'Sonoma' },
    { value: 'South San Francisco', label: 'South San Francisco' },
    { value: 'Stockton', label: 'Stockton' },
    { value: 'Sunnyvale', label: 'Sunnyvale' },
    { value: 'Susanville', label: 'Susanville' },
    { value: 'Thousand Oaks', label: 'Thousand Oaks' },
    { value: 'Torrance', label: 'Torrance' },
    { value: 'Turlock', label: 'Turlock' },
    { value: 'Ukiah', label: 'Ukiah' },
    { value: 'Vallejo', label: 'Vallejo' },
    { value: 'Ventura', label: 'Ventura' },
    { value: 'Victorville', label: 'Victorville' },
    { value: 'Visalia', label: 'Visalia' },
    { value: 'Walnut Creek', label: 'Walnut Creek' },
    { value: 'Watts', label: 'Watts' },
    { value: ' West Covina', label: 'West Covina' },
    { value: 'Whittier', label: 'Whittier' },
    { value: 'Woodland', label: 'Woodland' },
    { value: ' Yorba Linda', label: 'Yorba Linda' },
    { value: ' Yuba City', label: 'Yuba City' },

];


export const citysColorado = [
    { value: 'Alamosa', label: 'Alamosa' },
    { value: 'Aspen', label: 'Aspen' },
    { value: 'Aurora', label: 'Aurora' },
    { value: 'Boulder', label: 'Boulder' },
    { value: 'Breckenridge', label: 'Breckenridge' },
    { value: 'Brighton', label: 'Brighton' },
    { value: 'Canon City', label: 'Canon City' },
    { value: 'Central City', label: 'Central City' },
    { value: 'Climax', label: 'Climax' },
    { value: 'Colorado Springs', label: 'Colorado Springs' },
    { value: 'Cortez', label: 'Cortez' },
    { value: 'Cripple Creek', label: 'Cripple Creek' },
    { value: 'Denver', label: 'Denver' },
    { value: 'Durango', label: 'Durango' },
    { value: 'Englewood', label: 'Englewood' },
    { value: 'Estes Park', label: 'Estes Park' },
    { value: 'Fort Collins', label: 'Fort Collins' },
    { value: 'Fort Morgan', label: 'Fort Morgan' },
    { value: 'Georgetown', label: 'Georgetown' },
    { value: 'Glenwood Springs', label: 'Glenwood Springs' },
    { value: 'Golden', label: 'Golden' },
    { value: 'Grand Junction', label: 'Grand Junction' },
    { value: 'Greeley', label: 'Greeley' },
    { value: 'Gunnison', label: 'Gunnison' },
    { value: 'La Junta', label: 'La Junta' },
    { value: 'Leadville', label: 'Leadville' },
    { value: 'Littleton', label: 'Littleton' },
    { value: 'Longmont', label: 'Longmont' },
    { value: 'Loveland', label: 'Loveland' },
    { value: 'Montrose', label: 'Montrose' },
    { value: 'Ouray', label: 'Ouray' },
    { value: 'Pagosa Springs', label: 'Pagosa Springs' },
    { value: 'Pueblo', label: 'Pueblo' },
    { value: 'Silverton', label: 'Silverton' },
    { value: 'Steamboat Springs', label: 'Steamboat Springs' },
    { value: 'Sterling', label: 'Sterling' },
    { value: 'Telluride', label: 'Telluride' },
    { value: 'Trinidad', label: 'Trinidad' },
    { value: 'Vail', label: 'Vail' },
    { value: 'Walsenburg', label: 'Walsenburg' },
    { value: 'Westminster', label: 'Westminster' },
];

export const citysConnecticut = [
    { value: 'Ansonia', label: 'Ansonia' },
    { value: 'Berlin', label: 'Berlin' },
    { value: 'Bloomfield', label: 'Bloomfield' },
    { value: 'Branford', label: 'Branford' },
    { value: 'Bridgeport', label: 'Bridgeport' },
    { value: 'Bristol', label: 'Bristol' },
    { value: 'Coventry', label: 'Coventry' },
    { value: 'Danbury', label: 'Danbury' },
    { value: 'Darien', label: 'Darien' },
    { value: 'Derby', label: 'Derby' },
    { value: 'East Hartford', label: 'East Hartford' },
    { value: 'East Haven', label: 'East Haven' },
    { value: 'Enfield', label: 'Enfield' },
    { value: 'Fairfield', label: 'Fairfield' },
    { value: 'Farmington', label: 'Farmington' },
    { value: 'Greenwich', label: 'Greenwich' },
    { value: 'Groton', label: 'Groton' },
    { value: 'Guilford', label: 'Guilford' },
    { value: 'Hamden', label: 'Hamden' },
    { value: 'Hartford', label: 'Hartford' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Litchfield', label: 'Litchfield' },
    { value: 'Manchester', label: 'Manchester' },
    { value: 'Mansfield', label: 'Mansfield' },
    { value: 'Meriden', label: 'Meriden' },
    { value: 'Middletown', label: 'Middletown' },
    { value: 'Milford', label: 'Milford' },
    { value: 'Mystic', label: 'Mystic' },
    { value: 'Naugatuck', label: 'Naugatuck' },
    { value: 'New Britain', label: 'New Britain' },
    { value: 'New Haven', label: 'New Haven' },
    { value: 'New London', label: 'New London' },
    { value: 'North Haven', label: 'North Haven' },
    { value: 'Norwalk', label: 'Norwalk' },
    { value: 'Norwich', label: 'Norwich' },
    { value: 'Old Saybrook', label: 'Old Saybrook' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Seymour', label: 'Seymour' },
    { value: 'Shelton', label: 'Shelton' },
    { value: 'Simsbury', label: 'Simsbury' },
    { value: 'Southington', label: 'Southington' },
    { value: 'Stamford', label: 'Stamford' },
    { value: 'Stonington', label: 'Stonington' },
    { value: 'Stratford', label: 'Stratford' },
    { value: 'Torrington', label: 'Torrington' },
    { value: 'Wallingford', label: 'Wallingford' },
    { value: 'Waterbury', label: 'Waterbury' },
    { value: 'Waterford', label: 'Waterford' },
    { value: 'Watertown', label: 'Watertown' },
    { value: 'West Hartford', label: 'West Hartford' },
    { value: 'West Haven', label: 'West Haven' },
    { value: 'Westport', label: 'Westport' },
    { value: 'Wethersfield', label: 'Wethersfield' },
    { value: 'Willimantic', label: 'Willimantic' },
    { value: 'Windham', label: 'Windham' },
    { value: 'Windsor', label: 'Windsor' },
    { value: 'Windsor Locks', label: 'Windsor Locks' },
    { value: 'Winsted', label: 'Winsted' },
];

export const citysDelaware = [
    { value: 'Dover', label: 'Dover' },
    { value: 'Lewes', label: 'Lewes' },
    { value: 'Milford', label: 'Milford' },
    { value: 'New Castle', label: 'New Castle' },
    { value: 'Newark', label: 'Newark' },
    { value: 'Smyrna', label: 'Smyrna' },
    { value: 'Wilmington', label: 'Wilmington' },
];
export const citysFlorida = [
    { value: 'Apalachicola', label: 'Apalachicola' },
    { value: 'Bartow', label: 'Bartow' },
    { value: 'Belle Glade', label: 'Belle Glade' },
    { value: 'Boca Raton', label: 'Boca Raton' },
    { value: 'Bradenton', label: 'Bradenton' },
    { value: 'Cape Coral', label: 'Cape Coral' },
    { value: 'Clearwater', label: 'Clearwater' },
    { value: 'Cocoa Beach', label: 'Cocoa Beach' },
    { value: 'Cocoa-Rockledge', label: 'Cocoa-Rockledge' },
    { value: 'Coral Gables', label: 'Coral Gables' },
    { value: 'Daytona Beach', label: 'Daytona Beach' },
    { value: 'De Land', label: 'De Land' },
    { value: 'Deerfield Beach', label: 'Deerfield Beach' },
    { value: 'Delray Beach', label: 'Delray Beach' },
    { value: 'Fernandina Beach', label: 'Fernandina Beach' },
    { value: 'Fort Lauderdale', label: 'Fort Lauderdale' },
    { value: 'Fort Myers', label: 'Fort Myers' },
    { value: 'Fort Pierce', label: 'Fort Pierce' },
    { value: 'Fort Walton Beach', label: 'Fort Walton Beach' },
    { value: 'Gainesville', label: 'Gainesville' },
    { value: 'Hallandale Beach', label: 'Hallandale Beach' },
    { value: 'Hialeah', label: 'Hialeah' },
    { value: 'Hollywood', label: 'Hollywood' },
    { value: 'Homestead', label: 'Homestead' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Key West', label: 'Key West' },
    { value: 'Lake City', label: 'Lake City' },
    { value: 'Lake Wales', label: 'Lake Wales' },
    { value: 'Lakeland', label: 'Lakeland' },
    { value: 'Largo', label: 'Largo' },
    { value: 'Melbourne', label: 'Melbourne' },
    { value: 'Miami', label: 'Miami' },
    { value: 'Miami Beach', label: 'Miami Beach' },
    { value: 'Naples', label: 'Naples' },
    { value: 'New Smyrna Beach', label: 'New Smyrna Beach' },
    { value: 'Ocala', label: 'Ocala' },
    { value: 'Orlando', label: 'Orlando' },
    { value: 'Ormond Beach', label: 'Ormond Beach' },
    { value: 'Palatka', label: 'Palatka' },
    { value: 'Palm Bay', label: 'Palm Bay' },
    { value: 'Palm Beach', label: 'Palm Beach' },
    { value: 'Panama City', label: 'Panama City' },
    { value: 'Pensacola', label: 'Pensacola' },
    { value: 'Pompano Beach', label: 'Pompano Beach' },
    { value: 'Saint Augustine', label: 'Saint Augustine' },
    { value: 'Saint Petersburg', label: 'Saint Petersburg' },
    { value: 'Sanford', label: 'Sanford' },
    { value: 'Sarasota', label: 'Sarasota' },
    { value: 'Sebring', label: 'Sebring' },
    { value: 'Tallahassee', label: 'Tallahassee' },
    { value: 'Tampa', label: 'Tampa' },
    { value: 'Tarpon Springs', label: 'Tarpon Springs' },
    { value: 'Titusville', label: 'Titusville' },
    { value: 'Venice', label: 'Venice' },
    { value: 'West Palm Beach', label: 'West Palm Beach' },
    { value: 'White Springs', label: 'White Springs' },
    { value: 'Winter Haven', label: 'Winter Haven' },
    { value: 'Winter Park', label: 'Winter Park' },
];

export const citysGeorgia = [
    { value: 'Albany', label: 'Albany' },
    { value: 'Americus', label: 'Americus' },
    { value: 'Andersonville', label: 'Andersonville' },
    { value: 'Athens', label: 'Athens' },
    { value: 'Atlanta', label: 'Atlanta' },
    { value: 'Augusta', label: 'Augusta' },
    { value: 'Bainbridge', label: 'Bainbridge' },
    { value: 'Blairsville', label: 'Blairsville' },
    { value: 'Brunswick', label: 'Brunswick' },
    { value: 'Calhoun', label: 'Calhoun' },
    { value: 'Carrollton', label: 'Carrollton' },
    { value: 'Columbus', label: 'Columbus' },
    { value: 'Dahlonega', label: 'Dahlonega' },
    { value: 'Dalton', label: 'Dalton' },
    { value: 'Darien', label: 'Darien' },
    { value: 'Decatur', label: 'Decatur' },
    { value: 'Douglas', label: 'Douglas' },
    { value: 'East Point', label: 'East Point' },
    { value: 'Fitzgerald', label: 'Fitzgerald' },
    { value: 'Fort Valley', label: 'Fort Valley' },
    { value: 'Gainesville', label: 'Gainesville' },
    { value: 'La Grange', label: 'La Grange' },
    { value: 'Macon', label: 'Macon' },
    { value: 'Marietta', label: 'Marietta' },
    { value: 'Milledgeville', label: 'Milledgeville' },
    { value: 'Plains', label: 'Plains' },
    { value: 'Rome', label: 'Rome' },
    { value: 'Savannah', label: 'Savannah' },
    { value: 'Toccoa', label: 'Toccoa' },
    { value: 'Valdosta', label: 'Valdosta' },
    { value: 'Warm Springs', label: 'Warm Springs' },
    { value: 'Warner Robins', label: 'Warner Robins' },
    { value: 'Washington', label: 'Washington' },
    { value: 'Waycross', label: 'Waycross' },
];

export const citysHawaii = [
    { value: 'Hanalei', label: 'Hanalei' },
    { value: 'Hilo', label: 'Hilo' },
    { value: 'Honaunau', label: 'Honaunau' },
    { value: 'Honolulu', label: 'Honolulu' },
    { value: 'Kahului', label: 'Kahului' },
    { value: 'Kaneohe', label: 'Kaneohe' },
    { value: 'Kapaa', label: 'Kapaa' },
    { value: 'Kawaihae', label: 'Kawaihae' },
    { value: 'Lahaina', label: 'Lahaina' },
    { value: 'Laie', label: 'Laie' },
    { value: 'Wahiawa', label: 'Wahiawa' },
    { value: 'Wailuku', label: 'Wailuku' },
    { value: 'Waimea', label: 'Waimea' },
];

export const citysIdaho = [
    { value: 'Blackfoot', label: 'Blackfoot' },
    { value: 'Boise', label: 'Boise' },
    { value: 'Bonners Ferry', label: 'Bonners Ferry' },
    { value: 'Caldwell', label: 'Caldwell' },
    { value: 'Coeur d’Alene', label: 'Coeur d’Alene' },
    { value: 'Idaho City', label: 'Idaho City' },
    { value: 'Idaho Falls', label: 'Idaho Falls' },
    { value: 'Kellogg', label: 'Kellogg' },
    { value: 'Lewiston', label: 'Lewiston' },
    { value: 'Moscow', label: 'Moscow' },
    { value: 'Nampa', label: 'Nampa' },
    { value: 'Pocatello', label: 'Pocatello' },
    { value: 'Priest River', label: 'Priest River' },
    { value: 'Rexburg', label: 'Rexburg' },
    { value: 'Sun Valley', label: 'Sun Valley' },
    { value: 'Twin Falls', label: 'Twin Falls' },
];

export const citysIllinois = [
    { value: 'Alton', label: 'Alton' },
    { value: 'Arlington Heights', label: 'Arlington Heights' },
    { value: 'Arthur', label: 'Arthur' },
    { value: 'Aurora', label: 'Aurora' },
    { value: 'Belleville', label: 'Belleville' },
    { value: 'Belvidere', label: 'Belvidere' },
    { value: 'Bloomington', label: 'Bloomington' },
    { value: 'Brookfield', label: 'Brookfield' },
    { value: 'Cahokia', label: 'Cahokia' },
    { value: 'Cairo', label: 'Cairo' },
    { value: 'Calumet City', label: 'Calumet City' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Carbondale', label: 'Carbondale' },
    { value: 'Carlinville', label: 'Carlinville' },
    { value: 'Carthage', label: 'Carthage' },
    { value: 'Centralia', label: 'Centralia' },
    { value: 'Champaign', label: 'Champaign' },
    { value: 'Charleston', label: 'Charleston' },
    { value: 'Chester', label: 'Chester' },
    { value: 'Chicago', label: 'Chicago' },
    { value: 'Chicago Heights', label: 'Chicago Heights' },
    { value: 'Cicero', label: 'Cicero' },
    { value: 'Collinsville', label: 'Collinsville' },
    { value: 'Danville', label: 'Danville' },
    { value: 'Decatur', label: 'Decatur' },
    { value: 'DeKalb', label: 'DeKalb' },
    { value: 'Des Plaines', label: 'Des Plaines' },
    { value: 'Dixon', label: 'Dixon' },
    { value: 'East Moline', label: 'East Moline' },
    { value: 'East Saint Louis', label: 'East Saint Louis' },
    { value: 'Effingham', label: 'Effingham' },
    { value: 'Elgin', label: 'Elgin' },
    { value: 'Elmhurst', label: 'Elmhurst' },
    { value: 'Evanston', label: 'Evanston' },
    { value: 'Freeport', label: 'Freeport' },
    { value: 'Galena', label: 'Galena' },
    { value: 'Galesburg', label: 'Galesburg' },
    { value: 'Glen Ellyn', label: 'Glen Ellyn' },
    { value: 'Glenview', label: 'Glenview' },
    { value: 'Granite City', label: 'Granite City' },
    { value: 'Harrisburg', label: 'Harrisburg' },
    { value: 'Herrin', label: 'Herrin' },
    { value: 'Highland Park', label: 'Highland Park' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Joliet', label: 'Joliet' },
    { value: 'Kankakee', label: 'Kankakee' },
    { value: 'Kaskaskia', label: 'Kaskaskia' },
    { value: 'Kewanee', label: 'Kewanee' },
    { value: 'La Salle', label: 'La Salle' },
    { value: 'Lake Forest', label: 'Lake Forest' },
    { value: 'Libertyville', label: 'Libertyville' },
    { value: 'Lincoln', label: 'Lincoln' },
    { value: 'Lisle', label: 'Lisle' },
    { value: 'Lombard', label: 'Lombard' },
    { value: 'Macomb', label: 'Macomb' },
    { value: 'Mattoon', label: 'Mattoon' },
    { value: 'Moline', label: 'Moline' },
    { value: 'Monmouth', label: 'Monmouth' },
    { value: 'Mount Vernon', label: 'Mount Vernon' },
    { value: 'Mundelein', label: 'Mundelein' },
    { value: 'Naperville', label: 'Naperville' },
    { value: 'Nauvoo', label: 'Nauvoo' },
    { value: 'Normal', label: 'Normal' },
    { value: 'North Chicago', label: 'North Chicago' },
    { value: 'Oak Park', label: 'Oak Park' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Ottawa', label: 'Ottawa' },
    { value: 'Palatine', label: 'Palatine' },
    { value: 'Park Forest', label: 'Park Forest' },
    { value: 'Park Ridge', label: 'Park Ridge' },
    { value: 'Pekin', label: 'Pekin' },
    { value: 'Peoria', label: 'Peoria' },
    { value: 'Petersburg', label: 'Petersburg' },
    { value: 'Pontiac', label: 'Pontiac' },
    { value: 'Quincy', label: 'Quincy' },
    { value: 'Rantoul', label: 'Rantoul' },
    { value: 'River Forest', label: 'River Forest' },
    { value: 'Rock Island', label: 'Rock Island' },
    { value: 'Rockford', label: 'Rockford' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Shawneetown', label: 'Shawneetown' },
    { value: 'Skokie', label: 'Skokie' },
    { value: 'South Holland', label: 'South Holland' },
    { value: 'Springfield', label: 'Springfield' },
    { value: 'Streator', label: 'Streator' },
    { value: 'Summit', label: 'Summit' },
    { value: 'Urbana', label: 'Urbana' },
    { value: 'Vandalia', label: 'Vandalia' },
    { value: 'Virden', label: 'Virden' },
    { value: 'Waukegan', label: 'Waukegan' },
    { value: 'Wheaton', label: 'Wheaton' },
    { value: 'Wilmette', label: 'Wilmette' },
    { value: 'Winnetka', label: 'Winnetka' },
    { value: 'Wood River', label: 'Wood River' },
    { value: 'Zion', label: 'Zion' },
];

export const citysIndiana = [
    { value: 'Anderson', label: 'Anderson' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Bloomington', label: 'Bloomington' },
    { value: 'Columbus', label: 'Columbus' },
    { value: 'Connersville', label: 'Connersville' },
    { value: 'Corydon', label: 'Corydon' },
    { value: 'Crawfordsville', label: 'Crawfordsville' },
    { value: 'East Chicago', label: 'East Chicago' },
    { value: 'Elkhart', label: 'Elkhart' },
    { value: 'Elwood', label: 'Elwood' },
    { value: 'Evansville', label: 'Evansville' },
    { value: 'Fort Wayne', label: 'Fort Wayne' },
    { value: 'French Lick', label: 'French Lick' },
    { value: 'Gary', label: 'Gary' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Goshen', label: 'Goshen' },
    { value: 'Greenfield', label: 'Greenfield' },
    { value: 'Hammond', label: 'Hammond' },
    { value: 'Hobart', label: 'Hobart' },
    { value: 'Huntington', label: 'Huntington' },
    { value: 'Indianapolis', label: 'Indianapolis' },
    { value: 'Jeffersonville', label: 'Jeffersonville' },
    { value: 'Kokomo', label: 'Kokomo' },
    { value: 'Lafayette', label: 'Lafayette' },
    { value: 'Madison', label: 'Madison' },
    { value: 'Marion', label: 'Marion' },
    { value: 'Michigan City', label: 'Michigan City' },
    { value: 'Mishawaka', label: 'Mishawaka' },
    { value: 'Muncie', label: 'Muncie' },
    { value: 'Nappanee', label: 'Nappanee' },
    { value: 'Nashville', label: 'Nashville' },
    { value: 'New Albany', label: 'New Albany' },
    { value: 'New Castle', label: 'New Castle' },
    { value: 'New Harmony', label: 'New Harmony' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Plymouth', label: 'Plymouth' },
    { value: 'Richmond', label: 'Richmond' },
    { value: 'Santa Claus', label: 'Santa Claus' },
    { value: 'Shelbyville', label: 'Shelbyville' },
    { value: 'South Bend', label: 'South Bend' },
    { value: 'Terre Haute', label: 'Terre Haute' },
    { value: 'Valparaiso', label: 'Valparaiso' },
    { value: 'Vincennes', label: 'Vincennes' },
    { value: 'Wabash', label: 'Wabash' },
    { value: 'West Lafayette', label: 'West Lafayette' },

];
export const citysIowa = [
    { value: 'Amana Colonies', label: 'Amana Colonies' },
    { value: 'Ames', label: 'Ames' },
    { value: 'Boone', label: 'Boone' },
    { value: 'Burlington', label: 'Burlington' },
    { value: 'Cedar Falls', label: 'Cedar Falls' },
    { value: 'Cedar Rapids', label: 'Cedar Rapids' },
    { value: 'Charles City', label: 'Charles City' },
    { value: 'Cherokee', label: 'Cherokee' },
    { value: 'Clinton', label: 'Clinton' },
    { value: 'Council Bluffs', label: 'Council Bluffs' },
    { value: 'Davenport', label: 'Davenport' },
    { value: 'Des Moines', label: 'Des Moines' },
    { value: 'Dubuque', label: 'Dubuque' },
    { value: 'Estherville', label: 'Estherville' },
    { value: 'Fairfield', label: 'Fairfield' },
    { value: 'Fort Dodge', label: 'Fort Dodge' },
    { value: 'Grinnell', label: 'Grinnell' },
    { value: 'Indianola', label: 'Indianola' },
    { value: 'Iowa City', label: 'Iowa City' },
    { value: 'Keokuk', label: 'Keokuk' },
    { value: 'Mason City', label: 'Mason City' },
    { value: 'Mount Pleasant', label: 'Mount Pleasant' },
    { value: 'Muscatine', label: 'Muscatine' },
    { value: 'Newton', label: 'Newton' },
    { value: 'Oskaloosa', label: 'Oskaloosa' },
    { value: 'Ottumwa', label: 'Ottumwa' },
    { value: 'Sioux City', label: 'Sioux City' },
    { value: 'Waterloo', label: 'Waterloo' },
    { value: 'Webster City', label: 'Webster City' },
    { value: 'West Des Moines', label: 'West Des Moines' },
];

export const citysKansas = [
    { value: 'Abilene', label: 'Abilene' },
    { value: 'Arkansas City', label: 'Arkansas City' },
    { value: 'Atchison', label: 'Atchison' },
    { value: 'Chanute', label: 'Chanute' },
    { value: 'Coffeyville', label: 'Coffeyville' },
    { value: 'Council Grove', label: 'Council Grove' },
    { value: 'Dodge City', label: 'Dodge City' },
    { value: 'Emporia', label: 'Emporia' },
    { value: 'Fort Scott', label: 'Fort Scott' },
    { value: 'Garden City', label: 'Garden City' },
    { value: 'Great Bend', label: 'Great Bend' },
    { value: 'Hays', label: 'Hays' },
    { value: 'Hutchinson', label: 'Hutchinson' },
    { value: 'Independence', label: 'Independence' },
    { value: 'Junction City', label: 'Junction City' },
    { value: 'Kansas City', label: 'Kansas City' },
    { value: 'Lawrence', label: 'Lawrence' },
    { value: 'Leavenworth', label: 'Leavenworth' },
    { value: 'Liberal', label: 'Liberal' },
    { value: 'Manhattan', label: 'Manhattan' },
    { value: 'McPherson', label: 'McPherson' },
    { value: 'Medicine Lodge', label: 'Medicine Lodge' },
    { value: 'Newton', label: 'Newton' },
    { value: 'Olathe', label: 'Olathe' },
    { value: 'Osawatomie', label: 'Osawatomie' },
    { value: 'Ottawa', label: 'Ottawa' },
    { value: 'Overland Park', label: 'Overland Park' },
    { value: 'Pittsburg', label: 'Pittsburg' },
    { value: 'Salina', label: 'Salina' },
    { value: 'Shawnee', label: 'Shawnee' },
    { value: 'Smith Center', label: 'Smith Center' },
    { value: 'Topeka', label: 'Topeka' },
    { value: 'Wichita', label: 'Wichita' },
];


export const citysKentucky = [
    { value: 'Ashland', label: 'Ashland' },
    { value: 'Barbourville', label: 'Barbourville' },
    { value: 'Bardstown', label: 'Bardstown' },
    { value: 'Berea', label: 'Berea' },
    { value: 'Boonesborough', label: 'Boonesborough' },
    { value: 'Bowling Green', label: 'Bowling Green' },
    { value: 'Campbellsville', label: 'Campbellsville' },
    { value: 'Covington', label: 'Covington' },
    { value: 'Danville', label: 'Danville' },
    { value: 'Elizabethtown', label: 'Elizabethtown' },
    { value: 'Frankfort', label: 'Frankfort' },
    { value: 'Harlan', label: 'Harlan' },
    { value: 'Harrodsburg', label: 'Harrodsburg' },
    { value: 'Hazard', label: 'Hazard' },
    { value: 'Henderson', label: 'Henderson' },
    { value: 'Hodgenville', label: 'Hodgenville' },
    { value: 'Hopkinsville', label: 'Hopkinsville' },
    { value: 'Lexington', label: 'Lexington' },
    { value: 'Louisville', label: 'Louisville' },
    { value: 'Mayfield', label: 'Mayfield' },
    { value: 'Maysville', label: 'Maysville' },
    { value: 'Middlesboro', label: 'Middlesboro' },
    { value: 'Newport', label: 'Newport' },
    { value: 'Owensboro', label: 'Owensboro' },
    { value: 'Paducah', label: 'Paducah' },
    { value: 'Paris', label: 'Paris' },
    { value: 'Richmond', label: 'Richmond' },
];

export const citysLouisiana = [
    { value: 'Abbeville', label: 'Abbeville' },
    { value: 'Alexandria', label: 'Alexandria' },
    { value: 'Bastrop', label: 'Bastrop' },
    { value: 'Baton Rouge', label: 'Baton Rouge' },
    { value: 'Bogalusa', label: 'Bogalusa' },
    { value: 'Bossier City', label: 'Bossier City' },
    { value: 'Gretna', label: 'Gretna' },
    { value: 'Houma', label: 'Houma' },
    { value: 'Lafayette', label: 'Lafayette' },
    { value: 'Lake Charles', label: 'Lake Charles' },
    { value: 'Monroe', label: 'Monroe' },
    { value: 'Morgan City', label: 'Morgan City' },
    { value: 'Natchitoches', label: 'Natchitoches' },
    { value: 'New Iberia', label: 'New Iberia' },
    { value: 'New Orleans', label: 'New Orleans' },
    { value: 'Opelousas', label: 'Opelousas' },
    { value: 'Ruston', label: 'Ruston' },
    { value: 'Saint Martinville', label: 'Saint Martinville' },
    { value: 'Shreveport', label: 'Shreveport' },
    { value: 'Thibodaux   ', label: 'Thibodaux   ' },
];

export const citysMaine = [
    { value: 'Auburn', label: 'Auburn' },
    { value: 'Augusta', label: 'Augusta' },
    { value: 'Bangor', label: 'Bangor' },
    { value: 'Bar Harbor', label: 'Bar Harbor' },
    { value: 'Bath', label: 'Bath' },
    { value: 'Belfast', label: 'Belfast' },
    { value: 'Biddeford', label: 'Biddeford' },
    { value: 'Boothbay Harbor', label: 'Boothbay Harbor' },
    { value: 'Brunswick', label: 'Brunswick' },
    { value: 'Calais', label: 'Calais' },
    { value: 'Caribou', label: 'Caribou' },
    { value: 'Castine', label: 'Castine' },
    { value: 'Eastport', label: 'Eastport' },
    { value: 'Ellsworth', label: 'Ellsworth' },
    { value: 'Farmington', label: 'Farmington' },
    { value: 'Fort Kent', label: 'Fort Kent' },
    { value: 'Gardiner', label: 'Gardiner' },
    { value: 'Houlton', label: 'Houlton' },
    { value: 'Kennebunkport', label: 'Kennebunkport' },
    { value: 'Kittery', label: 'Kittery' },
    { value: 'Lewiston', label: 'Lewiston' },
    { value: 'Lubec', label: 'Lubec' },
    { value: 'Machias', label: 'Machias' },
    { value: 'Orono', label: 'Orono' },
    { value: 'Portland', label: 'Portland' },
    { value: 'Presque Isle', label: 'Presque Isle' },
    { value: 'Rockland', label: 'Rockland' },
    { value: 'Rumford', label: 'Rumford' },
    { value: 'Saco', label: 'Saco' },
    { value: 'Scarborough', label: 'Scarborough' },
    { value: 'Waterville', label: 'Waterville' },
    { value: 'York', label: 'York' },
];

export const citysMaryland = [
    { value: 'Aberdeen', label: 'Aberdeen' },
    { value: 'Annapolis', label: 'Annapolis' },
    { value: 'Baltimore', label: 'Baltimore' },
    { value: 'Bethesda-Chevy Chase', label: 'Bethesda-Chevy Chase' },
    { value: 'Bowie', label: 'Bowie' },
    { value: 'Cambridge', label: 'Cambridge' },
    { value: 'Catonsville', label: 'Catonsville' },
    { value: 'College Park', label: 'College Park' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Cumberland', label: 'Cumberland' },
    { value: 'Easton', label: 'Easton' },
    { value: 'Elkton', label: 'Elkton' },
    { value: 'Emmitsburg', label: 'Emmitsburg' },
    { value: 'Frederick', label: 'Frederick' },
    { value: 'Greenbelt', label: 'Greenbelt' },
    { value: 'Hagerstown', label: 'Hagerstown' },
    { value: 'Hyattsville', label: 'Hyattsville' },
    { value: 'Laurel', label: 'Laurel' },
    { value: 'Oakland', label: 'Oakland' },
    { value: 'Ocean City', label: 'Ocean City' },
    { value: 'Rockville', label: 'Rockville' },
    { value: 'Saint Marys City', label: 'Saint Marys City' },
    { value: 'Salisbury', label: 'Salisbury' },
    { value: 'Silver Spring', label: 'Silver Spring' },
    { value: 'Takoma Park', label: 'Takoma Park' },
    { value: 'Towson', label: 'Towson' },
    { value: 'Westminster', label: 'Westminster' },
];

export const citysMassachusetts = [
    { value: 'Abington', label: 'Abington' },
    { value: 'Adams', label: 'Adams' },
    { value: 'Amesbury', label: 'Amesbury' },
    { value: 'Amherst', label: 'Amherst' },
    { value: 'Andover', label: 'Andover' },
    { value: 'Arlington', label: 'Arlington' },
    { value: 'Athol', label: 'Athol' },
    { value: 'Attleboro', label: 'Attleboro' },
    { value: 'Barnstable', label: 'Barnstable' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Beverly', label: 'Beverly' },
    { value: 'Boston', label: 'Boston' },
    { value: 'Bourne', label: 'Bourne' },
    { value: 'Braintree', label: 'Braintree' },
    { value: 'Brockton', label: 'Brockton' },
    { value: 'Brookline', label: 'Brookline' },
    { value: 'Cambridge', label: 'Cambridge' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Charlestown', label: 'Charlestown' },
    { value: 'Chelmsford', label: 'Chelmsford' },
    { value: 'Chelsea', label: 'Chelsea' },
    { value: 'Chicopee', label: 'Chicopee' },
    { value: 'Clinton', label: 'Clinton' },
    { value: 'Cohasset', label: 'Cohasset' },
    { value: 'Concord', label: 'Concord' },
    { value: 'Danvers', label: 'Danvers' },
    { value: 'Dartmouth', label: 'Dartmouth' },
    { value: 'Dedham', label: 'Dedham' },
    { value: 'Dennis', label: 'Dennis' },
    { value: 'Duxbury', label: 'Duxbury' },
    { value: 'Eastham', label: 'Eastham' },
    { value: 'Edgartown', label: 'Edgartown' },
    { value: 'Everett', label: 'Everett' },
    { value: 'Fairhaven', label: 'Fairhaven' },
    { value: 'Fall River', label: 'Fall River' },
    { value: 'Falmouth', label: 'Falmouth' },
    { value: 'Fitchburg', label: 'Fitchburg' },
    { value: 'Framingham', label: 'Framingham' },
    { value: 'Gloucester', label: 'Gloucester' },
    { value: 'Great Barrington', label: 'Great Barrington' },
    { value: 'Greenfield', label: 'Greenfield' },
    { value: 'Groton', label: 'Groton' },
    { value: 'Harwich', label: 'Harwich' },
    { value: 'Haverhill', label: 'Haverhill' },
    { value: 'Hingham', label: 'Hingham' },
    { value: 'Holyoke', label: 'Holyoke' },
    { value: 'Hyannis', label: 'Hyannis' },
    { value: 'Ipswich', label: 'Ipswich' },
    { value: 'Lawrence', label: 'Lawrence' },
    { value: 'Lenox', label: 'Lenox' },
    { value: 'Leominster', label: 'Leominster' },
    { value: 'Lexington', label: 'Lexington' },
    { value: 'Lowell', label: 'Lowell' },
    { value: 'Ludlow', label: 'Ludlow' },
    { value: 'Lynn', label: 'Lynn' },
    { value: 'Malden', label: 'Malden' },
    { value: 'Marblehead', label: 'Marblehead' },
    { value: 'Marlborough', label: 'Marlborough' },
    { value: 'Medford', label: 'Medford' },
    { value: 'Milton', label: 'Milton' },
    { value: 'Nahant', label: 'Nahant' },
    { value: 'Natick', label: 'Natick' },
    { value: 'New Bedford', label: 'New Bedford' },
    { value: 'Newburyport', label: 'Newburyport' },
    { value: 'Newton', label: 'Newton' },
    { value: 'North Adams', label: 'North Adams' },
    { value: 'Northampton', label: 'Northampton' },
    { value: 'Norton', label: 'Norton' },
    { value: 'Norwood', label: 'Norwood' },
    { value: 'Peabody', label: 'Peabody' },
    { value: 'Pittsfield', label: 'Pittsfield' },
    { value: 'Plymouth', label: 'Plymouth' },
    { value: 'Provincetown', label: 'Provincetown' },
    { value: 'Quincy', label: 'Quincy' },
    { value: 'Randolph', label: 'Randolph' },
    { value: 'Revere', label: 'Revere' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Sandwich', label: 'Sandwich' },
    { value: 'Saugus', label: 'Saugus' },
    { value: 'Somerville', label: 'Somerville' },
    { value: 'South Hadley', label: 'South Hadley' },
    { value: 'Springfield', label: 'Springfield' },
    { value: 'Stockbridge', label: 'Stockbridge' },
    { value: 'Stoughton', label: 'Stoughton' },
    { value: 'Sturbridge', label: 'Sturbridge' },
    { value: 'Sudbury', label: 'Sudbury' },
    { value: 'Taunton', label: 'Taunton' },
    { value: 'Tewksbury', label: 'Tewksbury' },
    { value: 'Truro', label: 'Truro' },
    { value: 'Watertown', label: 'Watertown' },
    { value: 'Webster', label: 'Webster' },
    { value: 'Wellesley', label: 'Wellesley' },
    { value: 'Wellfleet', label: 'Wellfleet' },
    { value: 'West Bridgewater', label: 'West Bridgewater' },
    { value: 'West Springfield', label: 'West Springfield' },
    { value: 'Westfield', label: 'Westfield' },
    { value: 'Weymouth', label: 'Weymouth' },
    { value: 'Whitman', label: 'Whitman' },
    { value: 'Williamstown', label: 'Williamstown' },
    { value: 'Woburn', label: 'Woburn' },
    { value: 'Woods Hole', label: 'Woods Hole' },
    { value: 'Worcester', label: 'Worcester' },
];

export const citysMichigan = [
    { value: 'Adrian', label: 'Adrian' },
    { value: 'Alma', label: 'Alma' },
    { value: 'Ann Arbor', label: 'Ann Arbor' },
    { value: 'Battle Creek', label: 'Battle Creek' },
    { value: 'Bay City', label: 'Bay City' },
    { value: 'Benton Harbor', label: 'Benton Harbor' },
    { value: 'Bloomfield Hills', label: 'Bloomfield Hills' },
    { value: 'Cadillac', label: 'Cadillac' },
    { value: 'Charlevoix', label: 'Charlevoix' },
    { value: 'Cheboygan', label: 'Cheboygan' },
    { value: 'Dearborn', label: 'Dearborn' },
    { value: 'Detroit', label: 'Detroit' },
    { value: 'East Lansing', label: 'East Lansing' },
    { value: 'Eastpointe', label: 'Eastpointe' },
    { value: 'Ecorse', label: 'Ecorse' },
    { value: 'Escanaba', label: 'Escanaba' },
    { value: 'Flint', label: 'Flint' },
    { value: 'Grand Haven', label: 'Grand Haven' },
    { value: 'Grand Rapids', label: 'Grand Rapids' },
    { value: 'Grayling', label: 'Grayling' },
    { value: 'Grosse Pointe', label: 'Grosse Pointe' },
    { value: 'Hancock', label: 'Hancock' },
    { value: 'Highland Park', label: 'Highland Park' },
    { value: 'Holland', label: 'Holland' },
    { value: 'Houghton', label: 'Houghton' },
    { value: 'Interlochen', label: 'Interlochen' },
    { value: 'Iron Mountain', label: 'Iron Mountain' },
    { value: 'Ironwood', label: 'Ironwood' },
    { value: 'Ishpeming', label: 'Ishpeming' },
    { value: 'Jackson', label: 'Jackson' },
    { value: 'Kalamazoo', label: 'Kalamazoo' },
    { value: 'Lansing', label: 'Lansing' },
    { value: 'Livonia', label: 'Livonia' },
    { value: 'Ludington', label: 'Ludington' },
    { value: 'Mackinaw City', label: 'Mackinaw City' },
    { value: 'Manistee', label: 'Manistee' },
    { value: 'Marquette', label: 'Marquette' },
    { value: 'Menominee', label: 'Menominee' },
    { value: 'Midland', label: 'Midland' },
    { value: 'Monroe', label: 'Monroe' },
    { value: 'Mount Clemens', label: 'Mount Clemens' },
    { value: 'Mount Pleasant', label: 'Mount Pleasant' },
    { value: 'Muskegon', label: 'Muskegon' },
    { value: 'Niles', label: 'Niles' },
    { value: 'Petoskey', label: 'Petoskey' },
    { value: 'Pontiac', label: 'Pontiac' },
    { value: 'Port Huron', label: 'Port Huron' },
    { value: 'Royal Oak', label: 'Royal Oak' },
    { value: 'Saginaw', label: 'Saginaw' },
    { value: 'Saint Ignace', label: 'Saint Ignace' },
    { value: 'Saint Joseph', label: 'Saint Joseph' },
    { value: 'Sault Sainte Marie', label: 'Sault Sainte Marie' },
    { value: 'Traverse City', label: 'Traverse City' },
    { value: 'Trenton', label: 'Trenton' },
    { value: 'Warren', label: 'Warren' },
    { value: 'Wyandotte', label: 'Wyandotte' },
    { value: 'Ypsilanti', label: 'Ypsilanti' },
];

export const citysMinnesota = [
    { value: 'Albert Lea', label: 'Albert Lea' },
    { value: 'Alexandria', label: 'Alexandria' },
    { value: 'Austin', label: 'Austin' },
    { value: 'Bemidji', label: 'Bemidji' },
    { value: 'Bloomington', label: 'Bloomington' },
    { value: 'Brainerd', label: 'Brainerd' },
    { value: 'Crookston', label: 'Crookston' },
    { value: 'Duluth', label: 'Duluth' },
    { value: 'Ely', label: 'Ely' },
    { value: 'Eveleth', label: 'Eveleth' },
    { value: 'Faribault', label: 'Faribault' },
    { value: 'Fergus Falls', label: 'Fergus Falls' },
    { value: 'Hastings', label: 'Hastings' },
    { value: 'Hibbing', label: 'Hibbing' },
    { value: 'International Falls', label: 'International Falls' },
    { value: 'Little Falls', label: 'Little Falls' },
    { value: 'Mankato', label: 'Mankato' },
    { value: 'Minneapolis', label: 'Minneapolis' },
    { value: 'Moorhead', label: 'Moorhead' },
    { value: 'New Ulm', label: 'New Ulm' },
    { value: 'Northfield', label: 'Northfield' },
    { value: 'Owatonna', label: 'Owatonna' },
    { value: 'Pipestone', label: 'Pipestone' },
    { value: 'Red Wing', label: 'Red Wing' },
    { value: 'Rochester', label: 'Rochester' },
    { value: 'Saint Cloud', label: 'Saint Cloud' },
    { value: 'Saint Paul', label: 'Saint Paul' },
    { value: 'Sauk Centre', label: 'Sauk Centre' },
    { value: 'South Saint Paul', label: 'South Saint Paul' },
    { value: 'Stillwater', label: 'Stillwater' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Willmar', label: 'Willmar' },
    { value: 'Winona', label: 'Winona' },
];

export const citysMississippi = [
    { value: 'Bay Saint Louis', label: 'Bay Saint Louis' },
    { value: 'Biloxi', label: 'Biloxi' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Clarksdale', label: 'Clarksdale' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Columbus', label: 'Columbus' },
    { value: 'Corinth', label: 'Corinth' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Greenwood', label: 'Greenwood' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Gulfport', label: 'Gulfport' },
    { value: 'Hattiesburg', label: 'Hattiesburg' },
    { value: 'Holly Springs', label: 'Holly Springs' },
    { value: 'Jackson', label: 'Jackson' },
    { value: 'Laurel', label: 'Laurel' },
    { value: 'Meridian', label: 'Meridian' },
    { value: 'Natchez', label: 'Natchez' },
    { value: 'Ocean Springs', label: 'Ocean Springs' },
    { value: 'Oxford', label: 'Oxford' },
    { value: 'Pascagoula', label: 'Pascagoula' },
    { value: 'Pass Christian', label: 'Pass Christian' },
    { value: 'Philadelphia', label: 'Philadelphia' },
    { value: 'Port Gibson', label: 'Port Gibson' },
    { value: 'Starkville', label: 'Starkville' },
    { value: 'Tupelo', label: 'Tupelo' },
    { value: 'Vicksburg', label: 'Vicksburg' },
    { value: 'West Point', label: 'West Point' },
    { value: 'Yazoo City', label: 'Yazoo City' },
];


export const citysMissouri = [
    { value: 'Boonville', label: 'Boonville' },
    { value: 'Branson', label: 'Branson' },
    { value: 'Cape Girardeau', label: 'Cape Girardeau' },
    { value: 'Carthage', label: 'Carthage' },
    { value: 'Chillicothe', label: 'Chillicothe' },
    { value: 'Clayton', label: 'Clayton' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Excelsior Springs', label: 'Excelsior Springs' },
    { value: 'Ferguson', label: 'Ferguson' },
    { value: 'Florissant', label: 'Florissant' },
    { value: 'Fulton', label: 'Fulton' },
    { value: 'Hannibal', label: 'Hannibal' },
    { value: 'Independence', label: 'Independence' },
    { value: 'Jefferson City', label: 'Jefferson City' },
    { value: 'Joplin', label: 'Joplin' },
    { value: 'Kansas City', label: 'Kansas City' },
    { value: 'Kirksville', label: 'Kirksville' },
    { value: 'Lamar', label: 'Lamar' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lexington', label: 'Lexington' },
    { value: 'Maryville', label: 'Maryville' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Monett', label: 'Monett' },
    { value: 'Neosho', label: 'Neosho' },
    { value: 'New Madrid', label: 'New Madrid' },
    { value: 'Rolla', label: 'Rolla' },
    { value: 'Saint Charles', label: 'Saint Charles' },
    { value: 'Saint Joseph', label: 'Saint Joseph' },
    { value: 'Saint Louis', label: 'Saint Louis' },
    { value: 'Sainte Genevieve', label: 'Sainte Genevieve' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Sedalia', label: 'Sedalia' },
    { value: 'Springfield', label: 'Springfield' },
    { value: 'Warrensburg', label: 'Warrensburg' },
    { value: 'West Plains', label: 'West Plains' },
];

export const citysMontana = [
    { value: 'Anaconda', label: 'Anaconda' },
    { value: 'Billings', label: 'Billings' },
    { value: 'Bozeman', label: 'Bozeman' },
    { value: 'Butte', label: 'Butte' },
    { value: 'Dillon', label: 'Dillon' },
    { value: 'Fort Benton', label: 'Fort Benton' },
    { value: 'Glendive', label: 'Glendive' },
    { value: 'Great Falls', label: 'Great Falls' },
    { value: 'Havre', label: 'Havre' },
    { value: 'Helena', label: 'Helena' },
    { value: 'Kalispell', label: 'Kalispell' },
    { value: 'Lewistown', label: 'Lewistown' },
    { value: 'Livingston', label: 'Livingston' },
    { value: 'Miles City', label: 'Miles City' },
    { value: 'Missoula', label: 'Missoula' },
    { value: 'Virginia City', label: 'Virginia City' },
];

export const citysNebraska = [
    { value: 'Beatrice', label: 'Beatrice' },
    { value: 'Bellevue', label: 'Bellevue' },
    { value: 'Boys Town', label: 'Boys Town' },
    { value: 'Chadron', label: 'Chadron' },
    { value: 'Columbus', label: 'Columbus' },
    { value: 'Fremont', label: 'Fremont' },
    { value: 'Grand Island', label: 'Grand Island' },
    { value: 'Hastings', label: 'Hastings' },
    { value: 'Kearney', label: 'Kearney' },
    { value: 'Lincoln', label: 'Lincoln' },
    { value: 'McCook', label: 'McCook' },
    { value: 'Minden', label: 'Minden' },
    { value: 'Nebraska City', label: 'Nebraska City' },
    { value: 'Norfolk', label: 'Norfolk' },
    { value: 'North Platte', label: 'North Platte' },
    { value: 'Omaha', label: 'Omaha' },
    { value: 'Plattsmouth', label: 'Plattsmouth' },
    { value: 'Red Cloud', label: 'Red Cloud' },
    { value: 'Sidney', label: 'Sidney' },
];

export const citysNevada = [
    { value: 'Boulder City', label: 'Boulder City' },
    { value: 'Carson City', label: 'Carson City' },
    { value: 'Elko', label: 'Elko' },
    { value: 'Ely', label: 'Ely' },
    { value: 'Fallon', label: 'Fallon' },
    { value: 'Genoa', label: 'Genoa' },
    { value: 'Goldfield', label: 'Goldfield' },
    { value: 'Henderson', label: 'Henderson' },
    { value: 'Las Vegas', label: 'Las Vegas' },
    { value: 'North Las Vegas', label: 'North Las Vegas' },
    { value: 'Reno', label: 'Reno' },
    { value: 'Sparks', label: 'Sparks' },
    { value: 'Virginia City', label: 'Virginia City' },
    { value: 'Winnemucca', label: 'Winnemucca' },
];

export const citysNewHampshire = [
    { value: 'Berlin', label: 'Berlin' },
    { value: 'Claremont', label: 'Claremont' },
    { value: 'Concord', label: 'Concord' },
    { value: 'Derry', label: 'Derry' },
    { value: 'Dover', label: 'Dover' },
    { value: 'Durham', label: 'Durham' },
    { value: 'Exeter', label: 'Exeter' },
    { value: 'Franklin', label: 'Franklin' },
    { value: 'Hanover', label: 'Hanover' },
    { value: 'Hillsborough', label: 'Hillsborough' },
    { value: 'Keene', label: 'Keene' },
    { value: 'Laconia', label: 'Laconia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Manchester', label: 'Manchester' },
    { value: 'Nashua', label: 'Nashua' },
    { value: 'Peterborough', label: 'Peterborough' },
    { value: 'Plymouth', label: 'Plymouth' },
    { value: 'Portsmouth', label: 'Portsmouth' },
    { value: 'Rochester', label: 'Rochester' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Somersworth', label: 'Somersworth' },
];

export const citysNewJersey = [
    { value: 'Asbury Park', label: 'Asbury Park' },
    { value: 'Atlantic City', label: 'Atlantic City' },
    { value: 'Bayonne', label: 'Bayonne' },
    { value: 'Bloomfield', label: 'Bloomfield' },
    { value: 'Bordentown', label: 'Bordentown' },
    { value: 'Bound Brook', label: 'Bound Brook' },
    { value: 'Bridgeton', label: 'Bridgeton' },
    { value: 'Burlington', label: 'Burlington' },
    { value: 'Caldwell', label: 'Caldwell' },
    { value: 'Camden', label: 'Camden' },
    { value: 'Cape May', label: 'Cape May' },
    { value: 'Clifton', label: 'Clifton' },
    { value: 'Cranford', label: 'Cranford' },
    { value: 'East Orange', label: 'East Orange' },
    { value: 'Edison', label: 'Edison' },
    { value: 'Elizabeth', label: 'Elizabeth' },
    { value: 'Englewood', label: 'Englewood' },
    { value: 'Fort Lee', label: 'Fort Lee' },
    { value: 'Glassboro', label: 'Glassboro' },
    { value: 'Hackensack', label: 'Hackensack' },
    { value: 'Haddonfield', label: 'Haddonfield' },
    { value: 'Hoboken', label: 'Hoboken' },
    { value: 'Irvington', label: 'Irvington' },
    { value: 'Jersey City', label: 'Jersey City' },
    { value: 'Lakehurst', label: 'Lakehurst' },
    { value: 'Lakewood', label: 'Lakewood' },
    { value: 'Long Beach', label: 'Long Beach' },
    { value: 'Long Branch', label: 'Long Branch' },
    { value: 'Madison', label: 'Madison' },
    { value: 'Menlo Park', label: 'Menlo Park' },
    { value: 'Millburn', label: 'Millburn' },
    { value: 'Millville', label: 'Millville' },
    { value: 'Montclair', label: 'Montclair' },
    { value: 'Morristown', label: 'Morristown' },
    { value: 'Mount Holly', label: 'Mount Holly' },
    { value: 'New Brunswick', label: 'New Brunswick' },
    { value: 'New Milford', label: 'New Milford' },
    { value: 'Newark', label: 'Newark' },
    { value: 'Ocean City', label: 'Ocean City' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Parsippany–Troy Hills', label: 'Parsippany–Troy Hills' },
    { value: 'Passaic', label: 'Passaic' },
    { value: 'Paterson', label: 'Paterson' },
    { value: 'Perth Amboy', label: 'Perth Amboy' },
    { value: 'Plainfield', label: 'Plainfield' },
    { value: 'Princeton', label: 'Princeton' },
    { value: 'Ridgewood', label: 'Ridgewood' },
    { value: 'Roselle', label: 'Roselle' },
    { value: 'Rutherford', label: 'Rutherford' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Somerville', label: 'Somerville' },
    { value: 'South Orange Village', label: 'South Orange Village' },
    { value: 'Totowa', label: 'Totowa' },
    { value: 'Trenton', label: 'Trenton' },
    { value: 'Union', label: 'Union' },
    { value: 'Union City', label: 'Union City' },
    { value: 'Vineland', label: 'Vineland' },
    { value: 'Wayne', label: 'Wayne' },
    { value: 'Weehawken', label: 'Weehawken' },
    { value: 'West New York', label: 'West New York' },
    { value: 'West Orange', label: 'West Orange' },
    { value: 'Willingboro', label: 'Willingboro' },
    { value: 'Woodbridge', label: 'Woodbridge' },
];

export const citysNewMexico = [
    { value: 'Acoma', label: 'Acoma' },
    { value: 'Alamogordo', label: 'Alamogordo' },
    { value: 'Albuquerque', label: 'Albuquerque' },
    { value: 'Artesia', label: 'Artesia' },
    { value: 'Belen', label: 'Belen' },
    { value: 'Carlsbad', label: 'Carlsbad' },
    { value: 'Clovis', label: 'Clovis' },
    { value: 'Deming', label: 'Deming' },
    { value: 'Farmington', label: 'Farmington' },
    { value: 'Gallup', label: 'Gallup' },
    { value: 'Grants', label: 'Grants' },
    { value: 'Hobbs', label: 'Hobbs' },
    { value: 'Las Cruces', label: 'Las Cruces' },
    { value: 'Las Vegas', label: 'Las Vegas' },
    { value: 'Los Alamos', label: 'Los Alamos' },
    { value: 'Lovington', label: 'Lovington' },
    { value: 'Portales', label: 'Portales' },
    { value: 'Raton', label: 'Raton' },
    { value: 'Roswell', label: 'Roswell' },
    { value: 'Santa Fe', label: 'Santa Fe' },
    { value: 'Shiprock', label: 'Shiprock' },
    { value: 'Silver City', label: 'Silver City' },
    { value: 'Socorro', label: 'Socorro' },
    { value: 'Taos', label: 'Taos' },
    { value: 'Truth or Consequences', label: 'Truth or Consequences' },
    { value: 'Tucumcari', label: 'Tucumcari' },
];
export const citysNewYork = [
    { value: 'Albany', label: 'Albany' },
    { value: 'Amsterdam', label: 'Amsterdam' },
    { value: 'Auburn', label: 'Auburn' },
    { value: 'Babylon', label: 'Babylon' },
    { value: 'Batavia', label: 'Batavia' },
    { value: 'Beacon', label: 'Beacon' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Binghamton', label: 'Binghamton' },
    { value: 'Bronx', label: 'Bronx' },
    { value: 'Brooklyn', label: 'Brooklyn' },
    { value: 'Buffalo', label: 'Buffalo' },
    { value: 'Chautauqua', label: 'Chautauqua' },
    { value: 'Cheektowaga', label: 'Cheektowaga' },
    { value: 'Clinton', label: 'Clinton' },
    { value: 'Cohoes', label: 'Cohoes' },
    { value: 'Coney Island', label: 'Coney Island' },
    { value: 'Cooperstown', label: 'Cooperstown' },
    { value: 'Corning', label: 'Corning' },
    { value: 'Cortland', label: 'Cortland' },
    { value: 'Crown Point', label: 'Crown Point' },
    { value: 'Dunkirk', label: 'Dunkirk' },
    { value: 'East Aurora', label: 'East Aurora' },
    { value: 'East Hampton', label: 'East Hampton' },
    { value: 'Eastchester', label: 'Eastchester' },
    { value: 'Elmira', label: 'Elmira' },
    { value: 'Flushing', label: 'Flushing' },
    { value: 'Forest Hills', label: 'Forest Hills' },
    { value: 'Fredonia', label: 'Fredonia' },
    { value: 'Garden City', label: 'Garden City' },
    { value: 'Geneva', label: 'Geneva' },
    { value: 'Glens Falls', label: 'Glens Falls' },
    { value: 'Gloversville', label: 'Gloversville' },
    { value: 'Great Neck', label: 'Great Neck' },
    { value: 'Hammondsport', label: 'Hammondsport' },
    { value: 'Harlem', label: 'Harlem' },
    { value: 'Hempstead', label: 'Hempstead' },
    { value: 'Herkimer', label: 'Herkimer' },
    { value: 'Hudson', label: 'Hudson' },
    { value: 'Huntington', label: 'Huntington' },
    { value: 'Hyde Park', label: 'Hyde Park' },
    { value: 'Ilion', label: 'Ilion' },
    { value: 'Ithaca', label: 'Ithaca' },
    { value: 'Jamestown', label: 'Jamestown' },
    { value: 'Johnstown', label: 'Johnstown' },
    { value: 'Kingston', label: 'Kingston' },
    { value: 'Lackawanna', label: 'Lackawanna' },
    { value: 'Lake Placid', label: 'Lake Placid' },
    { value: 'Levittown', label: 'Levittown' },
    { value: 'Lockport', label: 'Lockport' },
    { value: 'Mamaroneck', label: 'Mamaroneck' },
    { value: 'Manhattan', label: 'Manhattan' },
    { value: 'Massena', label: 'Massena' },
    { value: 'Middletown', label: 'Middletown' },
    { value: 'Mineola', label: 'Mineola' },
    { value: 'Mount Vernon', label: 'Mount Vernon' },
    { value: 'New Paltz', label: 'New Paltz' },
    { value: 'New Rochelle', label: 'New Rochelle' },
    { value: 'New Windsor', label: 'New Windsor' },
    { value: 'New York City', label: 'New York City' },
    { value: 'Newburgh', label: 'Newburgh' },
    { value: 'Niagara Falls', label: 'Niagara Falls' },
    { value: 'North Hempstead', label: 'North Hempstead' },
    { value: 'Nyack', label: 'Nyack' },
    { value: 'Ogdensburg', label: 'Ogdensburg' },
    { value: 'Olean', label: 'Olean' },
    { value: 'Oneida', label: 'Oneida' },
    { value: 'Oneonta', label: 'Oneonta' },
    { value: 'Ossining', label: 'Ossining' },
    { value: 'Oswego', label: 'Oswego' },
    { value: 'Oyster Bay', label: 'Oyster Bay' },
    { value: 'Palmyra', label: 'Palmyra' },
    { value: 'Peekskill', label: 'Peekskill' },
    { value: 'Plattsburgh', label: 'Plattsburgh' },
    { value: 'Port Washington', label: 'Port Washington' },
    { value: 'Potsdam', label: 'Potsdam' },
    { value: 'Poughkeepsie', label: 'Poughkeepsie' },
    { value: 'Queens', label: 'Queens' },
    { value: 'Rensselaer', label: 'Rensselaer' },
    { value: 'Rochester', label: 'Rochester' },
    { value: 'Rome', label: 'Rome' },
    { value: 'Rotterdam', label: 'Rotterdam' },
    { value: 'Rye', label: 'Rye' },
    { value: 'Sag Harbor', label: 'Sag Harbor' },
    { value: 'Saranac Lake', label: 'Saranac Lake' },
    { value: 'Saratoga Springs', label: 'Saratoga Springs' },
    { value: 'Scarsdale', label: 'Scarsdale' },
    { value: 'Schenectady', label: 'Schenectady' },
    { value: 'Seneca Falls', label: 'Seneca Falls' },
    { value: 'Southampton', label: 'Southampton' },
    { value: 'Staten Island', label: 'Staten Island' },
    { value: 'Stony Brook', label: 'Stony Brook' },
    { value: 'Stony Point', label: 'Stony Point' },
    { value: 'Syracuse', label: 'Syracuse' },
    { value: 'Tarrytown', label: 'Tarrytown' },
    { value: 'Ticonderoga', label: 'Ticonderoga' },
    { value: 'Tonawanda', label: 'Tonawanda' },
    { value: 'Troy', label: 'Troy' },
    { value: 'Utica', label: 'Utica' },
    { value: 'Watertown', label: 'Watertown' },
    { value: 'Watervliet', label: 'Watervliet' },
    { value: 'Watkins Glen', label: 'Watkins Glen' },
    { value: 'West Seneca', label: 'West Seneca' },
    { value: 'White Plains', label: 'White Plains' },
    { value: 'Woodstock', label: 'Woodstock' },
    { value: 'Yonkers', label: 'Yonkers' },
];

export const citysNorthCarolina = [
    { value: 'Asheboro', label: 'Asheboro' },
    { value: 'Asheville', label: 'Asheville' },
    { value: 'Bath', label: 'Bath' },
    { value: 'Beaufort', label: 'Beaufort' },
    { value: 'Boone', label: 'Boone' },
    { value: 'Burlington', label: 'Burlington' },
    { value: 'Chapel Hill', label: 'Chapel Hill' },
    { value: 'Charlotte', label: 'Charlotte' },
    { value: 'Concord', label: 'Concord' },
    { value: 'Durham', label: 'Durham' },
    { value: 'Edenton', label: 'Edenton' },
    { value: 'Elizabeth City', label: 'Elizabeth City' },
    { value: 'Fayetteville', label: 'Fayetteville' },
    { value: 'Gastonia', label: 'Gastonia' },
    { value: 'Goldsboro', label: 'Goldsboro' },
    { value: 'Greensboro', label: 'Greensboro' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Halifax', label: 'Halifax' },
    { value: 'Henderson', label: 'Henderson' },
    { value: 'Hickory', label: 'Hickory' },
    { value: 'High Point', label: 'High Point' },
    { value: 'Hillsborough', label: 'Hillsborough' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'Kinston', label: 'Kinston' },
    { value: 'Kitty Hawk', label: 'Kitty Hawk' },
    { value: 'Lumberton', label: 'Lumberton' },
    { value: 'Morehead City', label: 'Morehead City' },
    { value: 'Morganton', label: 'Morganton' },
    { value: 'Nags Head', label: 'Nags Head' },
    { value: 'New Bern', label: 'New Bern' },
    { value: 'Pinehurst', label: 'Pinehurst' },
    { value: 'Raleigh', label: 'Raleigh' },
    { value: 'Rocky Mount', label: 'Rocky Mount' },
    { value: 'Salisbury', label: 'Salisbury' },
    { value: 'Shelby', label: 'Shelby' },
    { value: 'Washington', label: 'Washington' },
    { value: 'Wilmington', label: 'Wilmington' },
    { value: 'Wilson', label: 'Wilson' },
    { value: 'Winston-Salem', label: 'Winston-Salem' },
];

export const citysNorthDakota = [
    { value: 'Bismarck', label: 'Bismarck' },
    { value: 'Devils Lake', label: 'Devils Lake' },
    { value: 'Dickinson', label: 'Dickinson' },
    { value: 'Fargo', label: 'Fargo' },
    { value: 'Grand Forks', label: 'Grand Forks' },
    { value: 'Jamestown', label: 'Jamestown' },
    { value: 'Mandan', label: 'Mandan' },
    { value: 'Minot', label: 'Minot' },
    { value: 'Rugby', label: 'Rugby' },
    { value: 'Valley City', label: 'Valley City' },
    { value: 'Wahpeton', label: 'Wahpeton' },
    { value: 'Williston', label: 'Williston' },
];

export const citysOhio = [
    { value: 'Akron', label: 'Akron' },
    { value: 'Alliance', label: 'Alliance' },
    { value: 'Ashtabula', label: 'Ashtabula' },
    { value: 'Athens', label: 'Athens' },
    { value: 'Barberton', label: 'Barberton' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Bellefontaine', label: 'Bellefontaine' },
    { value: 'Bowling Green', label: 'Bowling Green' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Chillicothe', label: 'Chillicothe' },
    { value: 'Cincinnati', label: 'Cincinnati' },
    { value: 'Cleveland', label: 'Cleveland' },
    { value: 'Cleveland Heights', label: 'Cleveland Heights' },
    { value: 'Columbus', label: 'Columbus' },
    { value: 'Conneaut', label: 'Conneaut' },
    { value: 'Cuyahoga Falls', label: 'Cuyahoga Falls' },
    { value: 'Dayton', label: 'Dayton' },
    { value: 'Defiance', label: 'Defiance' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'East Cleveland', label: 'East Cleveland' },
    { value: 'East Liverpool', label: 'East Liverpool' },
    { value: 'Elyria', label: 'Elyria' },
    { value: 'Euclid', label: 'Euclid' },
    { value: 'Findlay', label: 'Findlay' },
    { value: 'Gallipolis', label: 'Gallipolis' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Hamilton', label: 'Hamilton' },
    { value: 'Kent', label: 'Kent' },
    { value: 'Kettering', label: 'Kettering' },
    { value: 'Lakewood', label: 'Lakewood' },
    { value: 'Lancaster', label: 'Lancaster' },
    { value: 'Lima', label: 'Lima' },
    { value: 'Lorain', label: 'Lorain' },
    { value: 'Mansfield', label: 'Mansfield' },
    { value: 'Marietta', label: 'Marietta' },
    { value: 'Marion', label: 'Marion' },
    { value: 'Martins Ferry', label: 'Martins Ferry' },
    { value: 'Massillon', label: 'Massillon' },
    { value: 'Mentor', label: 'Mentor' },
    { value: 'Middletown', label: 'Middletown' },
    { value: 'Milan', label: 'Milan' },
    { value: 'Mount Vernon', label: 'Mount Vernon' },
    { value: 'New Philadelphia', label: 'New Philadelphia' },
    { value: 'Newark', label: 'Newark' },
    { value: 'Niles', label: 'Niles' },
    { value: 'North College Hill', label: 'North College Hill' },
    { value: 'Norwalk', label: 'Norwalk' },
    { value: 'Oberlin', label: 'Oberlin' },
    { value: 'Painesville', label: 'Painesville' },
    { value: 'Parma', label: 'Parma' },
    { value: 'Piqua', label: 'Piqua' },
    { value: 'Portsmouth', label: 'Portsmouth' },
    { value: 'Put-in-Bay', label: 'Put-in-Bay' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Sandusky', label: 'Sandusky' },
    { value: 'Shaker Heights', label: 'Shaker Heights' },
    { value: 'Springfield', label: 'Springfield' },
    { value: 'Steubenville', label: 'Steubenville' },
    { value: 'Tiffin', label: 'Tiffin' },
    { value: 'Toledo', label: 'Toledo' },
    { value: 'Urbana', label: 'Urbana' },
    { value: 'Warren', label: 'Warren' },
    { value: 'Wooster', label: 'Wooster' },
    { value: 'Worthington', label: 'Worthington' },
    { value: 'Xenia', label: 'Xenia' },
    { value: 'Yellow Springs', label: 'Yellow Springs' },
    { value: 'Youngstown', label: 'Youngstown' },
    { value: 'Zanesville', label: 'Zanesville' },
];

export const citysOklahoma = [
    { value: 'Ada', label: 'Ada' },
    { value: 'Altus', label: 'Altus' },
    { value: 'Alva', label: 'Alva' },
    { value: 'Anadarko', label: 'Anadarko' },
    { value: 'Ardmore', label: 'Ardmore' },
    { value: 'Bartlesville', label: 'Bartlesville' },
    { value: 'Bethany', label: 'Bethany' },
    { value: 'Chickasha', label: 'Chickasha' },
    { value: 'Claremore', label: 'Claremore' },
    { value: 'Clinton', label: 'Clinton' },
    { value: 'Cushing', label: 'Cushing' },
    { value: 'Duncan', label: 'Duncan' },
    { value: 'Durant', label: 'Durant' },
    { value: 'Edmond', label: 'Edmond' },
    { value: 'El Reno', label: 'El Reno' },
    { value: 'Elk City', label: 'Elk City' },
    { value: 'Enid', label: 'Enid' },
    { value: 'Eufaula', label: 'Eufaula' },
    { value: 'Frederick', label: 'Frederick' },
    { value: 'Guthrie', label: 'Guthrie' },
    { value: 'Guymon', label: 'Guymon' },
    { value: 'Hobart', label: 'Hobart' },
    { value: 'Holdenville', label: 'Holdenville' },
    { value: 'Hugo', label: 'Hugo' },
    { value: 'Lawton', label: 'Lawton' },
    { value: 'McAlester', label: 'McAlester' },
    { value: 'Miami', label: 'Miami' },
    { value: 'Midwest City', label: 'Midwest City' },
    { value: 'Moore', label: 'Moore' },
    { value: 'Muskogee', label: 'Muskogee' },
    { value: 'Norman', label: 'Norman' },
    { value: 'Oklahoma City', label: 'Oklahoma City' },
    { value: 'Okmulgee', label: 'Okmulgee' },
    { value: 'Pauls Valley', label: 'Pauls Valley' },
    { value: 'Pawhuska', label: 'Pawhuska' },
    { value: 'Perry', label: 'Perry' },
    { value: 'Ponca City', label: 'Ponca City' },
    { value: 'Pryor', label: 'Pryor' },
    { value: 'Sallisaw', label: 'Sallisaw' },
    { value: 'Sand Springs', label: 'Sand Springs' },
    { value: 'Sapulpa', label: 'Sapulpa' },
    { value: 'Seminole', label: 'Seminole' },
    { value: 'Shawnee', label: 'Shawnee' },
    { value: 'Stillwater', label: 'Stillwater' },
    { value: 'Tahlequah', label: 'Tahlequah' },
    { value: 'The Village', label: 'The Village' },
    { value: 'Tulsa', label: 'Tulsa' },
    { value: 'Vinita', label: 'Vinita' },
    { value: 'Wewoka', label: 'Wewoka' },
    { value: 'Woodward', label: 'Woodward' },
];

export const citysOregon = [
    { value: 'Albany', label: 'Albany' },
    { value: 'Ashland', label: 'Ashland' },
    { value: 'Astoria', label: 'Astoria' },
    { value: 'Baker City', label: 'Baker City' },
    { value: 'Beaverton', label: 'Beaverton' },
    { value: 'Bend', label: 'Bend' },
    { value: 'Brookings', label: 'Brookings' },
    { value: 'Burns', label: 'Burns' },
    { value: 'Coos Bay', label: 'Coos Bay' },
    { value: 'Corvallis', label: 'Corvallis' },
    { value: 'Eugene', label: 'Eugene' },
    { value: 'Grants Pass', label: 'Grants Pass' },
    { value: 'Hillsboro', label: 'Hillsboro' },
    { value: 'Hood River', label: 'Hood River' },
    { value: 'Jacksonville', label: 'Jacksonville' },
    { value: 'John Day', label: 'John Day' },
    { value: 'Klamath Falls', label: 'Klamath Falls' },
    { value: 'La Grande', label: 'La Grande' },
    { value: 'Lake Oswego', label: 'Lake Oswego' },
    { value: 'Lakeview', label: 'Lakeview' },
    { value: 'McMinnville', label: 'McMinnville' },
    { value: 'Medford', label: 'Medford' },
    { value: 'Newberg', label: 'Newberg' },
    { value: 'Newport', label: 'Newport' },
    { value: 'Ontario', label: 'Ontario' },
    { value: 'Oregon City', label: 'Oregon City' },
    { value: 'Pendleton', label: 'Pendleton' },
    { value: 'Port Orford', label: 'Port Orford' },
    { value: 'Portland', label: 'Portland' },
    { value: 'Prineville', label: 'Prineville' },
    { value: 'Redmond', label: 'Redmond' },
    { value: 'Reedsport', label: 'Reedsport' },
    { value: 'Roseburg', label: 'Roseburg' },
    { value: 'Salem', label: 'Salem' },
    { value: 'Seaside', label: 'Seaside' },
    { value: 'Springfield', label: 'Springfield' },
    { value: 'The Dalles', label: 'The Dalles' },
    { value: 'Tillamook', label: 'Tillamook' },
];

export const citysPennsylvania = [
    { value: 'Abington', label: 'Abington' },
    { value: 'Aliquippa', label: 'Aliquippa' },
    { value: 'Allentown', label: 'Allentown' },
    { value: 'Altoona', label: 'Altoona' },
    { value: 'Ambridge', label: 'Ambridge' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Bethlehem', label: 'Bethlehem' },
    { value: 'Bloomsburg', label: 'Bloomsburg' },
    { value: 'Bradford', label: 'Bradford' },
    { value: 'Bristol', label: 'Bristol' },
    { value: 'Carbondale', label: 'Carbondale' },
    { value: 'Carlisle', label: 'Carlisle' },
    { value: 'Chambersburg', label: 'Chambersburg' },
    { value: 'Chester', label: 'Chester' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Easton', label: 'Easton' },
    { value: 'Erie', label: 'Erie' },
    { value: 'Franklin', label: 'Franklin' },
    { value: 'Germantown', label: 'Germantown' },
    { value: 'Gettysburg', label: 'Gettysburg' },
    { value: 'Greensburg', label: 'Greensburg' },
    { value: 'Hanover', label: 'Hanover' },
    { value: 'Harmony', label: 'Harmony' },
    { value: 'Harrisburg', label: 'Harrisburg' },
    { value: 'Hazleton', label: 'Hazleton' },
    { value: 'Hershey', label: 'Hershey' },
    { value: 'Homestead', label: 'Homestead' },
    { value: 'Honesdale', label: 'Honesdale' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Jeannette', label: 'Jeannette' },
    { value: 'Jim Thorpe', label: 'Jim Thorpe' },
    { value: 'Johnstown', label: 'Johnstown' },
    { value: 'Lancaster', label: 'Lancaster' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Levittown', label: 'Levittown' },
    { value: 'Lewistown', label: 'Lewistown' },
    { value: 'Lock Haven', label: 'Lock Haven' },
    { value: 'Lower Southampton', label: 'Lower Southampton' },
    { value: 'McKeesport', label: 'McKeesport' },
    { value: 'Meadville', label: 'Meadville' },
    { value: 'Middletown', label: 'Middletown' },
    { value: 'Monroeville', label: 'Monroeville' },
    { value: 'Nanticoke', label: 'Nanticoke' },
    { value: 'New Castle', label: 'New Castle' },
    { value: 'New Hope', label: 'New Hope' },
    { value: 'New Kensington', label: 'New Kensington' },
    { value: 'Norristown', label: 'Norristown' },
    { value: 'Oil City', label: 'Oil City' },
    { value: 'Philadelphia', label: 'Philadelphia' },
    { value: 'Phoenixville', label: 'Phoenixville' },
    { value: 'Pittsburgh', label: 'Pittsburgh' },
    { value: 'Pottstown', label: 'Pottstown' },
    { value: 'Pottsville', label: 'Pottsville' },
    { value: 'Reading', label: 'Reading' },
    { value: 'Scranton', label: 'Scranton' },
    { value: 'Shamokin', label: 'Shamokin' },
    { value: 'Sharon', label: 'Sharon' },
    { value: 'State College', label: 'State College' },
    { value: 'Stroudsburg', label: 'Stroudsburg' },
    { value: 'Sunbury', label: 'Sunbury' },
    { value: 'Swarthmore', label: 'Swarthmore' },
    { value: 'Tamaqua', label: 'Tamaqua' },
    { value: 'Titusville', label: 'Titusville' },
    { value: 'Uniontown', label: 'Uniontown' },
    { value: 'Warren', label: 'Warren' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Chester', label: 'West Chester' },
    { value: 'Wilkes-Barre', label: 'Wilkes-Barre' },
    { value: 'Williamsport', label: 'Williamsport' },
    { value: 'York', label: 'York' },
];

export const citysRhodeIsland = [
    { value: 'Barrington', label: 'Barrington' },
    { value: 'Bristol', label: 'Bristol' },
    { value: 'Central Falls', label: 'Central Falls' },
    { value: 'Cranston', label: 'Cranston' },
    { value: 'East Greenwich', label: 'East Greenwich' },
    { value: 'East Providence', label: 'East Providence' },
    { value: 'Kingston', label: 'Kingston' },
    { value: 'Middletown', label: 'Middletown' },
    { value: 'Narragansett', label: 'Narragansett' },
    { value: 'Newport', label: 'Newport' },
    { value: 'North Kingstown', label: 'North Kingstown' },
    { value: 'Pawtucket', label: 'Pawtucket' },
    { value: 'Portsmouth', label: 'Portsmouth' },
    { value: 'Providence', label: 'Providence' },
    { value: 'South Kingstown', label: 'South Kingstown' },
    { value: 'Tiverton', label: 'Tiverton' },
    { value: 'Warren', label: 'Warren' },
    { value: 'Warwick', label: 'Warwick' },
    { value: 'Westerly', label: 'Westerly' },
    { value: 'Wickford', label: 'Wickford' },
    { value: 'Woonsocket', label: 'Woonsocket' },
];

export const citysSouthCarolina = [
    { value: 'Abbeville', label: 'Abbeville' },
    { value: 'Aiken', label: 'Aiken' },
    { value: 'Anderson', label: 'Anderson' },
    { value: 'Beaufort', label: 'Beaufort' },
    { value: 'Camden', label: 'Camden' },
    { value: 'Charleston', label: 'Charleston' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Darlington', label: 'Darlington' },
    { value: 'Florence', label: 'Florence' },
    { value: 'Gaffney', label: 'Gaffney' },
    { value: 'Georgetown', label: 'Georgetown' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Greenwood', label: 'Greenwood' },
    { value: 'Hartsville', label: 'Hartsville' },
    { value: 'Lancaster', label: 'Lancaster' },
    { value: 'Mount Pleasant', label: 'Mount Pleasant' },
    { value: 'Myrtle Beach', label: 'Myrtle Beach' },
    { value: 'Orangeburg', label: 'Orangeburg' },
    { value: 'Rock Hill', label: 'Rock Hill' },
    { value: 'Spartanburg', label: 'Spartanburg' },
    { value: 'Sumter', label: 'Sumter' },
    { value: 'Union', label: 'Union' },
];

export const citysSouthDakota = [
    { value: 'Aberdeen', label: 'Aberdeen' },
    { value: 'Belle Fourche', label: 'Belle Fourche' },
    { value: 'Brookings', label: 'Brookings' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Custer', label: 'Custer' },
    { value: 'De Smet', label: 'De Smet' },
    { value: 'Deadwood', label: 'Deadwood' },
    { value: 'Hot Springs', label: 'Hot Springs' },
    { value: 'Huron', label: 'Huron' },
    { value: 'Lead', label: 'Lead' },
    { value: 'Madison', label: 'Madison' },
    { value: 'Milbank', label: 'Milbank' },
    { value: 'Mitchell', label: 'Mitchell' },
    { value: 'Mobridge', label: 'Mobridge' },
    { value: 'Pierre', label: 'Pierre' },
    { value: 'Rapid City', label: 'Rapid City' },
    { value: 'Sioux Falls', label: 'Sioux Falls' },
    { value: 'Spearfish', label: 'Spearfish' },
    { value: 'Sturgis', label: 'Sturgis' },
    { value: 'Vermillion', label: 'Vermillion' },
    { value: 'Watertown', label: 'Watertown' },
    { value: 'Yankton', label: 'Yankton' },
];

export const citysTennessee = [
    { value: 'Alcoa', label: 'Alcoa' },
    { value: 'Athens', label: 'Athens' },
    { value: 'Chattanooga', label: 'Chattanooga' },
    { value: 'Clarksville', label: 'Clarksville' },
    { value: 'Cleveland', label: 'Cleveland' },
    { value: 'Columbia', label: 'Columbia' },
    { value: 'Cookeville', label: 'Cookeville' },
    { value: 'Dayton', label: 'Dayton' },
    { value: 'Elizabethton', label: 'Elizabethton' },
    { value: 'Franklin', label: 'Franklin' },
    { value: 'Gallatin', label: 'Gallatin' },
    { value: 'Gatlinburg', label: 'Gatlinburg' },
    { value: 'Greeneville', label: 'Greeneville' },
    { value: 'Jackson', label: 'Jackson' },
    { value: 'Johnson City', label: 'Johnson City' },
    { value: 'Jonesborough', label: 'Jonesborough' },
    { value: 'Kingsport', label: 'Kingsport' },
    { value: 'Knoxville', label: 'Knoxville' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Maryville', label: 'Maryville' },
    { value: 'Memphis', label: 'Memphis' },
    { value: 'Morristown', label: 'Morristown' },
    { value: 'Murfreesboro', label: 'Murfreesboro' },
    { value: 'Nashville', label: 'Nashville' },
    { value: 'Norris', label: 'Norris' },
    { value: 'Oak Ridge', label: 'Oak Ridge' },
    { value: 'Shelbyville', label: 'Shelbyville' },
    { value: 'Tullahoma', label: 'Tullahoma' },
];

export const citysTexas = [
    { value: 'Abilene', label: 'Abilene' },
    { value: 'Alpine', label: 'Alpine' },
    { value: 'Amarillo', label: 'Amarillo' },
    { value: 'Arlington', label: 'Arlington' },
    { value: 'Austin', label: 'Austin' },
    { value: 'Baytown', label: 'Baytown' },
    { value: 'Beaumont', label: 'Beaumont' },
    { value: 'Big Spring', label: 'Big Spring' },
    { value: 'Borger', label: 'Borger' },
    { value: 'Brownsville', label: 'Brownsville' },
    { value: 'Bryan', label: 'Bryan' },
    { value: 'Canyon', label: 'Canyon' },
    { value: 'Cleburne', label: 'Cleburne' },
    { value: 'College Station', label: 'College Station' },
    { value: 'Corpus Christi', label: 'Corpus Christi' },
    { value: 'Crystal City', label: 'Crystal City' },
    { value: 'Dallas', label: 'Dallas' },
    { value: 'Del Rio', label: 'Del Rio' },
    { value: 'Denison', label: 'Denison' },
    { value: 'Denton', label: 'Denton' },
    { value: 'Eagle Pass', label: 'Eagle Pass' },
    { value: 'Edinburg', label: 'Edinburg' },
    { value: 'El Paso', label: 'El Paso' },
    { value: 'Fort Worth', label: 'Fort Worth' },
    { value: 'Freeport', label: 'Freeport' },
    { value: 'Galveston', label: 'Galveston' },
    { value: 'Garland', label: 'Garland' },
    { value: 'Goliad', label: 'Goliad' },
    { value: 'Greenville', label: 'Greenville' },
    { value: 'Harlingen', label: 'Harlingen' },
    { value: 'Houston', label: 'Houston' },
    { value: 'Huntsville', label: 'Huntsville' },
    { value: 'Irving', label: 'Irving' },
    { value: 'Johnson City', label: 'Johnson City' },
    { value: 'Kilgore', label: 'Kilgore' },
    { value: 'Killeen', label: 'Killeen' },
    { value: 'Kingsville', label: 'Kingsville' },
    { value: 'Laredo', label: 'Laredo' },
    { value: 'Longview', label: 'Longview' },
    { value: 'Lubbock', label: 'Lubbock' },
    { value: 'Lufkin', label: 'Lufkin' },
    { value: 'Marshall', label: 'Marshall' },
    { value: 'McAllen', label: 'McAllen' },
    { value: 'McKinney', label: 'McKinney' },
    { value: 'Mesquite', label: 'Mesquite' },
    { value: 'Midland', label: 'Midland' },
    { value: 'Mission', label: 'Mission' },
    { value: 'Nacogdoches', label: 'Nacogdoches' },
    { value: 'New Braunfels', label: 'New Braunfels' },
    { value: 'Odessa', label: 'Odessa' },
    { value: 'Orange', label: 'Orange' },
    { value: 'Pampa', label: 'Pampa' },
    { value: 'Paris', label: 'Paris' },
    { value: 'Pasadena', label: 'Pasadena' },
    { value: 'Pecos', label: 'Pecos' },
    { value: 'Pharr', label: 'Pharr' },
    { value: 'Plainview', label: 'Plainview' },
    { value: 'Plano', label: 'Plano' },
    { value: 'Port Arthur', label: 'Port Arthur' },
    { value: 'Port Lavaca', label: 'Port Lavaca' },
    { value: 'Richardson', label: 'Richardson' },
    { value: 'San Angelo', label: 'San Angelo' },
    { value: 'San Antonio', label: 'San Antonio' },
    { value: 'San Felipe', label: 'San Felipe' },
    { value: 'San Marcos', label: 'San Marcos' },
    { value: 'Sherman', label: 'Sherman' },
    { value: 'Sweetwater', label: 'Sweetwater' },
    { value: 'Temple', label: 'Temple' },
    { value: 'Texarkana', label: 'Texarkana' },
    { value: 'Texas City', label: 'Texas City' },
    { value: 'Tyler', label: 'Tyler' },
    { value: 'Uvalde', label: 'Uvalde' },
    { value: 'Victoria', label: 'Victoria' },
    { value: 'Waco', label: 'Waco' },
    { value: 'Weatherford', label: 'Weatherford' },
    { value: 'Wichita Falls', label: 'Wichita Falls' },
    { value: 'Ysleta', label: 'Ysleta' },
];

export const citysUtah = [
    { value: 'Alta', label: 'Alta' },
    { value: 'American Fork', label: 'American Fork' },
    { value: 'Bountiful', label: 'Bountiful' },
    { value: 'Brigham City', label: 'Brigham City' },
    { value: 'Cedar City', label: 'Cedar City' },
    { value: 'Clearfield', label: 'Clearfield' },
    { value: 'Delta', label: 'Delta' },
    { value: 'Fillmore', label: 'Fillmore' },
    { value: 'Green River', label: 'Green River' },
    { value: 'Heber City', label: 'Heber City' },
    { value: 'Kanab', label: 'Kanab' },
    { value: 'Layton', label: 'Layton' },
    { value: 'Lehi', label: 'Lehi' },
    { value: 'Logan', label: 'Logan' },
    { value: 'Manti', label: 'Manti' },
    { value: 'Moab', label: 'Moab' },
    { value: 'Monticello', label: 'Monticello' },
    { value: 'Murray', label: 'Murray' },
    { value: 'Nephi', label: 'Nephi' },
    { value: 'Ogden', label: 'Ogden' },
    { value: 'Orderville', label: 'Orderville' },
    { value: 'Orem', label: 'Orem' },
    { value: 'Panguitch', label: 'Panguitch' },
    { value: 'Park City', label: 'Park City' },
    { value: 'Payson', label: 'Payson' },
    { value: 'Price', label: 'Price' },
    { value: 'Provo', label: 'Provo' },
    { value: 'Saint George', label: 'Saint George' },
    { value: 'Salt Lake City', label: 'Salt Lake City' },
    { value: 'Spanish Fork', label: 'Spanish Fork' },
    { value: 'Springville', label: 'Springville' },
    { value: 'Tooele', label: 'Tooele' },
    { value: 'Vernal', label: 'Vernal' },
];

export const citysVermont = [
    { value: 'Barre', label: 'Barre' },
    { value: 'Bellows Falls', label: 'Bellows Falls' },
    { value: 'Bennington', label: 'Bennington' },
    { value: 'Brattleboro', label: 'Brattleboro' },
    { value: 'Burlington', label: 'Burlington' },
    { value: 'Essex', label: 'Essex' },
    { value: 'Manchester', label: 'Manchester' },
    { value: 'Middlebury', label: 'Middlebury' },
    { value: 'Montpelier', label: 'Montpelier' },
    { value: 'Newport', label: 'Newport' },
    { value: 'Plymouth', label: 'Plymouth' },
    { value: 'Rutland', label: 'Rutland' },
    { value: 'Saint Albans', label: 'Saint Albans' },
    { value: 'Saint Johnsbury', label: 'Saint Johnsbury' },
    { value: 'Sharon', label: 'Sharon' },
    { value: 'Winooski', label: 'Winooski' },
];

export const citysVirginia = [
    { value: 'Abingdon', label: 'Abingdon' },
    { value: 'Alexandria', label: 'Alexandria' },
    { value: 'Bristol', label: 'Bristol' },
    { value: 'Charlottesville', label: 'Charlottesville' },
    { value: 'Chesapeake', label: 'Chesapeake' },
    { value: 'Danville', label: 'Danville' },
    { value: 'Fairfax', label: 'Fairfax' },
    { value: 'Falls Church', label: 'Falls Church' },
    { value: 'Fredericksburg', label: 'Fredericksburg' },
    { value: 'Hampton', label: 'Hampton' },
    { value: 'Hanover', label: 'Hanover' },
    { value: 'Hopewell', label: 'Hopewell' },
    { value: 'Lexington', label: 'Lexington' },
    { value: 'Lynchburg', label: 'Lynchburg' },
    { value: 'Manassas', label: 'Manassas' },
    { value: 'Martinsville', label: 'Martinsville' },
    { value: 'New Market', label: 'New Market' },
    { value: 'Newport News', label: 'Newport News' },
    { value: 'Norfolk', label: 'Norfolk' },
    { value: 'Petersburg', label: 'Petersburg' },
    { value: 'Portsmouth', label: 'Portsmouth' },
    { value: 'Reston', label: 'Reston' },
    { value: 'Richmond', label: 'Richmond' },
    { value: 'Roanoke', label: 'Roanoke' },
    { value: 'Staunton', label: 'Staunton' },
    { value: 'Suffolk', label: 'Suffolk' },
    { value: 'Virginia Beach', label: 'Virginia Beach' },
    { value: 'Waynesboro', label: 'Waynesboro' },
    { value: 'Williamsburg', label: 'Williamsburg' },
    { value: 'Winchester', label: 'Winchester' },
];

export const citysWashington = [
    { value: 'Aberdeen', label: 'Aberdeen' },
    { value: 'Anacortes', label: 'Anacortes' },
    { value: 'Auburn', label: 'Auburn' },
    { value: 'Bellevue', label: 'Bellevue' },
    { value: 'Bellingham', label: 'Bellingham' },
    { value: 'Bremerton', label: 'Bremerton' },
    { value: 'Centralia', label: 'Centralia' },
    { value: 'Coulee Dam', label: 'Coulee Dam' },
    { value: 'Coupeville', label: 'Coupeville' },
    { value: 'Ellensburg', label: 'Ellensburg' },
    { value: 'Ephrata', label: 'Ephrata' },
    { value: 'Everett', label: 'Everett' },
    { value: 'Hoquiam', label: 'Hoquiam' },
    { value: 'Kelso', label: 'Kelso' },
    { value: 'Kennewick', label: 'Kennewick' },
    { value: 'Longview', label: 'Longview' },
    { value: 'Moses Lake', label: 'Moses Lake' },
    { value: 'Oak Harbor', label: 'Oak Harbor' },
    { value: 'Olympia', label: 'Olympia' },
    { value: 'Pasco', label: 'Pasco' },
    { value: 'Point Roberts', label: 'Point Roberts' },
    { value: 'Port Angeles', label: 'Port Angeles' },
    { value: 'Pullman', label: 'Pullman' },
    { value: 'Puyallup', label: 'Puyallup' },
    { value: 'Redmond', label: 'Redmond' },
    { value: 'Renton', label: 'Renton' },
    { value: 'Richland', label: 'Richland' },
    { value: 'Seattle', label: 'Seattle' },
    { value: 'Spokane', label: 'Spokane' },
    { value: 'Tacoma', label: 'Tacoma' },
    { value: 'Vancouver', label: 'Vancouver' },
    { value: 'Walla Walla', label: 'Walla Walla' },
    { value: 'Wenatchee', label: 'Wenatchee' },
    { value: 'Yakima', label: 'Yakima' },
];

export const citysWestVirginia = [
    { value: 'Bath', label: 'Bath' },
    { value: 'Beckley', label: 'Beckley' },
    { value: 'Bluefield', label: 'Bluefield' },
    { value: 'Buckhannon', label: 'Buckhannon' },
    { value: 'Charles Town', label: 'Charles Town' },
    { value: 'Charleston', label: 'Charleston' },
    { value: 'Clarksburg', label: 'Clarksburg' },
    { value: 'Elkins', label: 'Elkins' },
    { value: 'Fairmont', label: 'Fairmont' },
    { value: 'Grafton', label: 'Grafton' },
    { value: 'Harpers Ferry', label: 'Harpers Ferry' },
    { value: 'Hillsboro', label: 'Hillsboro' },
    { value: 'Hinton', label: 'Hinton' },
    { value: 'Huntington', label: 'Huntington' },
    { value: 'Keyser', label: 'Keyser' },
    { value: 'Lewisburg', label: 'Lewisburg' },
    { value: 'Logan', label: 'Logan' },
    { value: 'Martinsburg', label: 'Martinsburg' },
    { value: 'Morgantown', label: 'Morgantown' },
    { value: 'Moundsville', label: 'Moundsville' },
    { value: 'New Martinsville', label: 'New Martinsville' },
    { value: 'Parkersburg', label: 'Parkersburg' },
    { value: 'Philippi', label: 'Philippi' },
    { value: 'Point Pleasant', label: 'Point Pleasant' },
    { value: 'Princeton', label: 'Princeton' },
    { value: 'Romney', label: 'Romney' },
    { value: 'Shepherdstown', label: 'Shepherdstown' },
    { value: 'South Charleston', label: 'South Charleston' },
    { value: 'Summersville', label: 'Summersville' },
    { value: 'Weirton', label: 'Weirton' },
    { value: 'Welch', label: 'Welch' },
    { value: 'Wellsburg', label: 'Wellsburg' },
    { value: 'Weston', label: 'Weston' },
    { value: 'Wheeling', label: 'Wheeling' },
    { value: 'White Sulphur Springs', label: 'White Sulphur Springs' },
    { value: 'Williamson', label: 'Williamson' },
];

export const citysWisconsin = [
    { value: 'Appleton', label: 'Appleton' },
    { value: 'Ashland', label: 'Ashland' },
    { value: 'Baraboo', label: 'Baraboo' },
    { value: 'Belmont', label: 'Belmont' },
    { value: 'Beloit', label: 'Beloit' },
    { value: 'Eau Claire', label: 'Eau Claire' },
    { value: 'Fond du Lac', label: 'Fond du Lac' },
    { value: 'Green Bay', label: 'Green Bay' },
    { value: 'Hayward', label: 'Hayward' },
    { value: 'Janesville', label: 'Janesville' },
    { value: 'Kenosha', label: 'Kenosha' },
    { value: 'La Crosse', label: 'La Crosse' },
    { value: 'Lake Geneva', label: 'Lake Geneva' },
    { value: 'Madison', label: 'Madison' },
    { value: 'Manitowoc', label: 'Manitowoc' },
    { value: 'Marinette', label: 'Marinette' },
    { value: 'Menasha', label: 'Menasha' },
    { value: 'Milwaukee', label: 'Milwaukee' },
    { value: 'Neenah', label: 'Neenah' },
    { value: 'New Glarus', label: 'New Glarus' },
    { value: 'Oconto', label: 'Oconto' },
    { value: 'Oshkosh', label: 'Oshkosh' },
    { value: 'Peshtigo', label: 'Peshtigo' },
    { value: 'Portage', label: 'Portage' },
    { value: 'Prairie du Chien', label: 'Prairie du Chien' },
    { value: 'Racine', label: 'Racine' },
    { value: 'Rhinelander', label: 'Rhinelander' },
    { value: 'Ripon', label: 'Ripon' },
    { value: 'Sheboygan', label: 'Sheboygan' },
    { value: 'Spring Green', label: 'Spring Green' },
    { value: 'Stevens Point', label: 'Stevens Point' },
    { value: 'Sturgeon Bay', label: 'Sturgeon Bay' },
    { value: 'Superior', label: 'Superior' },
    { value: 'Waukesha', label: 'Waukesha' },
    { value: 'Wausau', label: 'Wausau' },
    { value: 'Wauwatosa', label: 'Wauwatosa' },
    { value: 'West Allis', label: 'West Allis' },
    { value: 'West Bend', label: 'West Bend' },
    { value: 'Wisconsin Dells', label: 'Wisconsin Dells' },
];

export const citysWyoming = [
    { value: 'Buffalo', label: 'Buffalo' },
    { value: 'Casper', label: 'Casper' },
    { value: 'Cheyenne', label: 'Cheyenne' },
    { value: 'Cody', label: 'Cody' },
    { value: 'Douglas', label: 'Douglas' },
    { value: 'Evanston', label: 'Evanston' },
    { value: 'Gillette', label: 'Gillette' },
    { value: 'Green River', label: 'Green River' },
    { value: 'Jackson', label: 'Jackson' },
    { value: 'Lander', label: 'Lander' },
    { value: 'Laramie', label: 'Laramie' },
    { value: 'Newcastle', label: 'Newcastle' },
    { value: 'Powell', label: 'Powell' },
    { value: 'Rawlins', label: 'Rawlins' },
    { value: 'Riverton', label: 'Riverton' },
    { value: 'Rock Springs', label: 'Rock Springs' },
    { value: 'Sheridan', label: 'Sheridan' },
    { value: 'Ten Sleep', label: 'Ten Sleep' },
    { value: 'Thermopolis', label: 'Thermopolis' },
    { value: 'Torrington', label: 'Torrington' },
    { value: 'Worland', label: 'Worland' },
];

////
export const filterCountryACity = (pais) => {
    console.log("SELECCIONADO:", pais);
    switch (pais) {
        case "Alabama":
            return citysAlabama;

        case "Alaska":
            return citysAlaska;

        case "Arizona":
            return citysArizona;

        case "Arkansas":
            return citysArkansas;

        case "California":
            return citysCalifornia;

        case "Colorado":
            return citysColorado;

        case "Connecticut":
            return citysConnecticut;

        case "Delaware":
            return citysDelaware;

        case "Florida":
            return citysFlorida;

        case "Georgia":
            return citysGeorgia;

        case "Hawái":
            return citysHawaii;

        case "Idaho":
            return citysIdaho;

        case "Illinois":
            return citysIllinois;

        case "Indiana":
            return citysIndiana;

        case "Iowa":
            return citysIowa;

        case "Kansas":
            return citysKansas;

        case "Kentucky":
            return citysKentucky;

        case "Louisiana":
            return citysLouisiana;

        case "Maine":
            return citysMaine;

        case "Maryland":
            return citysMaryland;

        case "Massachusetts":
            return citysMassachusetts;

        case "Michigan":
            return citysMichigan;

        case "Minnesota":
            return citysMinnesota;

        case "Mississippi":
            return citysMississippi;

        case "Missouri":
            return citysMissouri;

        case "Montana":
            return citysMontana;

        case "Nebraska":
            return citysNebraska;

        case "Nevada":
            return citysNevada;

        case "New Hampshire":
            return citysNewHampshire;

        case "New Jersey":
            return citysNewJersey;

        case "New Mexico":
            return citysNewMexico;

        case "New York":
            return citysNewYork;

        case "North Carolina":
            return citysNorthCarolina;

        case "North Dakota":
            return citysNorthDakota;

        case "Ohio":
            return citysOhio;

        case "Oklahoma":
            return citysOklahoma;

        case "Oregon":
            return citysOregon;

        case "Pennsylvania":
            return citysPennsylvania;

        case "Rhode Island":
            return citysRhodeIsland;

        case "South Carolina":
            return citysSouthCarolina;

        case "South Dakota":
            return citysSouthDakota;

        case "Tennessee":
            return citysTennessee;

        case "Texas":
            return citysTexas;

        case "Utah":
            return citysUtah;

        case "Vermont":
            return citysVermont;

        case "Virginia":
            return citysVirginia;

        case "Washington":
            return citysWashington;

        case "West Virginia":
            return citysWestVirginia;

        case "Wisconsin":
            return citysWisconsin;

        case "Wyoming":
            return citysWyoming;

        default:
            break;
    }
}
















