import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { startSaveAddres, activeAddress, startDeleting, setActiveAddress } from '../../actions/address';
import { en, es } from '../../types/traslate'
import { removeError, setError } from '../../actions/ui'
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/en-au';
import { setHelperEdit, setModalOpen, setModalCancel } from '../../actions/helpers'

export const AddresScreen1 = () => {

    const dispatch = useDispatch();


    const handleSave = () => {
        if (isFormValid()) {
            dispatch(startSaveAddres(active));
            dispatch(setHelperEdit(false));
        }
    }

    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.address);
    const { address } = useSelector(state => state.address);

    const [formValues, handleInputChange, reset] = useForm(active);
    const { body, title, id, secondname, lastname, username, business, address1, address2, address3, citytown } = formValues;
    const { msgError } = useSelector(state => state.ui);
    const { isNew } = useSelector(state => state.helpers);


    const activeId = useRef(active.id);
    const [err, setErr] = useState({
        title: false,
        address1: false,
    });

    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');

    useEffect(() => {
        if (active.id !== activeId.current) {
            reset(active);
            activeId.current = active.id
        }
    }, [active, reset])

    useEffect(() => {
        dispatch(activeAddress(formValues.id, { ...formValues }));
    }, [formValues, dispatch])

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const isFormValid = () => {

        if (active.title.trim().length === 0) {
            setErr({ ...err, title: true });
            dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'));
            return false;
        }
        if (active.address1.trim().length === 0) {
            setErr({ ...err, address1: true });
            dispatch(setError(ui.spanish ? 'Se requiere la dirección 1' : 'Address 1 is required'));
            return false;
        }

        dispatch(removeError());
        return true;
    }



    const handleVolver = () => {

        if (isNew) {
            if (active.title.trim().length === 0) {
                setErr({ ...err, title: true });
                dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'));

            } else if (active.address1.trim().length === 0) {
                setErr({ ...err, address1: true });
                dispatch(setError(ui.spanish ? 'Se requiere la dirección 1' : 'Address 1 is required'));
            }

            dispatch(setModalCancel(true));


        } else if (address.length > 0) {
            const filterAddress = address.filter(addres => addres.id === active.id)[0];
            if (filterAddress?.title !== active.title || filterAddress?.body !== active.body ||
                filterAddress?.secondname !== active.secondname || filterAddress?.lastname !== active.lastname ||
                filterAddress?.username !== active.username || filterAddress?.business !== active.business ||
                filterAddress?.address1 !== active.address1 || filterAddress?.address2 !== active.address2 ||
                filterAddress?.address3 !== active.address3 || filterAddress?.citytown !== active.citytown) {

                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));
            } else {
                dispatch(setActiveAddress());
            }
        }

        //dispatch(setActiveAddress());
        //dispatch(setHelperEdit(false));
    }

    useEffect(() => {

        if (address.length > 0) {
            const filterAddress = address.filter(addres => addres.id === active.id)[0];
            if (filterAddress?.title !== active.title || filterAddress?.body !== active.body ||
                filterAddress?.secondname !== active.secondname || filterAddress?.lastname !== active.lastname ||
                filterAddress?.username !== active.username || filterAddress?.business !== active.business ||
                filterAddress?.address1 !== active.address1 || filterAddress?.address2 !== active.address2 ||
                filterAddress?.address3 !== active.address3 || filterAddress?.citytown !== active.citytown) {

                dispatch(setHelperEdit(true));
            }
        }


    }, [active.id, address, active, dispatch])

    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>

            {/*  <AddressAppBar1 date={active.date} /> */}

            <div className="notes__content">
                <div className="notes__content-col">
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ border: err.title && active.title === "" ? '1px solid red' : '', width: '30%' }} >
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.address.form.name : en.address.form.name}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="title"
                                value={title}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.address.form.name : en.address.form.name}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode  three-col' : 'form__group  three-col'} style={{ width: '30%' }} >
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.address.form.secon : en.address.form.secon}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="secondname"
                                value={secondname}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.address.form.secon : en.address.form.secon}</label>

                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '30%' }} >
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.address.form.last : en.address.form.last}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field "}
                                autoComplete="off"
                                name="lastname"
                                value={lastname}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.address.form.last : en.address.form.last}</label>

                        </div>
                    </div>
                    <br />
                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.username : en.address.form.username}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="username"
                            value={username}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.username : en.address.form.username}</label>
                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.business : en.address.form.business}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="business"
                            value={business}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.business : en.address.form.business}</label>
                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.city : en.address.form.city}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="citytown"
                            value={citytown}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.city : en.address.form.city}</label>
                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ border: err.address1 && active.address1 === "" ? '1px solid red' : '' }}>
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.address1 : en.address.form.address1}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="address1"
                            value={address1}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.address1 : en.address.form.address1}</label>

                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.address2 : en.address.form.address2}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="address2"
                            value={address2}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.address2 : en.address.form.address2}</label>
                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.address.form.address3 : en.address.form.address3}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="address3"
                            value={address3}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.address3 : en.address.form.address3}</label>

                    </div>
                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <textarea
                            placeholder={ui.spanish ? es.address.form.notes : en.address.form.notes}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            name="body"
                            value={body}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.address.form.notes : en.address.form.notes}</label>

                    </div>
                    <br />

                </div>
                {
                    (active.url) &&
                    <div className="notes__image">
                        <img
                            src={active.url}
                            alt="imagen"
                        />
                    </div>
                }
            </div>

            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"} style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }


            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3  >
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.address.form.save : en.address.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}
                    >
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.address.form.delete : en.address.form.delete} </span>
                        </h3>
                    </button>
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>

            </div>


            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>



        </div>
    )
}
