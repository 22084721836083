import React from 'react'
import { useSelector } from 'react-redux';
import { AddresEntries } from './address/AddressEntries';
import { EmailEntries } from './email/EmailEntries';
import { PasswordEntries } from './password/PasswordEntries';
import { NoteEntries } from './note1/NoteEntries';
import { VideoEntries } from './video/VideoEntries';
import { GlobalEntries } from './GlobalEntries';



export const NothingSelected = ({ type }) => {
    const ui = useSelector(state => state.ui);
    const { photos } = useSelector(state => state.photos);
    const { emails } = useSelector(state => state.emails);
    const { passwords } = useSelector(state => state.passwords);
    const { videos } = useSelector(state => state.videos);
    const { notes } = useSelector(state => state.notes);
    const { address } = useSelector(state => state.address);




    switch (type) {
        case "address":
            if (address.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                        <AddresEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }

        case "photo":
            if (photos.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                        <GlobalEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }

        case "email":
            if (emails.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} >
                        <EmailEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }

        case "notes":
            if (notes.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                        <NoteEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }

        case "password":
            if (passwords.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                        <PasswordEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }

        case "videos":
            if (videos.length > 0) {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                        <VideoEntries />
                    </div>
                )
            } else {
                return (
                    <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"} style={{justifyContent: 'center'}}>
                        <br />
                        <img src='https://i.ibb.co/NSG0Fpq/PNG-High-Res-Shield-Text-30-AUG20.png' height="250px" width="400px" alt="noImage"></img>
                    </div>
                )
            }




        default:
            break;
    }

    /* if (photos.length > 0) {
        return (
            <div className={ui.darkMode ? "nothing__main-content darkMode" : "nothing__main-content"}>
                <GlobalEntries />
            </div>
        )
    } */

}
