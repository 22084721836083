import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { fileUpload } from '../helpers/fileUpload';
import { loadNotes } from '../helpers/loadNotes';

export const startNewNotes = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        const newNote = {
            title: '',
            body: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/notes`).add(newNote);
        console.log(doc);

        dispatch(activeNotes(doc.id, newNote));
        dispatch(addNewwNote(doc.id, newNote));
    }
}

export const activeNotes = (id, note) => ({
    type: types.notesActive,
    payload: {
        id,
        ...note
    }
});

export const addNewwNote = (id, note) => ({
    type: types.notesAddNew,
    payload: {
        id, ...note
    }
})

export const startLoadingNotes = (uid) => {
    return async (dispatch) => {
        const notes = await loadNotes(uid);
        dispatch(setNotes(notes));
    }
}

export const setNotes = (notes) => ({
    type: types.notesLoad,
    payload: notes
});

export const startSaveNote = (note) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;


        if (!note.url) {
            delete note.url;
        }

        const updatedAt = new Date().getTime();
        const noteFirestore = { ...note, updatedAt };
        delete noteFirestore.id;

        await db.doc(`${uid}/global/notes/${note.id}`).update(noteFirestore);

        dispatch(refreshNote(note.id, noteFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', note.title, 'success');
        dispatch(setActiveNotes());

    }
}

export const refreshNote = (id, note) => ({
    type: types.notesUpdated,
    payload: {
        id,
        note: {
            id,
            ...note
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        const { active } = getState().notes;
        const { spanish } = getState().ui;

        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });


        const fileUrl = await fileUpload(file);
        active.url = fileUrl;
        dispatch(startSaveNote(active))


        console.log(fileUrl);
        Swal.close();
    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/notes/${id}`).delete();

        dispatch(deleteNote(id));
    }
}

export const deleteNote = (id) => ({
    type: types.notesDelete,
    payload: id
})

export const noteLogout = () => ({
    type: types.notesLogoutCleaning
})

export const setActiveNotes = () => ({
    type: types.setActiveNotes
})