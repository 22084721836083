import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import { useForm } from '../../hooks/useForm';
import { setError, removeError } from '../../actions/ui';
import { startForgot } from '../../actions/auh';
import { en, es } from '../../types/traslate'

export const ForgotScreen = () => {

    const language = localStorage.getItem("language");
    const theme = localStorage.getItem("theme");


    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        email: '',
    })

    const { email } = formValues;
    const history = useHistory();

    const handleForgot = (e) => {
        e.preventDefault();
        dispatch(startForgot(email, history));
    }

    //reformular messge alert error y registro
    const isFormValid = () => {
        //var e = 'prubea'
        if (!validator.isEmail(email)) {
            dispatch(setError('Email is not valid'));
            return false;
        } else if (validator.isEmail(email)) {
            Swal.fire('Sent', 'Check your email to reset your password');
            return false;
        }
        dispatch(removeError());
        return true;
    }

    return (
        <>
            <div className="auth__logo" >
                <img src='https://i.ibb.co/FgwCn8b/PNG-High-Res-Shield-30-AUG20.png' height="80"
                    width="80" />
            </div>
            <h2 className={theme === "Dark" ? "auth__title_app__dark" : "auth__title_app"}>Lock This</h2>
            <h3 className={theme === "Dark" ? "auth__title__dark" : "auth__title"}>
                {language === 'Español' ? es.forgot.forgot : en.forgot.forgot}
            </h3>
            <form
                className='animate__animated animate__fadeIn'
                onSubmit={handleForgot}
            >

                {
                    msgError &&
                    (
                        <div className="auth__alert-error">
                            {msgError}
                        </div>
                    )
                }

                <input
                    type="text"
                    placeholder={language === 'Español' ? es.forgot.email : en.forgot.email}
                    name="email"
                    className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                />


                <button
                    type="submit"
                    className="btn btn-primary btn-block mb-5"
                >
                    {language === 'Español' ? es.forgot.btnForgot : en.forgot.btnForgot}
                </button>
                {/* <hr /> */}


                <Link
                    to="/auth/login"
                    className="link">
                    {language === 'Español' ? es.forgot.already : en.forgot.already}
                </Link>

                <h3 className={theme === "Dark" ? "link__dark" : "link"}>v2.0</h3>
            </form>
        </>
    )
}
