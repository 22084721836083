export const fileUpload = async (file) => {
    const cloudUrl = 'https://api.cloudinary.com/v1_1/dyhzmh7l5/upload';
    const formData = new FormData();
    formData.append('upload_preset','updgo6el');
    formData.append('file', file);

    try {
        const resp = await fetch ( cloudUrl, {
            method: 'POST',
            body: formData
        });
        if(resp.ok){
            const cloudResp = await resp.json();
            return cloudResp.secure_url;
        }else {
            throw await resp.json();
        }
    } catch (err) {
        throw err;
    }

}

export const fileUploadVideo = async (file) => {
    const cloudUrl = 'https://api.cloudinary.com/v1_1/dyhzmh7l5/image/upload?upload_preset=updgo6el';
    const formData = new FormData();
    formData.append('upload_preset','global');
    formData.append('file', file);

    try {
        const resp = await fetch ( cloudUrl, {
            method: 'POST',
            body: formData
        });
        if(resp.ok){
            const cloudResp = await resp.json();
            return cloudResp.secure_url;
        }else {
            throw await resp.json();
        }
    } catch (err) {
        throw err;
    }

}