import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { loadPasswords } from "../helpers/loadPassword";
import { fileUpload } from '../helpers/fileUpload';

export const startNewPasswords = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        /*  const newPassword = {
             title: '',
             body: '',
             name: '',
             username: '',
             sitepassword: '',
             date: new Date().getTime()
         } */

        const newPassword = {
            username: '',
            password: '',
            company: '',
            description: '',
            site: '',
            account: '',
            pin: '',
            email: '',
            accountId: '',
            customer: '',
            otherPhone1: '',
            otherPhone2: '',
            recoveryEmail: '',
            companyAddress1: '',
            companyAddress2: '',
            country: '',
            cityUSA: '',
            citysX: '',
            question1: '',
            answer1: '',
            question2: '',
            answer2: '',
            question3: '',
            answer3: '',
            question4: '',
            answer4: '',
            city: '',
            zipcode: '',
            body: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/passwords`).add(newPassword);
        console.log(doc);

        dispatch(activePasswords(doc.id, newPassword));
        dispatch(addNewwPassword(doc.id, newPassword));
    }
}

export const activePasswords = (id, password) => ({
    type: types.passwordsActive,
    payload: {
        id,
        ...password
    }
});

export const addNewwPassword = (id, password) => ({
    type: types.passwordsAddNew,
    payload: {
        id, ...password
    }
})

export const startLoadingPasswords = (uid) => {
    return async (dispatch) => {
        const passwords = await loadPasswords(uid);
        dispatch(setPasswords(passwords));
    }
}

export const setPasswords = (passwords) => ({
    type: types.passwordsLoad,
    payload: passwords
});

export const startSavePassword = (password) => {

    console.log("START password: ", password)
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;

        if (!password.url) {
            delete password.url;
        }

        const updatedAt = new Date().getTime();
        const passwordFirestore = { ...password, updatedAt };
        delete passwordFirestore.id;

        await db.doc(`${uid}/global/passwords/${password.id}`).update(passwordFirestore);

        dispatch(refreshPassword(password.id, passwordFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', password.title, 'success');

        dispatch(setActivePassword());

    }
}

export const refreshPassword = (id, password) => ({
    type: types.passwordsUpdated,
    payload: {
        id,
        password: {
            id,
            ...password
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        const { active } = getState().passwords;
        const { spanish } = getState().ui;

        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });


        const fileUrl = await fileUpload(file);
        active.url = fileUrl;
        dispatch(startSavePassword(active))


        console.log(fileUrl);
        Swal.close();
    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/passwords/${id}`).delete();

        dispatch(deletePassword(id));
    }
}

export const deletePassword = (id) => ({
    type: types.passwordsDelete,
    payload: id
})

export const passwordLogout = () => ({
    type: types.passwordsLogoutCleaning
})

export const setActivePassword = () => ({
    type: types.setActivePassword,
})