import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { activePasswords } from '../../../actions/passwords';
import 'moment/locale/es';
import 'moment/locale/en-au';
import { removeError } from '../../../actions/ui';
import { setHelperEdit, setIsNew, setModalOpen } from '../../../actions/helpers';


export const PasswordEntry = ({
    id,
    account,
    otherPhone1,
    date,
    company,
    username,
    companyAddress2,
    companyAddress1,
    site,
    description,
    body,
    pin,
    email,
    question1,
    customer,
    otherPhone2,
    question3,
    zipcode,
    question2,
    city,
    country,
    question4,
    answer3,
    answer4,
    cityUSA,
    citysX,
    password,
    answer2,
    accountId,
    recoveryEmail,
    answer1,
    url }) => {



    const passwordDate = moment(date).locale('en-au');
    const passwordDateEs = moment(date).locale('es');
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    const obj = {
        account: account ?? '',
        otherPhone1: otherPhone1 ?? '',
        date: date ?? '',
        company: company ?? '',
        username: username ?? '',
        companyAddress2: companyAddress2 ?? '',
        companyAddress1: companyAddress1 ?? '',
        site: site ?? '',
        description: description ?? '',
        body: body ?? '',
        pin: pin ?? '',
        email: email ?? '',
        question1: question1 ?? '',
        customer: customer ?? '',
        otherPhone2: otherPhone2 ?? '',
        question3: question3 ?? '',
        zipcode: zipcode ?? '',
        question2: question2 ?? '',
        city: city ?? '',
        country: country ?? '',
        question4: question4 ?? '',
        answer3: answer3 ?? '',
        answer4: answer4 ?? '',
        cityUSA: cityUSA ?? '',
        citysX: citysX ?? '',
        password: password ?? '',
        answer2: answer2 ?? '',
        accountId: accountId ?? '',
        recoveryEmail: recoveryEmail ?? '',
        answer1: answer1 ?? '',
    }

    const handleEntryClick = () => {
        dispatch(
            activePasswords(id, obj)
        );

        dispatch(removeError());
        //dispatch(setHelperEdit(true));
        dispatch(setModalOpen(false));
        dispatch(setIsNew(false))


    }

    return (
        <div
            className={ui.darkMode ? "global__entry global__entry__darkMode  pointer animate__animated animate__fadeIn" : "global__entry  pointer animate__animated animate__fadeIn"}
            onClick={handleEntryClick}
        >
            {
                url &&
                <div
                    className="global__entry-picture"
                    style={{
                        backgroundSize: 'cover',
                        backgroundImage: `url(${url})`
                    }}
                >
                </div>
            }
            <div className="w150">
                <div className="global__entry-body">
                    <p className={ui.darkMode ? "global__entry-title__darkMode" : "global__entry-title"}>{company}</p>
                    <p className={ui.darkMode ? "global__entry-title__darkMode" : "global__entry-title"} style={{ fontSize: '20px' }}>
                        <a href={site} target="_blank" rel="noopener noreferrer">{site}</a>
                    </p>
                    <p className="global__entry-content">
                        {email}
                    </p>
                </div>
                {/* <div className={ui.darkMode ? "global__entry-date-box__darkMode" : "global__entry-date-box"}>
                    <span>{ui.spanish ? passwordDateEs.format('dddd') : passwordDate.format('dddd')}</span>
                    <h4>{ui.spanish ? passwordDateEs.format('Do') : passwordDate.format('Do')}</h4>
                </div> */}
            </div>
        </div>
    )
}
