import React from 'react'
import { useSelector } from 'react-redux'
import { EmailEntry } from './EmailEntry';

export const EmailEntries = () => {

    const { emails } = useSelector(state => state.emails);
    //console.log(photos)

    return (
        <div className = "global__entries ">
            {
                emails.map(email => (
                    <EmailEntry 
                        key = { email.id }
                        {...email}

                    />
                ))
            }
        </div>
    )
}
