import React from 'react'
import { useSelector } from 'react-redux'
import { VideoEntry } from './VideoEntry';

export const VideoEntries = () => {

    const { videos } = useSelector(state => state.videos);
    //console.log(photos)

    return (
        <div className = "global__entries ">
            {
                videos.map(video => (
                    <VideoEntry 
                        key = { video.id }
                        {...video}

                    />
                ))
            }
        </div>
    )
}
