import { types } from "../types/types"

const initialState = {
    videos: [],
    active: null
}

export const videosReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.videosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.videosAddNew:
            return {
                ...state,
                videos: [action.payload, ...state.videos]
            }
        case types.videosLoad:
            return {
                ...state,
                videos: [...action.payload]
            }
        case types.videosUpdated:
            return {
                ...state,
                videos: state.videos.map(
                    video => video.id === action.payload.id
                        ? action.payload.video
                        : video
                ),
                active: action.payload.video
            }
        case types.videosDelete:
            return {
                ...state,
                active: null,
                videos: state.videos.filter(video => video.id !== action.payload)
            }
        case types.videosLogoutCleaning:
            return {
                ...state,
                active: null,
                notes: []
            }

        case types.setActiveVideo:
            return {
                ...state,
                active: null
            }
        default:
            return state
    }

}

