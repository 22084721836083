import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setActiveAddress } from '../../actions/address';
import { setActiveEmail } from '../../actions/emails';
import { setHelperEdit, setIsNew, setModalCancel, setModalOpen, setRutas, setSavedPhotos, setSavedVideo } from '../../actions/helpers';
import { setActiveNotes } from '../../actions/notes';
import { setActivePassword } from '../../actions/passwords';
import { setActivePhoto } from '../../actions/photos';
import { setActiveVideo } from '../../actions/videos';
import { en, es } from '../../types/traslate';


export const Modals = ({ type }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { path } = useSelector(state => state.helpers);
    const { rutas } = useSelector(state => state.helpers);
    const { modalIsOpen } = useSelector(state => state.helpers);
    const { modalIsCancel } = useSelector(state => state.helpers);




    const { darkMode } = useSelector(state => state.ui);
    const { spanish } = useSelector(state => state.ui);


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '500px',
            transform: 'translate(-50%, -50%)',
            color: darkMode ? '#d2d3d0' : '#000',
            backgroundColor: darkMode ? '#151E21' : '#fff',
            borderRadius: '20px'
        },

    };

    const onClickGetOut = () => {
        dispatch(setRutas(false));
        dispatch(setHelperEdit(false));
        dispatch(setSavedPhotos(""));
        dispatch(setSavedVideo(""));

        /* dispatch(setIsNew(false)); */

        history.push(path);
    }

    const onClickVolver = () => {
        dispatch(setModalOpen(false));
        dispatch(setHelperEdit(false));
        dispatch(setSavedPhotos(""));
        dispatch(setSavedVideo(""));

        /* dispatch(setIsNew(false)); */

        switch (type) {
            case "photo":
                dispatch(setActivePhoto(false));
                break;

            case "email":
                dispatch(setActiveEmail(false));
                break;

            case "address":
                dispatch(setActiveAddress(false));
                break;

            case "note":
                dispatch(setActiveNotes(false));
                break;

            case "password":
                dispatch(setActivePassword(false));
                break;

            case "video":
                dispatch(setActiveVideo(false));
                break;

            default:
                break;
        }

    }

    if (rutas) {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={rutas}
                style={customStyles}
                contentLabel="Modal">
                <div>
                    <h1 style={{ textAlign: 'center' }}>{spanish ? es.modal.title : en.modal.title}</h1>
                    <h3 style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                        {spanish ? es.modal.text : en.modal.text}
                    </h3>

                    <div style={{ textAlign: 'center' }}>
                        <button className="btn btn-save-modal" onClick={() => onClickGetOut()}>
                            <h3>
                                <span>
                                    {
                                        spanish ? es.modal.btn1 : en.modal.btn1
                                    }

                                </span>
                            </h3>
                        </button>
                        <button className="btn btn-danger-modal" onClick={() => dispatch(setRutas(false))}>
                            <h3>
                                <span> {
                                    spanish ? es.modal.btn2 : en.modal.btn2
                                } </span>
                            </h3>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    } else if (modalIsOpen) {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Modal">
                <div>
                    <h1 style={{ textAlign: 'center' }}>{spanish ? es.modalCancel.title : en.modalCancel.title}</h1>
                    <h3 style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                        {spanish ? es.modalCancel.text : en.modalCancel.text}
                    </h3>

                    <div style={{ textAlign: 'center' }}>
                        <button className="btn btn-save-modal" onClick={() => onClickVolver()}>
                            <h3>
                                <span>
                                    {
                                        spanish ? es.modalCancel.btn1 : en.modalCancel.btn1
                                    }

                                </span>
                            </h3>
                        </button>
                        <button className="btn btn-danger-modal" onClick={() => dispatch(setModalOpen(false))}>
                            <h3>
                                <span> {
                                    spanish ? es.modal.btn2 : en.modal.btn2
                                } </span>
                            </h3>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    } else {
        return (
            <Modal
                ariaHideApp={false}
                isOpen={modalIsCancel}
                style={customStyles}
                contentLabel="Modal">
                <div>
                    <h1 style={{ textAlign: 'center' }}>{spanish ? es.modalCancelAdd.title : en.modalCancelAdd.title}</h1>
                    <h3 style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                        {spanish ? es.modalCancelAdd.text : en.modalCancelAdd.text}
                    </h3>

                    <div style={{ textAlign: 'center' }}>
                        <button className="btn btn-save-modal" onClick={() => dispatch(setModalCancel(false))}>
                            <h3>
                                <span>
                                    {
                                        spanish ? es.modalCancelAdd.btn1 : en.modalCancelAdd.btn1
                                    }

                                </span>
                            </h3>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

}
