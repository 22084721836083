import React from 'react'
import { useSelector } from 'react-redux'
import { PasswordEntry } from './PasswordEntry';

export const PasswordEntries = () => {

    const { passwords } = useSelector(state => state.passwords);
    //console.log(photos)

    return (
        <div className = "global__entries ">
            {
                passwords.map(password => (
                    <PasswordEntry 
                        key = { password.id }
                        {...password}

                    />
                ))
            }
        </div>
    )
}
