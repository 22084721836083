import { useState } from 'react';


export const useForm = (initialState = {}) => {

    const [values, setValues] = useState(initialState);

    const reset = (newFormState = initialState) => {
        setValues(newFormState);
    }


    const handleInputChange = (e, tipo) => {
        /******/
        if (e.value) {
            if (e.value && tipo === 'country') {
                setValues({
                    ...values,
                    cityUSA: "",
                    citysX: "",
                    country: e.value
                });
            } else if (e.value && tipo === 'city') {
                setValues({
                    ...values,
                    city: "",
                    cityUSA: e.value
                });
            } else if (e.value && tipo === 'cityUSA') {
                setValues({
                    ...values,
                    city: "",
                    citysX: e.value
                });
            }
            /******/
        } else {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            });
        }

    }

    return [values, handleInputChange, reset];

}

