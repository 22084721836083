export const en = {
    login: {
        login: 'Login',
        email: 'Email',
        password: 'Password',
        btnLogin: 'Login',
        signInGoogle: 'Sign In With Google',
        url: 'Login with social networks',
        createNew: 'Create new Account',
        forgotPassword: 'Forgot Password',
    },

    register: {
        register: 'Register',
        email: 'Email',
        password: 'Password',
        confirmPass: 'Confirm password',
        btnRegister: 'Register',
        already: 'Already registered?'
    },
    forgot: {
        forgot: 'Forgot Password',
        email: 'Email',
        btnForgot: 'Send',
        already: 'Already registered?',
    },
    logout: "Logout",
    picture: "Picture",
    video: "Video",
    newEntry: "New Entry",
    photoVault: {
        id: "Photo Vault",
        form: {
            name: "Name",
            note: "Note",
            save: "Save",
            delete: "Delete",
        }
    },
    emails: {
        id: "Emails",
        form: {
            name: "Name",
            email: "Email",
            note: "Note",
            save: "Save",
            delete: "Delete",
        }
    },
    password: {
        id: "Passwords",
        form: {
            username: "Username",
            password: "Passwords",
            company: "Name of Company",
            description: "Company Description",
            site: "Site URL",
            questions: "Question",
            answers: "Answer",
            account: "Account ID",
            pin: "PIN Number",
            email: "Email associated with Users Account",
            accountId: "Account ID",
            customer: "Customer Service Phone",
            otherPhone1: "Other Phone 1",
            otherPhone2: "Other Phone 2",
            recoveryEmail: "Recovery Email",
            companyAdress1: "Company Address 1",
            companyAdress2: "Company Address 2",
            state: "State",
            city: "City",
            zipCode: "Postal Code",
            country: "Country",
            notes: "Notes",
            save: "Save",
            delete: "Delete",
        }
    },

    videoVault: {
        id: "Video Vault",
        form: {
            name: "Name",
            notes: "Notes",
            save: "Save",
            delete: "Delete",
        }
    },

    notes: {
        id: "Notes",
        form: {
            name: "Name",
            notes: "Notes",
            save: "Save",
            delete: "Delete",
        }
    },
    address: {
        id: "Address",
        form: {
            name: "Name",
            secon: "Second name",
            last: "Lastname",
            username: "Username",
            business: "Business",
            city: "City town",
            address1: "Address 1",
            address2: "Address 2",
            address3: "Address 3",
            notes: "Notes",
            save: "Save",
            delete: "Delete",
        }
    },
    settings: "Settings",
    darkMode: "Dark Mode",
    language: "Select a language",
    modal: {
        title: "Are you sure you want to change section?",
        text: "Changes made will be lost",
        btn1: "Yes, exit",
        btn2: "No, stay",
    },
    modalCancel: {
        title: "Are you sure you want to cancel the changes?",
        text: "Changes made will be lost",
        btn1: "Yes, back to the menu",
        btn2: "No, stay",
    },
    modalCancelAdd: {
        title: "It is necessary to save the data",
        text: "Fill in the fields and save",
        btn1: "Ok",
    },
}

export const es = {
    modal: {
        title: "¿Está seguro que quiere cambiar de sección?",
        text: "Se perderán los cambios realizados",
        btn1: "Si, salir",
        btn2: "No, quedarse",
    },
    modalCancel: {
        title: "¿Está seguro que quiere cancelar los cambios?",
        text: "Se perderán los cambios realizados",
        btn1: "Si, volver al menú",
        btn2: "No, quedarse",
    },
    modalCancelAdd: {
        title: "Es necesario guardar los datos",
        text: "Complete los campos y guarde",
        btn1: "Aceptar",
    },
    login: {
        login: 'Acceso',
        email: 'Correo electrónico',
        password: 'Contraseña',
        btnLogin: 'Acceso',
        signInGoogle: 'Iniciar sesión con Google',
        url: 'Iniciar sesión con redes sociales',
        createNew: 'Crear nueva cuenta',
        forgotPassword: 'Olvidé mi contraseña',
    },

    register: {
        register: 'Registrarse',
        email: 'Correo electrónico',
        password: 'Contraseña',
        confirmPass: 'Confirmar contraseña',
        btnRegister: 'Registrarse',
        already: '¿Ya estás registrado?'
    },
    forgot: {
        forgot: 'Se te olvidó tu contraseña',
        email: 'Correo electrónico',
        btnForgot: 'Enviar',
        already: '¿Ya estás registrado?'
    },
    logout: "Cerrar Sesión",
    picture: "Fotografía",
    video: "Video",
    newEntry: "Nueva Entrada",
    photoVault: {
        id: "Bóveda de fotos",
        form: {
            name: "Nombre",
            note: "Nota",
            save: "Guardar",
            delete: "Eliminar",
        }
    },
    emails: {
        id: "Correos electrónicos",
        form: {
            name: "Nombre",
            email: "Correo",
            note: "Nota",
            save: "Guardar",
            delete: "Eliminar",
        }
    },
    password: {
        id: "Contraseñas",
        form: {
            username: "Nombre de usuario",
            password: "Contraseñas",
            company: "Nombre de la empresa",
            description: "Descripción de la empresa",
            questions: "Pregunta",
            answers: "Respuesta",
            site: "URL del sitio",
            account: "ID de cuenta",
            pin: "Número de PIN",
            email: "Correo electrónico asociado a usuarios",
            accountId: "ID de cuenta",
            customer: "Teléfono de atención al cliente",
            otherPhone1: "Otro teléfono 1",
            otherPhone2: "Otro teléfono 2",
            recoveryEmail: "Correo electrónico de recuperación",
            companyAdress1: "Dirección de la empresa 1",
            companyAdress2: "Dirección de la empresa 2",
            state: "Estado",
            city: "Ciudad",
            zipCode: "Código Postal",
            country: "País",
            notes: "Notas",
            save: "Guardar",
            delete: "Eliminar",
        }
    },

    videoVault: {
        id: "Bóveda de video",
        form: {
            name: "Nombre",
            notes: "Notas",
            save: "Guardar",
            delete: "Eliminar",
        }
    },

    notes: {
        id: "Notas",
        form: {
            name: "Nombre",
            notes: "Notas",
            save: "Guardar",
            delete: "Eliminar",
        }
    },
    address: {
        id: "Dirección",
        form: {
            name: "Nombre",
            secon: "Segundo nombre",
            last: "Apellido",
            username: "Nombre de Usuario",
            business: "Empresa",
            city: "Ciudad",
            address1: "Dirección 1",
            address2: "Dirección 2",
            address3: "Dirección 3",
            notes: "Notas",
            save: "Guardar",
            delete: "Eliminar",
        }
    },
    settings: "Opciones",
    darkMode: "Modo Oscuro",
    language: "Selecciona un idioma"
}