import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { loadVideos } from "../helpers/loadVideos";
import { fileUpload } from '../helpers/fileUpload';
import { setHelperEdit, setSavedVideo } from './helpers';

export const startNewVideos = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        const newVideo = {
            title: '',
            body: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/videos`).add(newVideo);
        console.log(doc);

        dispatch(activeVideos(doc.id, newVideo));
        dispatch(addNewwVideo(doc.id, newVideo));
    }
}

export const activeVideos = (id, video) => ({
    type: types.videosActive,
    payload: {
        id,
        ...video
    }
});

export const addNewwVideo = (id, video) => ({
    type: types.videosAddNew,
    payload: {
        id, ...video
    }
})

export const startLoadingVideos = (uid) => {
    return async (dispatch) => {
        const videos = await loadVideos(uid);
        dispatch(setVideos(videos));
    }
}

export const setVideos = (videos) => ({
    type: types.videosLoad,
    payload: videos
});

export const startSaveVideo = (video) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;
        const { videoUrl } = getState().helpers;

        if (!video.url) {
            delete video.url;
        }

        if (videoUrl !== "" && video.url) {
            video.url = videoUrl;
        } else if (videoUrl !== "" && !video.url) {
            video.url = videoUrl;
        }

        /*  video.url = video.url ? video.url : videoUrl; */
        const updatedAt = new Date().getTime();
        const videoFirestore = { ...video, updatedAt };
        delete videoFirestore.id;

        await db.doc(`${uid}/global/videos/${video.id}`).update(videoFirestore);

        dispatch(refreshVideo(video.id, videoFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', video.title, 'success');

        dispatch(setActiveVideo());
        dispatch(setSavedVideo(""));
        dispatch(setHelperEdit(false));

    }
}

export const refreshVideo = (id, video) => ({
    type: types.videosUpdated,
    payload: {
        id,
        video: {
            id,
            ...video
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        /* const { active } = getState().videos; */
        const { spanish } = getState().ui;


        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });


        const fileUrl = await fileUpload(file);
        dispatch(setSavedVideo(fileUrl));
        Swal.close();
        /* active.url = fileUrl;
        dispatch(startSaveVideo(active)) */



    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/videos/${id}`).delete();
        dispatch(setSavedVideo(""));
        dispatch(deleteVideo(id));
    }
}

export const deleteVideo = (id) => ({
    type: types.videosDelete,
    payload: id
})

export const videoLogout = () => ({
    type: types.videosLogoutCleaning
})

export const setActiveVideo = () => ({
    type: types.setActiveVideo
})