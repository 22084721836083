import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { LoginScreen } from '../components/auth/LoginScreen'
import { RegisterScreen } from '../components/auth/RegisterScreen'
import { ForgotScreen } from '../components/auth/ForgotScreen'

export const AuthRouter = () => {

    const theme = localStorage.getItem("theme");



    return (
        <div className = {theme === "Dark" ? "auth_main_dark" : "auth__main"}>
            <div className = {theme === "Dark" ? "auth__box-container__dark " : "auth__box-container"} >
            <Switch>
                <Route
                    path = "/auth/login"
                    component = { LoginScreen }
                />
                <Route
                    path = "/auth/register"
                    component = { RegisterScreen }
                />
                <Route
                    path = "/auth/forgot"
                    component = { ForgotScreen }
                />
                <Redirect
                    to = "/auth/login"
                />
            </Switch>
            </div>
        </div>
    )
}
