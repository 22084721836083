import React from 'react'
import { useSelector } from 'react-redux'
import { AddressEntry } from './AddressEntry';

export const AddresEntries = () => {

    const { address } = useSelector(state => state.address);
    //console.log(photos)

    return (
        <div className = "global__entries ">
            {
                address.map(addres => (
                    <AddressEntry 
                        key = { addres.id }
                        {...addres}

                    />
                ))
            }
        </div>
    )
}
