import React from 'react'
import { useSelector } from 'react-redux'
import { NoteEntry } from './NoteEntry';

export const NoteEntries = () => {

    const { notes } = useSelector(state => state.notes);
    //console.log(photos)

    return (
        <div className = "global__entries ">
            {
                notes.map(note => (
                    <NoteEntry 
                        key = { note.id }
                        {...note}

                    />
                ))
            }
        </div>
    )
}
