import React from 'react'
import moment from 'moment'
import { startUploading } from '../../actions/photos'
import { useDispatch, useSelector } from 'react-redux'
import { en, es } from '../../types/traslate'
import 'moment/locale/es';
import 'moment/locale/en-au';

export const NotesAppBar = ({ date, setFile }) => {

    const photoDate = moment(date).locale('en-au');
    const photoDateEs = moment(date).locale('es');

    const ui = useSelector(state => state.ui);
    const dispatch = useDispatch();
    // const { active } = useSelector( state => state.photos )

    // const handleSave = () => {
    //     dispatch(startSavePhoto(active));
    // }

    const handlePictureClick = () => {
        document.querySelector('#fileSelector').click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        /* setFile(file); */
        if (file) {
            dispatch(startUploading(file));
        }
    }


    return (

        <div /* className={ui.darkMode ? "notes__appbar_dark" : "notes__appbar"} */>
            {/* <span>{ui.spanish ? photoDateEs.format('dddd') + ' ' + photoDateEs.format('Do') : photoDate.format('dddd') + ' ' + photoDate.format('Do')}</span> */}
            <input
                id="fileSelector"
                type="file"
                name="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <div>
                <button
                    /*style={{ backgroundColor: ui.darkMode ? '#7367f0' : '#fff', color: ui.darkMode ? '#fff' : '#000' }}*/
                    className="btn btn-picture"

                    onClick={handlePictureClick}
                >
                    <h3  >
                        <i className="fas fa-camera"></i>
                        <span> {ui.spanish ? es.picture : en.picture} </span>
                    </h3>

                </button>
            </div>
        </div>

    )
}
