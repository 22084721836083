import { db } from '../firebase/firebase-config'

export const loadEmails = async ( uid ) => {
    const emailsSnap = await db.collection(`${ uid }/global/emails`).get();
    const emails = [];
    emailsSnap.forEach ( snapChild => {
        emails.push({
            id: snapChild.id,
            ...snapChild.data()
        })
    });

    console.log({emails});
    
    return emails;
}