import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAZJlvaMcC4zGeXctAumEKn66ywXeSw_nI",
    authDomain: "globalappy-1d891.firebaseapp.com",
    databaseURL: "https://globalappy-1d891.firebaseio.com",
    projectId: "globalappy-1d891",
    storageBucket: "globalappy-1d891.appspot.com",
    messagingSenderId: "385536905208",
    appId: "1:385536905208:web:bc70778f95b078fec4900d",
    measurementId: "G-ZDG0R9F7WC"
  };

  firebase.initializeApp(firebaseConfig);

  const db = firebase.firestore();
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

  export{
      db,
      googleAuthProvider,
      firebase
  }