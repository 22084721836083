import { types } from "../types/types";

const initState = {
    isEdit: false,
    isNew: false,
    modalIsCancel: false,
    modalIsOpen: false,
    rutas: false,
    path: '',
    photoUrl: '',
    videoUrl: '',


}

export const helpersReducer = (state = initState, action) => {

    switch (action.type) {
        case types.setHelperEdit:
            return {
                ...state,
                isEdit: action.payload
            }

        case types.setModalOpen:
            return {
                ...state,
                modalIsOpen: action.payload
            }

        case types.setRutas:
            return {
                ...state,
                rutas: action.payload
            }

        case types.setPath:
            return {
                ...state,
                path: action.payload
            }


        case types.setIsNew:
            return {
                ...state,
                isNew: action.payload
            }

        case types.setModalCancel:
            return {
                ...state,
                modalIsCancel: action.payload
            }

        case types.setSavedPhotos:
            return {
                ...state,
                photoUrl: action.payload
            }

        case types.setSavedVideo:
            return {
                ...state,
                videoUrl: action.payload
            }

        default:
            return state;
    }

}
