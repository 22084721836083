import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';

import { authReducer } from '../reducers/authReducer';
import { uiReducer } from '../reducers/uiReducer';
import { photosReducer } from '../reducers/photosReducer';
import { emailsReducer } from '../reducers/emailsReducer';
import { passwordReducer } from '../reducers/passwordsReducer';
import { videosReducer } from '../reducers/videosReducer';
import { notesReducer } from '../reducers/notesReducer';
import { addressReducer } from '../reducers/addressReducer';
import { helpersReducer } from '../reducers/helpersReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers ({
    auth: authReducer,
    ui: uiReducer,
    photos: photosReducer,
    emails: emailsReducer,
    passwords: passwordReducer,
    videos: videosReducer,
    notes: notesReducer,
    address: addressReducer,
    helpers: helpersReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);