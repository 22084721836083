import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm';
import validator from 'validator';

import { startSavePassword, activePasswords, startDeleting, setActivePassword } from '../../actions/passwords'
import { PasswordsAppBar1 } from './PasswordsAppBar'
import { en, es } from '../../types/traslate';
import Select from 'react-select';
import { citysAlabama, citysAlaska, citysArizona, citysArkansas, citysCalifornia, citysColorado, citysConnecticut, citysDelaware, citysFlorida, citysGeorgia, citysHawaii, citysIdaho, citysIllinois, citysIndiana, citysIowa, citysKansas, citysKentucky, citysLouisiana, citysMaine, citysMaryland, citysMassachusetts, citysMichigan, citysMinnesota, citysMississippi, citysMissouri, citysMontana, citysNebraska, citysNevada, citysNewHampshire, citysNewJersey, citysNewMexico, citysNewYork, citysNorthCarolina, citysNorthDakota, citysOhio, citysOklahoma, citysOregon, citysPennsylvania, citysRhodeIsland, citysSouthCarolina, citysSouthDakota, citysTennessee, citysTexas, citysUtah, citysVermont, citysVirginia, citysWashington, citysWestVirginia, citysWisconsin, citysWyoming, countrys, countrysES, filterCountryACity, sortCountry, sortStateUSA, stateES, stateUS } from '../../types/country';
import { removeError, setError } from '../../actions/ui';
import 'moment/locale/es';
import 'moment/locale/en-au';
import moment from "moment";
import { setHelperEdit, setModalCancel, setModalOpen } from '../../actions/helpers'
import { IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


export const PasswordScreen1 = () => {

    const dispatch = useDispatch();

    const ui = useSelector(state => state.ui);
    const { msgError } = useSelector(state => state.ui);
    const { active } = useSelector(state => state.passwords);
    const { passwords } = useSelector(state => state.passwords);
    const { isNew } = useSelector(state => state.helpers);


    const [formValues, handleInputChange, reset] = useForm(active);

    const [countryUS, setCountryUS] = useState(active.country);
    const [err, setErr] = useState({
        username: false,
        password: false,
        siteUrl: false,
    });

    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });


    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');

    const {
        id,
        username,
        password,
        company,
        description,
        site,
        account,
        pin,
        email,
        accountId,
        customer,
        otherPhone1,
        otherPhone2,
        recoveryEmail,
        companyAddress1,
        companyAddress2,
        country,
        cityUSA,
        citysX,
        question1,
        answer1,
        question2,
        answer2,
        question3,
        answer3,
        question4,
        answer4,
        city,
        zipcode,
        body
    } = formValues;

    const activeId = useRef(active.id);

    useEffect(() => {
        if (active.id !== activeId.current) {
            setCountryUS(active.country);
            setErr({
                ...err, username: false,
                password: false,
                siteUrl: false,
            });

            reset(active);
            activeId.current = active.id
        }
    }, [active, reset, err, countryUS])

    useEffect(() => {
        dispatch(activePasswords(formValues.id, { ...formValues }));
    }, [formValues, dispatch])

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const handleSave = () => {
        if (isFormValid()) {
            setCountryUS('');
            dispatch(startSavePassword(active));
            dispatch(setHelperEdit(false));
        }
    }


    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });

    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const isFormValid = () => {

        /*   if (active.username.trim().length === 0) {
              setErr({ ...err, username: true });
              dispatch(setError(ui.spanish ? 'El nombre de usuario es obligatorio' : 'Username is required'));
              return false;
          }
  
  
          if (active.password.trim().length === 0) {
              setErr({ ...err, password: true });
              dispatch(setError(ui.spanish ? 'La contraseña es obligatoria' : 'Password is required'));
              return false;
          }
  
          if (active.site.trim().length === 0) {
              setErr({ ...err, siteUrl: true });
              dispatch(setError(ui.spanish ? 'El sitio es obligatorio' : 'Site is required'));
              return false;
          }
  
          if (!validator.isURL(active.site, { protocols: ['http', 'https'] })) {
              setErr({ ...err, siteUrl: true });
              dispatch(setError(ui.spanish ? 'El sitio no establece protocolo' : 'The site does not establish protocol'));
              return false;
          } */

        dispatch(removeError());
        return true;
    }

    const handleVolver = () => {

        if (isNew) {
            /*    if (active.username.trim().length === 0) {
                   setErr({ ...err, username: true });
                   dispatch(setError(ui.spanish ? 'El nombre de usuario es obligatorio' : 'Username is required'));
               } else if (active.password.trim().length === 0) {
                   setErr({ ...err, password: true });
                   dispatch(setError(ui.spanish ? 'La contraseña es obligatoria' : 'Password is required'));
               } else if (active.site.trim().length === 0) {
                   setErr({ ...err, siteUrl: true });
                   dispatch(setError(ui.spanish ? 'El sitio es obligatorio' : 'Site is required'));
               } else if (!validator.isURL(active.site, { protocols: ['http', 'https'] })) {
                   setErr({ ...err, siteUrl: true });
                   dispatch(setError(ui.spanish ? 'El sitio no establece protocolo' : 'The site does not establish protocol'));
               } */
            dispatch(setModalCancel(true));


        } else if (passwords.length > 0) {
            const filterPasswords = passwords.filter(pass => pass.id === active.id)[0];
            if (filterPasswords?.username !== active.username || filterPasswords?.password !== active.password ||
                filterPasswords?.site !== active.site || filterPasswords?.company !== active.company ||
                filterPasswords?.description !== active.description || filterPasswords?.account !== active.account ||
                filterPasswords?.pin !== active.pin || filterPasswords?.email !== active.email ||
                filterPasswords?.accountId !== active.accountId || filterPasswords?.customer !== active.customer ||
                filterPasswords?.otherPhone1 !== active.otherPhone1 || filterPasswords?.otherPhone2 !== active.otherPhone2 ||
                filterPasswords?.recoveryEmail !== active.recoveryEmail || filterPasswords?.companyAddress1 !== active.companyAddress1 ||
                filterPasswords?.companyAddress2 !== active.companyAddress2 || filterPasswords?.country !== active.country ||
                filterPasswords?.cityUSA !== active.cityUSA || filterPasswords?.citysX !== active.citysX ||
                filterPasswords?.question1 !== active.question1 || filterPasswords?.answer1 !== active.answer1 ||
                filterPasswords?.question2 !== active.question2 || filterPasswords?.answer2 !== active.answer2 ||
                filterPasswords?.question3 !== active.question3 || filterPasswords?.answer3 !== active.answer3 ||
                filterPasswords?.question4 !== active.question4 || filterPasswords?.answer4 !== active.answer4 ||
                filterPasswords?.city !== active.city || filterPasswords?.zipcode !== active.zipcode ||
                filterPasswords?.body !== active.body) {
                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));

            } else {
                dispatch(setActivePassword());
            }
        }

    }


    useEffect(() => {

        if (passwords.length > 0) {
            const filterPasswords = passwords.filter(pass => pass.id === active.id)[0];
            if (filterPasswords?.username !== active.username || filterPasswords?.password !== active.password ||
                filterPasswords?.site !== active.site || filterPasswords?.company !== active.company ||
                filterPasswords?.description !== active.description || filterPasswords?.account !== active.account ||
                filterPasswords?.pin !== active.pin || filterPasswords?.email !== active.email ||
                filterPasswords?.accountId !== active.accountId || filterPasswords?.customer !== active.customer ||
                filterPasswords?.otherPhone1 !== active.otherPhone1 || filterPasswords?.otherPhone2 !== active.otherPhone2 ||
                filterPasswords?.recoveryEmail !== active.recoveryEmail || filterPasswords?.companyAddress1 !== active.companyAddress1 ||
                filterPasswords?.companyAddress2 !== active.companyAddress2 || filterPasswords?.country !== active.country ||
                filterPasswords?.cityUSA !== active.cityUSA || filterPasswords?.citysX !== active.citysX ||
                filterPasswords?.question1 !== active.question1 || filterPasswords?.answer1 !== active.answer1 ||
                filterPasswords?.question2 !== active.question2 || filterPasswords?.answer2 !== active.answer2 ||
                filterPasswords?.question3 !== active.question3 || filterPasswords?.answer3 !== active.answer3 ||
                filterPasswords?.question4 !== active.question4 || filterPasswords?.answer4 !== active.answer4 ||
                filterPasswords?.city !== active.city || filterPasswords?.zipcode !== active.zipcode ||
                filterPasswords?.body !== active.body) {
                dispatch(setHelperEdit(true));
            }
        }


    }, [active.id, passwords, active, dispatch])


    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}/*  style={{ height: '100%' }} */>
            {/* <PasswordsAppBar1 date={active.date} /> */}

            <div className="notes__content">

                <div className="notes__content__pass-col">
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ border: err.username && active.username === "" ? '1px solid red' : '', width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.username : en.password.form.username}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="username"
                                value={username}
                                onChange={handleInputChange} />
                            <label className="form__label">{ui.spanish ? es.password.form.username : en.password.form.username}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col gpassword-field' : 'form__group three-col gpassword-field'} style={{ border: err.password && active.password === "" ? '1px solid red' : '', width: '47%' }}>
                            <input
                                type={values.showPassword ? "text" : "password"}
                                placeholder={ui.spanish ? es.password.form.password : en.password.form.password}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.password : en.password.form.password}</label>
                            <IconButton className="icon-eye" onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>{values.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                        </div>
                    </div>
                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ border: err.siteUrl && active.site === "" ? '1px solid red' : '', width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.site : en.password.form.site}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="site"
                                value={site}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.site : en.password.form.site}</label>
                        </div>

                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.company : en.password.form.company}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="company"
                                value={company}
                                onChange={handleInputChange} />
                            <label className="form__label">{ui.spanish ? es.password.form.company : en.password.form.company}</label>

                        </div>
                    </div>

                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ width: '98.5%' }}>
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.password.form.description : en.password.form.description}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="description"
                            value={description}
                            onChange={handleInputChange} />
                        <label className="form__label">{ui.spanish ? es.password.form.description : en.password.form.description}</label>
                    </div>

                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>

                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.questions + " 1 " : en.password.form.questions + " 1 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="question1"
                                value={question1}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.questions + " 1 " : en.password.form.questions + " 1 "}</label>
                        </div>

                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.answers + " 1 " : en.password.form.answers + " 1 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="answer1"
                                value={answer1}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.answers + " 1 " : en.password.form.answers + " 1 "}</label>
                        </div>
                    </div>

                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.questions + " 2 " : en.password.form.questions + " 2 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="question2"
                                value={question2}
                                onChange={handleInputChange} />
                            <label className="form__label">{ui.spanish ? es.password.form.questions + " 2 " : en.password.form.questions + " 2 "}</label>

                        </div>

                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.answers + " 2 " : en.password.form.answers + " 2 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="answer2"
                                value={answer2}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.answers + " 2 " : en.password.form.answers + " 2 "}</label>
                        </div>
                    </div>
                    <br />
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.questions + " 3 " : en.password.form.questions + " 3 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="question3"
                                value={question3}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.questions + " 3 " : en.password.form.questions + " 3 "}</label>

                        </div>

                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.answers + " 3 " : en.password.form.answers + " 3 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="answer3"
                                value={answer3}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.answers + " 3 " : en.password.form.answers + " 3 "}</label>

                        </div>
                    </div>
                    <br />
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.questions + " 4 " : en.password.form.questions + " 4 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="question4"
                                value={question4}
                                onChange={handleInputChange} />
                            <label className="form__label">{ui.spanish ? es.password.form.questions + " 4 " : en.password.form.questions + " 4 "}</label>
                        </div>

                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.answers + " 4 " : en.password.form.answers + " 4 "}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="answer4"
                                value={answer4}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.answers + " 4 " : en.password.form.answers + " 4 "}</label>
                        </div>
                    </div>
                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.account : en.password.form.account}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="account"
                                value={account}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.account : en.password.form.account}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="number"
                                placeholder={ui.spanish ? es.password.form.pin : en.password.form.pin}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="pin"
                                value={pin}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.pin : en.password.form.pin}</label>
                        </div>
                    </div>
                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ border: err.email ? ui.darkMode ? { border: '1px solid #310c97' } : { border: '1px solid red' } : {}, width: '47%' }}>
                            <input
                                type="email"
                                placeholder={ui.spanish ? es.password.form.email : en.password.form.email}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.email : en.password.form.email}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.accountId : en.password.form.accountId}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="accountId"
                                value={accountId}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.accountId : en.password.form.accountId}</label>
                        </div>
                    </div>

                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.customer : en.password.form.customer}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="customer"
                                value={customer}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.customer : en.password.form.customer}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.otherPhone1 : en.password.form.otherPhone1}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="otherPhone1"
                                value={otherPhone1}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.otherPhone1 : en.password.form.otherPhone1}</label>
                        </div>
                    </div>

                    <br />

                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.otherPhone2 : en.password.form.otherPhone2}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="otherPhone2"
                                value={otherPhone2}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.otherPhone2 : en.password.form.otherPhone2}</label>
                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ border: err.recoveryEmail ? ui.darkMode ? { border: '1px solid #310c97' } : { border: '1px solid red' } : {}, width: '47%' }}>
                            <input
                                type="email"
                                placeholder={ui.spanish ? es.password.form.recoveryEmail : en.password.form.recoveryEmail}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="recoveryEmail"
                                value={recoveryEmail}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.recoveryEmail : en.password.form.recoveryEmail}</label>
                        </div>

                    </div>
                    <br />
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>

                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.companyAdress1 : en.password.form.companyAdress1}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="companyAddress1"
                                value={companyAddress1}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.companyAdress1 : en.password.form.companyAdress1}</label>

                        </div>
                        <div className={ui.darkMode ? 'form__group__darkMode three-col' : 'form__group three-col'} style={{ width: '47%' }}>


                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.companyAdress2 : en.password.form.companyAdress2}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="companyAddress2"
                                value={companyAddress2}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.companyAdress2 : en.password.form.companyAdress2}</label>

                        </div>

                    </div>
                    <br />
                    <div className="form-group" style={{ justifyContent: 'space-between' }}>
                        <div style={{ width: '30%', marginRight: '10px' }}>
                            <Select
                                name="country"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        height: '56px',
                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                        color: ui.darkMode ? '#fff' : '#121212',
                                        border: ui.darkMode ? '1px solid #000' : '1px solid #d2d3d0',
                                        borderRadius: '10px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                        },
                                        '&:active': {
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                        },
                                    }),
                                    menu: (base, state) => ({
                                        ...base,
                                        height: '56px',
                                        borderRadius: '10px',
                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                        color: ui.darkMode ? '#fff' : '#121212', border: '1px solid #d2d3d0',
                                        boxShadow: 'none',
                                        '&:active': {
                                            border: '1px solid #121212',
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            border: '1px solid #121212',
                                            boxShadow: 'none',
                                        },
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        height: '56px',
                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                        color: ui.darkMode ? '#fff' : '#121212',
                                        boxShadow: 'none',
                                        '&:active': {
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                        },
                                    }),
                                }}
                                value={ui.spanish ? active.country !== "" ? countrysES.find(c => c.value === active.country) : "" : active.country !== "" ? countrys.find(c => c.value === active.country) : ""}
                                placeholder={ui.spanish ? es.password.form.country : en.password.form.country}
                                options={ui.spanish ? sortCountry(countrysES) : sortCountry(countrys)}
                                onChange={(e) => {
                                    handleInputChange(e, 'country');
                                    if (e.value === 'US') {
                                        setCountryUS('US');
                                    } else {
                                        setCountryUS('');
                                    }
                                }} />
                        </div>


                        {
                            countryUS === 'US' ?
                                (
                                    <>
                                        <div style={{ width: '20%', marginRight: '10px' }}>
                                            <Select
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212',
                                                        border: ui.darkMode ? '1px solid #000' : '1px solid #d2d3d0',
                                                        borderRadius: '10px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                    menu: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        borderRadius: '10px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212', border: '1px solid #d2d3d0',
                                                        boxShadow: 'none',
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212',
                                                        boxShadow: 'none',
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                }}

                                                value={ui.spanish ? active.cityUSA !== "" ? stateUS.find(c => c.value === active.cityUSA) : "" : active.cityUSA !== "" ? stateUS.find(c => c.value === active.cityUSA) : ""}
                                                name="cityUSA"
                                                placeholder={ui.spanish ? es.password.form.state : en.password.form.state}
                                                options={ui.spanish ? sortStateUSA(stateUS) : sortStateUSA(stateUS)}
                                                onChange={(e) => handleInputChange(e, 'city')} />
                                        </div>

                                        <div style={{ width: '20%', marginRight: '10px' }}>
                                            <Select
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212',
                                                        border: ui.darkMode ? '1px solid #000' : '1px solid #d2d3d0',
                                                        borderRadius: '10px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                    menu: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        borderRadius: '10px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212', border: '1px solid #d2d3d0',
                                                        boxShadow: 'none',
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        height: '56px',
                                                        backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                                        color: ui.darkMode ? '#fff' : '#121212',
                                                        boxShadow: 'none',
                                                        '&:active': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                        '&:focus': {
                                                            border: '1px solid #363636',
                                                            boxShadow: 'none',
                                                        },
                                                    }),
                                                }}

                                                value={active.cityUSA !== "" ? filterCountryACity(active.cityUSA)?.find(c => c.value === active.citysX) : ""}
                                                name="citysX"
                                                placeholder={ui.spanish ? es.password.form.city : en.password.form.city}
                                                options={filterCountryACity(active.cityUSA)}
                                                onChange={(e) => handleInputChange(e, 'cityUSA')} />
                                        </div>
                                    </>

                                )
                                :
                                (
                                    <>
                                        <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ marginRight: '10px' }}>
                                            <input
                                                type="text"
                                                placeholder={ui.spanish ? es.password.form.state : en.password.form.state}
                                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                                autoComplete="off"
                                                name="city"
                                                value={city !== "" ? city : ""}
                                                onChange={handleInputChange}
                                            //value={ui.spanish ? active.country === 'US' ? stateES.filter(c => c.value === active.cityUSA)[0]?.label : city : active.country === 'US' ? stateUS.filter(c => c.value === active.cityUSA)[0]?.label : city}
                                            />
                                            <label className="form__label">{ui.spanish ? es.password.form.state : en.password.form.state}</label>
                                        </div>
                                        <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ marginRight: '10px' }}>

                                            <input
                                                type="text"
                                                placeholder={ui.spanish ? es.password.form.city : en.password.form.city}
                                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                                autoComplete="off"
                                                name="citysX"
                                                value={citysX !== undefined ? citysX : ''}
                                                onChange={handleInputChange}
                                            />
                                            <label className="form__label">{ui.spanish ? es.password.form.city : en.password.form.city}</label>
                                        </div>
                                    </>
                                )
                        }
                        <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                            <input
                                type="text"
                                placeholder={ui.spanish ? es.password.form.zipCode : en.password.form.zipCode}
                                className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                                autoComplete="off"
                                name="zipcode"
                                value={zipcode}
                                onChange={handleInputChange}
                            />
                            <label className="form__label">{ui.spanish ? es.password.form.zipCode : en.password.form.zipCode}</label>
                        </div>

                    </div>

                    <br />

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ width: '98.5%' }}>

                        <textarea
                            placeholder={ui.spanish ? es.password.form.notes : en.password.form.notes}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            name="body"
                            value={body}
                            onChange={handleInputChange}
                        ></textarea>
                        <label className="form__label">{ui.spanish ? es.password.form.notes : en.password.form.notes}</label>

                    </div>



                    {/* NEW */}
                    {/*   <input
                        type="text"
                        placeholder={ui.spanish ? es.password.form.url : en.password.form.url}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        name="title"
                        value={title}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        placeholder={ui.spanish ? es.password.form.name : en.password.form.name}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        placeholder={ui.spanish ? es.password.form.usename : en.password.form.usename}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        name="username"
                        value={username}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        placeholder={ui.spanish ? es.password.form.site : en.password.form.site}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        name="sitepassword"
                        value={sitepassword}
                        onChange={handleInputChange}
                    />
                    <textarea
                        placeholder={ui.spanish ? es.password.form.notes : en.password.form.notes}
                        className={ui.darkMode ? "notes__textarea__darkMode" : "notes__textarea"}
                        name="body"
                        value={body}
                        onChange={handleInputChange}
                    ></textarea> */}
                </div>
                {
                    (active.url) &&
                    <div className="notes__image">
                        <img
                            src={active.url}
                            alt="imagen"
                        />
                    </div>
                }
            </div >

            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"} style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }

            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3  >
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.password.form.save : en.password.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}
                    >
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.password.form.delete : en.password.form.delete} </span>
                        </h3>
                    </button>
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>


            </div>

            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>


        </div >
    )
}
