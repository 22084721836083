import React, { Component, useState } from "react";
import { SidebarData } from './SidebarData'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { startUploading } from '../actions/photos'
import Swal from "sweetalert2";
import { fileUpload } from "../helpers/fileUpload";
import { uploadProfileImage } from "../helpers/loadProfile";

import { setModeDark } from "../actions/ui";
import Switch from "react-switch";
import { Modal } from "./modal/Modal";



export const Sidebarlist = () => {
    const selector = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const ui = useSelector(state => state.ui);


    const { uid, image } = selector;
    const [state, setState] = useState({
        profileImg: image !== '' ? image : undefined
    }
    );
    const profileImg = state['profileImg'];
    console.log('selector', selector)
    console.log('PROFILE IMAGE ', image)
    const handlePictureClick = () => {
        document.querySelector('#profileSelector').click();
    }
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                Swal.fire({
                    title: ui.spanish ? 'Subiendo' : 'Uploading...',
                    text: ui.spanish ? 'Espere por favor' : 'Please wait',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    }
                });
                const fileUrl = await fileUpload(file);
                await uploadProfileImage(uid, fileUrl);
                setState({ profileImg: fileUrl });
                Swal.close();
            } catch (e) {
                Swal.close();
                Swal.fire('Error', ui.spanish ? 'Error de carga de imagen' : 'Image upload error', 'error');
            }
        }
    }

    const handleDarkMode = (e) => {
        if (e) {
            localStorage.setItem('theme', 'Dark');
        } else {
            localStorage.setItem('theme', 'Lite');
        }
        dispatch(setModeDark(!ui.darkMode));
    }


    return (
        <aside className={ui.darkMode ? "global__sidebar darkModeP" : "global__sidebar"}>
            <div className="global__sidebar-navbar2">
                {/* <h3  >
                            <i className = "far fa-moon"></i>
                            <span> User </span>
                        </h3> */}

            </div>

            <div className="global__new-entry">
                <div className="logo">
                    <img src={profileImg ?? 'https://i.ibb.co/FgwCn8b/PNG-High-Res-Shield-30-AUG20.png'} height="80"
                        alt={profileImg} width="80" style={profileImg ? { objectFit: 'cover', borderRadius: '50%' } : {}} />
                    <input
                        id="profileSelector"
                        type="file"
                        name="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <i className="pl-2 fa fa-plus-square fa-2x" onClick={handlePictureClick}></i>
                    {/*<div>*/}
                    {/*    <button*/}
                    {/*        className = "btn btn-upload"*/}
                    {/*        onClick = { handlePictureClick }*/}
                    {/*    >*/}
                    {/*        Picture*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>

            </div>
            {/* <input type="file" name="image-upload" id="input" accept="image/*" onChange={this.handleFileChange}></input>
                        <div className="label">
                            <label htmlFor="input" className="image-upload">
                                <i className="material-icons">add_photo_alternate</i>
                                Choose your photo
                            </label>
                        </div> */}


            <SidebarData handleDarkMode={handleDarkMode} />
            {/*  <div className={ui.darkMode ? "global__nav-menu_darkMode" : "global__nav-menu"}>
                <ul>
                    {SidebarData.map((item, index) => {                        
                        if (item.title === 'Dark Mode' || item.title === 'Modo Oscuro') {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        <>
                                            <Switch
                                                onColor="#0F6BDA"
                                                checked={ui.darkMode ? true : false}
                                                onChange={handleDarkMode}
                                                checkedIcon={
                                                    <i className="fa fa-moon" />
                                                }
                                                uncheckedHandleIcon={
                                                    <i className="fa fa-sun" />
                                                }

                                            />
                                            <span style={{ marginLeft: '10px' }}>{item.title}</span>
                                        </>
                                    </Link>
                                </li>
                            )
                        } else {
                            return (
                                <li key={index} className={item.cName}>
                                    <Link to={item.path}>
                                        {item.icons}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            )
                        }

                    })}
                </ul>
            </div> */}
        </aside>
    )
};
//
// export class Sidebarlist extends Component {
//
//
//     dispatch = useDispatch();
//
//     handleFileChange = (e) => {
//          const reader = new FileReader();
//          reader.onload = () => {
//              if(reader.readyState === 2){
//                  this.setState({profileImg: reader.result})
//              }
//          }
//          reader.readAsDataURL(e.target.files[0])
//      }
//
//      handleFileChange = (e) => {
//
//         const file = e.target.files[0];
//         if(file){
//             useDispatch(startUploading(file));
//         }
//     }
//
//     render() {
//         return (
//         )
//     }
//
//
// }
