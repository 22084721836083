import { types } from "../types/types"

const initialState = {
    photos: [],
    active: null
}

export const photosReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.photosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.photosAddNew:
            return {
                ...state,
                photos: [action.payload, ...state.photos]
            }
        case types.photosLoad:
            return {
                ...state,
                photos: [...action.payload]
            }
        case types.photosUpdated:
            return {
                ...state,
                photos: state.photos.map(
                    photo => photo.id === action.payload.id
                        ? action.payload.photo
                        : photo
                ),
                active: action.payload.photo
            }
        case types.photosDelete:
            return {
                ...state,
                active: null,
                photos: state.photos.filter(photo => photo.id !== action.payload)
            }
        case types.photosLogoutCleaning:
            return {
                ...state,
                active: null,
                notes: []
            }

        case types.setActivePhoto:
            return {
                ...state,
                active: null,
            }
        default:
            return state
    }

}

