import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { loadEmails } from "../helpers/loadEmails";
import { fileUpload } from '../helpers/fileUpload';

export const startNewEmails = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        const newEmail = {
            title: '',
            mail: '',
            body: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/emails`).add(newEmail);
        console.log(doc);

        dispatch(activeEmails(doc.id, newEmail));
        dispatch(addNewwEmail(doc.id, newEmail));
    }
}

export const activeEmails = (id, email) => ({
    type: types.emailsActive,
    payload: {
        id,
        ...email
    }
});

export const addNewwEmail = (id, email) => ({
    type: types.emailsAddNew,
    payload: {
        id, ...email
    }
})

export const startLoadingEmails = (uid) => {
    return async (dispatch) => {
        const emails = await loadEmails(uid);
        dispatch(setEmails(emails));
    }
}

export const setEmails = (emails) => ({
    type: types.emailsLoad,
    payload: emails
});

export const startSaveEmail = (email) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;

        if (!email.url) {
            delete email.url;
        }
        const updatedAt = new Date().getTime();
        const emailFirestore = { ...email, updatedAt };
        delete emailFirestore.id;
        console.log('NEW EMAIL FIRESTORE', emailFirestore);
        await db.doc(`${uid}/global/emails/${email.id}`).update(emailFirestore);

        dispatch(refreshEmail(email.id, emailFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', email.title, 'success');
        dispatch(setActiveEmail());

    }
}

export const refreshEmail = (id, email) => ({
    type: types.emailsUpdated,
    payload: {
        id,
        email: {
            id,
            ...email
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        const { active } = getState().emails;
        const { spanish } = getState().ui;

        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });


        const fileUrl = await fileUpload(file);
        active.url = fileUrl;
        dispatch(startSaveEmail(active))


        console.log(fileUrl);
        Swal.close();
    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/emails/${id}`).delete();

        dispatch(deleteEmail(id));
    }
}

export const deleteEmail = (id) => ({
    type: types.emailsDelete,
    payload: id
})

export const emailLogout = () => ({
    type: types.emailsLogoutCleaning
})

export const setActiveEmail = () => ({
    type: types.setActiveEmail
})