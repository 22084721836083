import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { EmailsAppBar1 } from './EmailsAppBar'
import { startSaveEmail, activeEmails, startDeleting, setActiveEmail } from '../../actions/emails'
import { en, es } from '../../types/traslate'
import { removeError, setError } from '../../actions/ui';
import validator from 'validator';
import moment from "moment";
import 'moment/locale/es';
import 'moment/locale/en-au';
import { setHelperEdit, setModalCancel, setModalOpen } from '../../actions/helpers'

export const EmailScreen1 = () => {

    const dispatch = useDispatch();


    const handleSave = () => {
        if (isFormValid()) {
            dispatch(startSaveEmail(active));
            dispatch(setHelperEdit(false));

        }
    }
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.emails);
    const { emails } = useSelector(state => state.emails);


    const [formValues, handleInputChange, reset] = useForm(active);
    const { body, title, mail, id } = formValues;
    const { msgError } = useSelector(state => state.ui);
    const { isNew } = useSelector(state => state.helpers)

    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');

    const activeId = useRef(active.id);
    const [err, setErr] = useState({
        title: false,
        mail: false,
    });

    useEffect(() => {
        if (active.id !== activeId.current) {
            reset(active);
            activeId.current = active.id
        }
    }, [active, reset])

    useEffect(() => {
        dispatch(activeEmails(formValues.id, { ...formValues }));
    }, [formValues, dispatch])

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const isFormValid = () => {

        if (active.title.trim().length === 0) {
            setErr({ ...err, title: true });
            dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))
            return false;
        }

        if (active.mail.trim().length === 0) {
            setErr({ ...err, mail: true });
            dispatch(setError(ui.spanish ? 'Se requiere el email' : 'Email is required'))
            return false;
        }


        if (!validator.isEmail(active.mail)) {
            setErr({ ...err, mail: true });
            dispatch(setError(ui.spanish ? 'El email no es válido' : 'Email is not valid'));
            return false;
        }

        dispatch(removeError());
        return true;
    }


    const handleVolver = () => {
        if (isNew) {
            if (active.title.trim().length === 0) {
                setErr({ ...err, title: true });
                dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))

            } else if (active.mail.trim().length === 0) {
                setErr({ ...err, mail: true });
                dispatch(setError(ui.spanish ? 'Se requiere el email' : 'Email is required'))

            } else if (!validator.isEmail(active.mail)) {
                setErr({ ...err, mail: true });
                dispatch(setError(ui.spanish ? 'El email no es válido' : 'Email is not valid'));

            }

            dispatch(setModalCancel(true));


        } else if (emails.length > 0) {
            const filterEmails = emails.filter(email => email.id === active.id)[0];
            if (filterEmails?.title !== active.title || filterEmails?.body !== active.body ||
                filterEmails?.mail !== active.mail) {
                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));
            } else {
                dispatch(setActiveEmail());

            }
        }
    }

    useEffect(() => {

        if (emails.length > 0) {
            const filterEmails = emails.filter(email => email.id === active.id)[0];
            if (filterEmails?.title !== active.title || filterEmails?.body !== active.body ||
                filterEmails?.mail !== active.mail) {

                dispatch(setHelperEdit(true));
            }
        }


    }, [active.id, emails, active, dispatch])

    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>
            {/* <EmailsAppBar1 date={active.date} /> */}

            <div className="notes__content">
                <div className="notes__content-col">
                    <input
                        type="text"
                        placeholder={ui.spanish ? es.emails.form.name : en.emails.form.name}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        style={{ border: err.title && active.title === "" ? '1px solid red' : '' }}

                        name="title"
                        value={title}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        placeholder={ui.spanish ? es.emails.form.email : en.emails.form.email}
                        className={ui.darkMode ? "notes__title-input__darkMode" : "notes__title-input"}
                        autoComplete="off"
                        name="mail"
                        style={{ border: err.mail && active.mail === "" ? '1px solid red' : '' }}

                        value={mail}
                        onChange={handleInputChange}
                    />
                    <textarea
                        placeholder={ui.spanish ? es.emails.form.note : en.emails.form.note}
                        className={ui.darkMode ? "notes__textarea__darkMode" : "notes__textarea"}
                        name="body"
                        value={body}
                        onChange={handleInputChange}
                    ></textarea>
                </div>
                {
                    (active.url) &&
                    <div className="notes__image">
                        <img
                            src={active.url}
                            alt="imagen"
                        />
                    </div>
                }
            </div>
            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"}
                            style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }

            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3>
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.emails.form.save : en.emails.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}
                    >
                        <h3>
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.emails.form.delete : en.emails.form.delete} </span>
                        </h3>
                    </button>
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3>
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>

            </div>

            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>

        </div>
    )
}
