import { db } from '../firebase/firebase-config'

export const loadPasswords = async ( uid ) => {
    const passwordsSnap = await db.collection(`${ uid }/global/passwords`).get();
    const passwords = [];
    passwordsSnap.forEach ( snapChild => {
        passwords.push({
            id: snapChild.id,
            ...snapChild.data()
        })
    });

    console.log(passwords);
    
    return passwords;
}