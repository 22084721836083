import React from 'react'
import { useSelector } from 'react-redux'
import { NothingSelected } from '../NothingSelected'
import { Sidebarlist } from '../../Sidebarlist'
import { NavBarEmails } from '../NavBar'
import { EmailScreen1 } from '../../emails/EmailScreen'
import { SidebarEmails } from './SidebarEmails'
import { Modals } from '../../modal/Modal'

export const EmailScreen = () => {

    const { active } = useSelector(state => state.emails)
    const ui = useSelector(state => state.ui);
    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBarEmails />
                <main>
                    {
                        (active)
                            ? (<EmailScreen1 />)
                            : (<NothingSelected type="email" />)
                    }
                </main>
                <Modals type="email" />
                {/* <SidebarEmails /> */}
            </div>
        </div>
    )
}
