import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { fileUpload } from '../helpers/fileUpload';
import { loadAddress } from '../helpers/loadAddress';

export const startNewAddress = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        const newAddres = {
            title: '',
            body: '',
            secondname: '',
            lastname: '',
            username: '',
            business: '',
            address1: '',
            address2: '',
            address3: '',
            citytown: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/address`).add(newAddres);
        console.log(doc);

        dispatch(activeAddress(doc.id, newAddres));
        dispatch(addNewwAddres(doc.id, newAddres));
    }
}

export const activeAddress = (id, addres) => ({
    type: types.addressActive,
    payload: {
        id,
        ...addres
    }
});

export const addNewwAddres = (id, addres) => ({
    type: types.addressAddNew,
    payload: {
        id, ...addres
    }
})

export const startLoadingAddress = (uid) => {
    return async (dispatch) => {
        const address = await loadAddress(uid);
        dispatch(setAddress(address));
    }
}

export const setAddress = (address) => ({
    type: types.addressLoad,
    payload: address
});

export const startSaveAddres = (addres) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;


        if (!addres.url) {
            delete addres.url;
        }
        const updatedAt = new Date().getTime();
        const addresFirestore = { ...addres, updatedAt };
        delete addresFirestore.id;

        await db.doc(`${uid}/global/address/${addres.id}`).update(addresFirestore);

        dispatch(refreshAddres(addres.id, addresFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', addres.title, 'success');
        dispatch(setActiveAddress());

    }
}

export const refreshAddres = (id, addres) => ({
    type: types.addressUpdated,
    payload: {
        id,
        addres: {
            id,
            ...addres
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        const { active } = getState().address;
        const { spanish } = getState().ui;

        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });


        const fileUrl = await fileUpload(file);
        active.url = fileUrl;
        dispatch(startSaveAddres(active))


        console.log(fileUrl);
        Swal.close();
    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/address/${id}`).delete();

        dispatch(deleteAddres(id));
    }
}

export const deleteAddres = (id) => ({
    type: types.addressDelete,
    payload: id
})

export const addresLogout = () => ({
    type: types.addressLogoutCleaning
})

export const setActiveAddress = () => ({
    type: types.setActiveAddress
})