import React from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";

import { useForm } from '../../hooks/useForm';
import { setError, removeError } from '../../actions/ui';
import { startRegisterWithEmailPassword } from '../../actions/auh';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { en, es } from '../../types/traslate';

export const RegisterScreen = () => {

    const language = localStorage.getItem("language");
    const theme = localStorage.getItem("theme");

    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: '',
        password2: '',
    })

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [values2, setValues2] = React.useState({
        password2: "",
        showPassword2: false,
    });

    const { email, password, password2 } = formValues;

    const handleRegister = (e) => {
        e.preventDefault();

        if (isFormValid()) {
            dispatch(startRegisterWithEmailPassword(email, password));
        }

    }

    //reformular messge alert error y registro
    const isFormValid = () => {

        if (!validator.isEmail(email)) {
            dispatch(setError('Email is not valid'));
            return false;
        } else if (password !== password2 || password.length < 6) {
            dispatch(setError('The password must be at least 5 characteres long.'));
            return false
        }
        dispatch(removeError());
        return true;


    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword2: !values2.showPassword2 });
    };

    return (
        <>
            <div className="auth__logo" >
                <img src='https://i.ibb.co/FgwCn8b/PNG-High-Res-Shield-30-AUG20.png' height="80"
                    width="80" />
            </div>
            <h2 className={theme === "Dark" ? "auth__title_app__dark" : "auth__title_app"}>Lock This</h2>
            <h3 className={theme === "Dark" ? "auth__title__dark" : "auth__title"}>
                {language === 'Español' ? es.register.register : en.register.register}

            </h3>
            <form
                className='animate__animated animate__fadeIn'
                onSubmit={handleRegister}
            >

                {
                    msgError &&
                    (
                        <div className="auth__alert-error">
                            {msgError}
                        </div>
                    )
                }

                <input
                    type="text"
                    placeholder={language === 'Español' ? es.register.email : en.register.email}
                    name="email"
                    className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                />
                <div className="gpassword-field">
                    <input
                        type={values.showPassword ? "text" : "password"}
                        placeholder={language === 'Español' ? es.register.password : en.register.password}
                        name="password"
                        className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                        value={password}
                        onChange={handleInputChange}
                    />
                    <IconButton className="icon-eye" onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>{values.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                </div>
                <div className="gpassword-field">
                    <input
                        type={values2.showPassword2 ? "text" : "password"}
                        placeholder={language === 'Español' ? es.register.confirmPass : en.register.confirmPass}
                        name="password2"
                        className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                        value={password2}
                        onChange={handleInputChange}
                    />
                    <IconButton className="icon-eye" onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}>{values2.showPassword2 ? <Visibility /> : <VisibilityOff />}</IconButton>
                </div>

                <button
                    type="submit"
                    className="btn btn-primary btn-block mb-5"
                >
                    {language === 'Español' ? es.register.btnRegister : en.register.btnRegister}
                </button>
                {/* <hr /> */}


                <Link
                    to="/auth/login"
                    className="link"
                >
                    {language === 'Español' ? es.register.already : en.register.already}
                </Link>

                <h3 className={theme === "Dark" ? "link__dark" : "link"}>v2.0</h3>

            </form>
        </>
    )
}
