import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NothingSelected } from '../NothingSelected'
import { Sidebarlist } from '../../Sidebarlist'
import { NavBarPasswords } from '../NavBar'
import { PasswordScreen1 } from '../../password/PasswordScreen'
import { SidebarPasswords } from './SidebarPassword';
import { Modals } from '../../modal/Modal';

export const PasswordScreen = () => {

    const { active } = useSelector(state => state.passwords)
    const ui = useSelector(state => state.ui);
    const { darkMode } = useSelector(state => state.ui)


    useEffect(() => {
        darkMode ? document.body.style = 'background: #121212;' : document.body.style = 'background: white;';
    }, [darkMode])


    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBarPasswords />
                <main>
                    {
                        (active)
                            ? (<PasswordScreen1 />)
                            : (<NothingSelected type="password" />)
                    }
                </main>
                <Modals type="password" />
                {/* <SidebarPasswords /> */}
            </div>
        </div>
    )
}
