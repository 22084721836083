import React from 'react';
import ReactDOM from 'react-dom';
import  { GlobalAppy }  from './GlobalAppy';
import * as serviceWorker from './serviceWorker';
import './styles/styles.scss';

ReactDOM.render(
    <GlobalAppy />,
  document.getElementById('root')
);


serviceWorker.register();