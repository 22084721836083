import { types } from "../types/types";

export const setHelperEdit = (data) => ({
    type: types.setHelperEdit,
    payload: data
});

export const setModalOpen = (data) => ({
    type: types.setModalOpen,
    payload: data
});

export const setRutas = (data) => ({
    type: types.setRutas,
    payload: data
})

export const setPath = (data) => ({
    type: types.setPath,
    payload: data
})

export const setIsNew = (data) => ({
    type: types.setIsNew,
    payload: data
})


export const setModalCancel = (data) => ({
    type: types.setModalCancel,
    payload: data
});


export const setSavedPhotos = (data) => ({
    type: types.setSavedPhotos,
    payload: data
});

export const setSavedVideo = (data) => ({
    type: types.setSavedVideo,
    payload: data
});








