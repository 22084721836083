import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../actions/auh';
import { Link } from 'react-router-dom';
import { activePhotos, startNewPhotos } from '../../actions/photos';
import * as FaIcons from 'react-icons/fa'
import { en, es } from '../../types/traslate';
import { removeError, setError } from '../../actions/ui';
import { useHistory } from 'react-router-dom';
import { startNewEmails } from '../../actions/emails';
import { startNewPasswords } from '../../actions/passwords';
import { startNewVideos } from '../../actions/videos';
import { startNewNotes } from '../../actions/notes';
import { startNewAddress } from '../../actions/address';
import { setHelperEdit, setIsNew, setSavedPhotos, setSavedVideo } from '../../actions/helpers';

export const NavBar = ({ id, date, title, body, url, mail }) => {

    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.photos);


    //const photoDate = moment(date);
    const ed = [1]
    const dispatch = useDispatch();
    /* const handleEntryClick = () => {
        dispatch(
            activePhotos(id, {
                date, title, body, url
            })
        );
    } */
    //console.log(photoDate)
    console.log('Photo navbar: ' + title)

    const hanleLogout = () => {
        dispatch(startLogout());
    }

    const handleAddNew = () => {
        dispatch(startNewPhotos());
        dispatch(setHelperEdit(false));
        dispatch(removeError());
        dispatch(setIsNew(true));
        dispatch(setSavedPhotos(""));

    };

    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaCamera />
                    <span > {ui.spanish ? es.photoVault.id : en.photoVault.id}</span>
                </h3>

                {
                    active === null
                        ? <>
                            <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                                <i className="pl-2 fa fa-plus-square"></i>
                                <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                            </h3>
                            <div></div>
                            {/*  <button className="btn" onClick={hanleLogout} >
                                 <h3>
                                    <i className="fas fa-sign-out-alt"></i>
                                    <span > {ui.spanish ? es.logout : en.logout}</span>
                                </h3>
                            </button> */}
                        </>
                        : null
                }


            </div>
        </aside >
    )
}

export const NavBarEmails = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.emails)
    const hanleLogout = () => {
        dispatch(startLogout())
    }

    const handleAddNew = () => {
        dispatch(startNewEmails());
        dispatch(setHelperEdit(false));
        dispatch(setError(null));
        dispatch(setIsNew(true));
    };

    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>

                <h3>
                    <FaIcons.FaEnvelope />
                    <span > {ui.spanish ? es.emails.id : en.emails.id}</span>
                </h3>
                {
                    active === null
                        ?
                        <>
                            <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                                <i className="pl-2 fa fa-plus-square"></i>
                                <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                            </h3>
                            <div></div>

                        </>
                        : null
                }
            </div>
        </aside>
    )
}

export const NavBarPasswords = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.passwords);
    const hanleLogout = () => {
        dispatch(startLogout())
    }

    const handleAddNew = () => {
        dispatch(startNewPasswords());
        dispatch(setHelperEdit(false));
        dispatch(setError(null));
        dispatch(removeError());
        dispatch(setIsNew(true));
    };


    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaLock />
                    <span > {ui.spanish ? es.password.id : en.password.id}</span>
                </h3>
                {
                    active === null
                        ?
                        <>
                            <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                                <i className="pl-2 fa fa-plus-square"></i>
                                <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                            </h3>
                            <div></div>
                        </>
                        : null
                }
            </div>
        </aside>
    )
}

export const NavBarVideos = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.videos);
    const hanleLogout = () => {
        dispatch(startLogout())
    }

    const handleAddNew = () => {
        dispatch(startNewVideos());
        dispatch(setHelperEdit(false));
        dispatch(setError(null));
        dispatch(setIsNew(true));
        dispatch(setSavedVideo(""));

    };

    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaVideo />
                    <span > {ui.spanish ? es.videoVault.id : en.videoVault.id}</span>
                </h3>

                {active === null
                    ?
                    <>
                        <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                            <i className="pl-2 fa fa-plus-square"></i>
                            <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                        </h3>
                        <div></div>
                    </>
                    : null
                }
            </div>
        </aside>
    )
}

export const NavBarNotes = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.notes);
    const hanleLogout = () => {
        dispatch(startLogout())
    }

    const handleAddNew = () => {
        dispatch(startNewNotes());
        dispatch(setHelperEdit(false));
        dispatch(setError(null));
        dispatch(setIsNew(true));
    };


    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaStickyNote />
                    <span > {ui.spanish ? es.notes.id : en.notes.id}</span>
                </h3>

                {
                    active === null
                        ?
                        <>
                            <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                                <i className="pl-2 fa fa-plus-square"></i>
                                <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                            </h3>
                            <div></div>
                        </>
                        : null
                }


            </div>
        </aside>
    )
}

export const NavBarAddress = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.address)

    const hanleLogout = () => {
        dispatch(startLogout())
    }

    const handleAddNew = () => {
        dispatch(startNewAddress());
        dispatch(setHelperEdit(false));
        dispatch(setError(null));
        dispatch(setIsNew(true));
    };

    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaMapMarkedAlt className="fa-1x" />
                    <span > {ui.spanish ? es.address.id : en.address.id}</span>
                </h3>
                {
                    active === null
                        ?
                        <>
                            <h3 onClick={handleAddNew} style={{ cursor: 'pointer' }}>
                                <i className="pl-2 fa fa-plus-square"></i>
                                <span>{ui.spanish ? 'Nueva Entrada' : 'New entry'}</span>
                            </h3>
                            <div></div>
                        </>
                        : null
                }
            </div>
        </aside>
    )
}

export const NavBarSettings = () => {

    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const hanleLogout = () => {
        dispatch(startLogout());
        dispatch(setHelperEdit(false));
    }


    return (
        <aside className="global__sidebar__items">
            <div className={ui.darkMode ? "global__sidebar-navbar darkMode" : "global__sidebar-navbar"}>
                <h3>
                    <FaIcons.FaMapMarkedAlt className="fa-1x" />
                    <span > {ui.spanish ? 'Opciones' : en.settings}</span>
                </h3>
                <div></div>
            </div>
        </aside>
    )
}

