import React, { useEffect, useState } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';

import { firebase } from '../firebase/firebase-config'
import { AuthRouter } from './AuthRouter';
import { GlobalScreen } from '../components/globalappy/GlobalScreen';
import { useDispatch } from 'react-redux';
import { login, startLoadingProfile } from '../actions/auh';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { startLoadingPhotos } from '../actions/photos';
import { EmailScreen } from '../components/globalappy/email/EmailsScreen';
import { startLoadingEmails } from '../actions/emails';
import { startLoadingPasswords } from '../actions/passwords';
import { PasswordScreen } from '../components/globalappy/password/PasswordsScreen';
import { startLoadingVideos } from '../actions/videos';
import { VideoScreen } from '../components/globalappy/video/VideosScreen';
import { startLoadingNotes } from '../actions/notes';

import { startLoadingAddress } from '../actions/address';
import { AddresScreen } from '../components/globalappy/address/AddresScreen';

import { SettingScreen } from '../components/globalappy/settings/SettingScreen';
import { NoteScreen } from '../components/globalappy/note1/NotesScreen';

export const AppRouter = () => {
    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            console.log("testfff")
            console.log(user?.uid)
            if (user?.uid) {
                // dispatch(login(user.uid));
                await dispatch(startLoadingProfile(user.uid));
                setIsLoggedIn(true);
                dispatch(startLoadingPhotos(user.uid));
                dispatch(startLoadingEmails(user.uid));
                dispatch(startLoadingPasswords(user.uid));
                dispatch(startLoadingVideos(user.uid));
                dispatch(startLoadingNotes(user.uid));
                dispatch(startLoadingAddress(user.uid));
            } else {
                setIsLoggedIn(false);
            }

            setChecking(false);
        });

    }, [dispatch, setChecking, setIsLoggedIn])

    if (checking) {
        return (
            <h1>Please Wait...</h1>
        )
    }

    return (
        <Router>
            <div>
                <Switch>
                    <PublicRoute
                        path="/auth"
                        component={AuthRouter}
                        isAuthenticated={isLoggedIn}
                    />

                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/photos"
                        component={GlobalScreen}
                    />


                    { 
                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/emails"
                        component={EmailScreen}
                    />}

                    {  <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/password"
                        component={PasswordScreen}
                    /> }

                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/videos"
                        component={VideoScreen}
                    />
                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/notes"
                        component={NoteScreen}
                    />
                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/address"
                        component={AddresScreen}
                    />

                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/settings"
                        component={SettingScreen}
                    />
                    <Redirect to="/auth/login" />
                </Switch>
            </div>
        </Router>
    )
}
