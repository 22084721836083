import React from 'react'
import { useSelector } from 'react-redux'
import { GlobalEntry } from './GlobalEntry';

export const GlobalEntries = () => {

    const { photos } = useSelector(state => state.photos);
    //console.log(photos)

    return (
        <div className = "global__entries">
            {
                photos.map(photo => (
                    <GlobalEntry 
                        key = { photo.id }
                        {...photo}

                    />
                ))
            }
        </div>
    )
}
