import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { activePhotos } from '../../actions/photos';
import 'moment/locale/es';
import 'moment/locale/en-au';
import { removeError } from '../../actions/ui';
import { setHelperEdit, setIsNew, setModalOpen } from '../../actions/helpers';


export const GlobalEntry = ({ id, date, title, body, url }) => {

    const photoDate = moment(date).locale('en-au');
    const photoDateEs = moment(date).locale('es');


    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);

    const handleEntryClick = () => {
        dispatch(
            activePhotos(id, {
                date, title, body, url
            })
        );

        dispatch(removeError());
        //dispatch(setHelperEdit(true));
        dispatch(setModalOpen(false));
        dispatch(setIsNew(false));


    }
    //console.log(photoDate)
    return (
        <div
            className={ui.darkMode ? "global__entry  global__entry__darkMode pointer animate__animated animate__fadeIn" : "global__entry pointer animate__animated animate__fadeIn"}
            onClick={handleEntryClick}
        >
            {
                url &&
                <div
                    className="global__entry-picture"
                    style={{
                        backgroundSize: 'cover',
                        backgroundImage: `url(${url})`
                    }}
                >
                </div>
            }
            <div className="w150" style={{ width: '50%' }}>
                <div className="global__entry-body">
                    <p className={ui.darkMode ? "global__entry-title__darkMode" : "global__entry-title"}>
                        {title}
                    </p>
                    <p className="global__entry-content">
                        {body}
                    </p>
                </div>
                {/* <div className={ui.darkMode ? "global__entry-date-box__darkMode" : "global__entry-date-box"}>
                    <span>{ui.spanish ? photoDateEs.format('dddd') : photoDate.format('dddd')}</span>
                    <h4>{ui.spanish ? photoDateEs.format('Do') : photoDate.format('Do')}</h4>
                </div> */}
            </div>
        </div>
    )
}
