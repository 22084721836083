import React from 'react'
import { Sidebar } from './Sidebar'
import { NoteScreen } from '../notes/NoteScreen'
import { useSelector } from 'react-redux'
import { NothingSelected } from './NothingSelected'
import { Sidebarlist } from '../Sidebarlist'
import { NavBar } from './NavBar'
import { Modals } from '../modal/Modal'


export const GlobalScreen = () => {
    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.photos)

    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBar />
                <main>
                    {
                        (active)
                            ? (<NoteScreen />)
                            : (<NothingSelected type="photo" />)
                    }
                </main>
                <Modals type="photo"  />
                {/* <Sidebar /> */}
            </div>

        </div>
    )
}
