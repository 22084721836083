import { types } from '../types/types';

const darkMode = localStorage.getItem("theme");
const language = localStorage.getItem("language");

const initialState = {
    loading: false,
    msgError: null,
    darkMode: darkMode === "Dark" ? true : false,
    spanish: language === "English" ? false : true,
    
}



export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }
        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        case types.setModeDark:
            return {
                ...state,
                darkMode: action.payload
            }

        case types.setTraslate:
            return {
                ...state,
                spanish: action.payload
            }


        default:
            return state;
    }

}