import React, { Component } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

import { Link } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import * as FaIcons from 'react-icons/fa'
import { startLoginEmailPassword, startGoogleLogin } from '../../actions/auh'
import { render } from '@testing-library/react'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { en, es } from '../../types/traslate';



export const LoginScreen = () => {

    const language = localStorage.getItem("language");
    const theme = localStorage.getItem("theme");


    /* const ui = useSelector(state => state.ui);
    localStorage.setItem('language', ui.spanish ? 'Español' : 'English');
    localStorage.setItem('theme', ui.darkMode ? 'Dark' : 'Lite'); */

    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui)

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    });

    const { email, password } = formValues;
    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password));
    }

    const handleGoogleLogin = () => {
        dispatch(startGoogleLogin());
    }

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };


    return (

        <>
            <div className="auth__logo" >
                <img src='https://i.ibb.co/FgwCn8b/PNG-High-Res-Shield-30-AUG20.png' height="80"
                    width="80" />
            </div>
            <h2 className={theme === "Dark" ? "auth__title_app__dark" : "auth__title_app"}>Lock This</h2>
            <h3 className={theme === "Dark" ? "auth__title__dark" : "auth__title"}>
                {language === 'Español' ? es.login.login : en.login.login}
            </h3>
            <form
                className='animate__animated animate__fadeIn'
                onSubmit={handleLogin}
            >
                <input
                    type="text"
                    placeholder={language === 'Español' ? es.login.email : en.login.email}
                    name="email"
                    className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                />
                <div className="gpassword-field">
                    <input
                        type={values.showPassword ? "text" : "password"}
                        id="password-field"
                        placeholder={language === 'Español' ? es.login.password : en.login.password}
                        name="password"
                        className={theme === "Dark" ? "auth__input__dark" : "auth__input"}
                        value={password}
                        onChange={handleInputChange}
                    />
                    <IconButton className="icon-eye" onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>{values.showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={loading}
                >
                    {language === 'Español' ? es.login.btnLogin : en.login.btnLogin}
                </button>
                {/* <hr /> */}

                <div className="auth__social-networks">
                    {language === 'Español' ? es.login.url : en.login.url}
                    <div
                        className="google-btn"
                        onClick={handleGoogleLogin}
                    >
                        <div className="google-icon-wrapper">
                            <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="google button" />
                        </div>
                        <p className="btn-text">
                            {language === 'Español' ? es.login.signInGoogle : en.login.signInGoogle}
                        </p>
                    </div>
                    <Link
                        to="/auth/register"
                        className="link"
                    >
                        {language === 'Español' ? es.login.createNew : en.login.createNew}
                    </Link>
                    <Link
                        to="/auth/forgot"
                        className="link"
                    >
                        {language === 'Español' ? es.login.forgotPassword : en.login.forgotPassword}
                    </Link>

                    <h3 className={theme === "Dark" ? "link__dark" : "link"}>v2.0</h3>
                </div>



            </form>
        </>
    )
}
