import {db} from '../firebase/firebase-config'

export const loadProfile = async (uid) => {
    const reference = db.collection(`${uid}`);
    const response = await reference.doc('profile').get();
    return response.exists ? response.data()['image'] : '';
}

export const uploadProfileImage = async (uid, urlImage) => {
    const reference = db.collection(`${uid}`);
    const profileSnap = await reference.doc('profile').get();
    if (profileSnap.exists) {
        await reference.doc('profile').update({'image': urlImage})
    } else {
        await reference.doc('profile').set({'image': urlImage})
    }
}