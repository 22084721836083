import {firebase, googleAuthProvider} from '../firebase/firebase-config'
import {types} from '../types/types'
import {startLoading, finishLoading} from './ui'
import Swal from 'sweetalert2'
import {photoLogout, setPhotos} from './photos'
import {videoLogout} from './videos'
import {emailLogout} from './emails'
import {passwordLogout} from './passwords'
import {noteLogout} from './notes'
import {addresLogout} from './address'
import {loadProfile} from "../helpers/loadProfile";
import {loadPhotos} from "../helpers/loadPhotos";
import {useHistory} from "react-router-dom";

export const startLoginEmailPassword = (email, password) => {
    return (dispatch) => {

        dispatch(startLoading());

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(async ({user}) => {
                const profile = await loadProfile(user.uid);
                console.log("profile24")
                console.log(profile)
                dispatch(
                    login(user.uid, '', profile.image)
                )
                // dispatch(
                //     login(user.uid)
                // );
                dispatch(
                    finishLoading()
                );
            })
            .catch(e => {
                console.log(e);
                dispatch(
                    finishLoading());
                Swal.fire('Error', e.message, 'error');
            });
    }
}

export const startRegisterWithEmailPassword = (email, password) => {
    return (dispatch) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(({user}) => {
                dispatch(
                    login(user.uid)
                );
            })
            .catch(e => {
                console.log(e);
                Swal.fire('Error', e.message, 'error');
            });
    }
}
export const startForgot = (email, history) => {
    return (dispatch) => {
        dispatch(startLoading());
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch(
                    forgot()
                );
                dispatch(finishLoading());
                Swal.fire('Sent', 'Check your email to reset your password');
                history.push('/auth/login');

            })
            .catch(e => {
                console.log(e);
                Swal.fire('Error', e.message, 'error');
            });
    }
}

export const startGoogleLogin = () => {
    return (dispatch) => {
        firebase.auth().signInWithPopup(googleAuthProvider)
            .then(({user}) => {
                dispatch(
                    login(user.uid, user.displayName)
                )
            });
    }
}

export const login = (uid, displayName, image) => ({
    type: types.login,
    payload: {
        uid,
        displayName,
        image
    }
});

export const startLogout = () => {
    return async (dispatch) => {
        await firebase.auth().signOut();

        dispatch(logout());
        dispatch(photoLogout());
        dispatch(videoLogout());
        dispatch(emailLogout());
        dispatch(passwordLogout());
        dispatch(noteLogout());
        dispatch(addresLogout());

    }
}

export const logout = () => ({
    type: types.logout
})

export const forgot = () => ({
    type: types.forgot
})
export const startLoadingProfile = (uid) => {
    return async (dispatch) => {
        const image = await loadProfile(uid);
        await dispatch(login(uid, '', image))
    }
}
