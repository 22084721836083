import Swal from 'sweetalert2'

import { db } from "../firebase/firebase-config";
import { types } from "../types/types";
import { loadPhotos } from "../helpers/loadPhotos";
import { fileUpload } from '../helpers/fileUpload';
import { setHelperEdit, setSavedPhotos } from './helpers';

export const startNewPhotos = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        const newPhoto = {
            title: '',
            body: '',
            date: new Date().getTime()
        }

        const doc = await db.collection(`${uid}/global/photos`).add(newPhoto);
        console.log(doc);

        dispatch(activePhotos(doc.id, newPhoto));
        dispatch(addNewwPhoto(doc.id, newPhoto));
    }
}

export const activePhotos = (id, photo) => ({
    type: types.photosActive,
    payload: {
        id,
        ...photo
    }
});

export const addNewwPhoto = (id, photo) => ({
    type: types.photosAddNew,
    payload: {
        id, ...photo
    }
})

export const startLoadingPhotos = (uid) => {
    return async (dispatch) => {
        const photos = await loadPhotos(uid);
        dispatch(setPhotos(photos));
    }
}

export const setPhotos = (photos) => ({
    type: types.photosLoad,
    payload: photos
});

export const startSavePhoto = (photo) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { spanish } = getState().ui;
        const { photoUrl } = getState().helpers;


        if (!photo.url) {
            delete photo.url;
        }

        if(photoUrl !== "" && photo.url){
            photo.url = photoUrl;
        }else if(photoUrl !== "" && !photo.url){
            photo.url = photoUrl;
        }

        /* photo.url = photo.url ? photo.url : photoUrl; */
        const updatedAt = new Date().getTime();
        const photoFirestore = { ...photo, updatedAt };
        delete photoFirestore.id;

        await db.doc(`${uid}/global/photos/${photo.id}`).update(photoFirestore);
        dispatch(refreshPhoto(photo.id, photoFirestore));
        Swal.fire(spanish ? 'Guardado' : 'Saved', photo.title, 'success');
        
        dispatch(setActivePhoto());
        dispatch(setSavedPhotos(""));
        dispatch(setHelperEdit(false));


    }
}

export const refreshPhoto = (id, photo) => ({
    type: types.photosUpdated,
    payload: {
        id,
        photo: {
            id,
            ...photo
        }
    }
});

export const startUploading = (file) => {
    return async (dispatch, getState) => {
        /*  const { active } = getState().photos; */
        const { spanish } = getState().ui;

        Swal.fire({
            title: spanish ? 'Subiendo' : 'Uploading...',
            text: spanish ? 'Espere por favor' : 'Please wait',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });

        const fileUrl = await fileUpload(file);
        /* active.url = fileUrl; */
        dispatch(setSavedPhotos(fileUrl));
        Swal.close();
        /*  dispatch(startSavePhoto(active)) */

    }
}

export const startDeleting = (id) => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        await db.doc(`${uid}/global/photos/${id}`).delete();
        dispatch(setSavedPhotos(""));
        dispatch(deletePhoto(id));
    }
}

export const deletePhoto = (id) => ({
    type: types.photosDelete,
    payload: id
})

export const photoLogout = () => ({
    type: types.photosLogoutCleaning
})

export const setActivePhoto = () => ({
    type: types.setActivePhoto
})