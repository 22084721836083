import { types } from "../types/types"

const initialState = {
    address: [],
    active: null
}

export const addressReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.addressActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.addressAddNew:
            return {
                ...state,
                address: [action.payload, ...state.address]
            }
        case types.addressLoad:
            return {
                ...state,
                address: [...action.payload]
            }
        case types.addressUpdated:
            return {
                ...state,
                address: state.address.map(
                    addres => addres.id === action.payload.id
                        ? action.payload.addres
                        : addres
                ),
                active: action.payload.addres
            }
        case types.addressDelete:
            return {
                ...state,
                active: null,
                address: state.address.filter(addres => addres.id !== action.payload)
            }
        case types.addressLogoutCleaning:
            return {
                ...state,
                active: null,
                notes: []
            }

        case types.setActiveAddress:
            return {
                ...state,
                active: null
            }
            
        default:
            return state
    }

}

