export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    forgot: '[Auth] forgot',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    setModeDark: '[UI] Set mode dark',

    setTraslate: '[UI] Set translate',


    photosAddNew: '[Photos] New photos',
    photosActive: '[Photos] Set active photo',
    photosLoad: '[Photos] Load photos note',
    photosUpdated: '[Photos] Updated photo note',
    photosFileUrl: '[Photos] Updated image url',
    photosDelete: '[Photos] Delete photo note',
    photosLogoutCleaning: '[Photos] Logout Cleaning',

    setActivePhoto: '[Photos] Clear Active Photo',

    emailsAddNew: '[Emails] New emails',
    emailsActive: '[Emails] Set active email',
    emailsLoad: '[Emails] Load emails note',
    emailsUpdated: '[Emails] Updated email note',
    emailsFileUrl: '[Emails] Updated image url',
    emailsDelete: '[Emails] Delete email note',
    emailsLogoutCleaning: '[Emails] Logout Cleaning',

    setActiveEmail: '[Emails] Clear Active Email',

    passwordsAddNew: '[Passwords] New passwords',
    passwordsActive: '[Passwords] Set active password',
    passwordsLoad: '[Passwords] Load passwords note',
    passwordsUpdated: '[Passwords] Updated password note',
    passwordsFileUrl: '[Passwords] Updated image url',
    passwordsDelete: '[Passwords] Delete password note',
    passwordsLogoutCleaning: '[Passwords] Logout Cleaning',

    setActivePassword: '[Passwords] Clear Active Password',

    videosAddNew: '[Videos] New videos',
    videosActive: '[Videos] Set active video',
    videosLoad: '[Videos] Load videos note',
    videosUpdated: '[Videos] Updated video note',
    videosFileUrl: '[Videos] Updated image url',
    videosDelete: '[Videos] Delete video note',
    videosLogoutCleaning: '[Videos] Logout Cleaning',

    setActiveVideo: '[Videos] Clear Active Video',

    notesAddNew: '[Notes] New notes',
    notesActive: '[Notes] Set active note',
    notesLoad: '[Notes] Load notes note',
    notesUpdated: '[Notes] Updated note note',
    notesFileUrl: '[Notes] Updated image url',
    notesDelete: '[Notes] Delete note note',
    notesLogoutCleaning: '[Notes] Logout Cleaning',

    setActiveNotes: '[Notes] Clear Active Notes',

    addressAddNew: '[Address] New address',
    addressActive: '[Address] Set active address',
    addressLoad: '[Address] Load address note',
    addressUpdated: '[Address] Updated address note',
    addressFileUrl: '[Address] Updated image url',
    addressDelete: '[Address] Delete address note',
    addressLogoutCleaning: '[Address] Logout Cleaning',

    setActiveAddress: '[Address] Clear Active Address',


    setHelperEdit: '[Helper] Set Helper Edit',
    setModalOpen: '[Helper] Set Modal Open',

    setRutas: '[Rutas] Set Rutas',

    setPath: '[Rutas] Set Path',

    setIsNew: '[Rutas] Set isNew',

    setModalCancel: '[Rutas] Set Modal Cancel',

    setSavedPhotos: '[Rutas] Set Saved Photos',

    setSavedVideo: '[Rutas] Set Saved Video',


}