import { types } from "../types/types"

const initialState = {
    passwords: [],
    active: null
}

export const passwordReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.passwordsActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        case types.passwordsAddNew:
            return {
                ...state,
                passwords: [action.payload, ...state.passwords]
            }
        case types.passwordsLoad:
            return {
                ...state,
                passwords: [...action.payload]
            }
        case types.passwordsUpdated:
            return {
                ...state,
                passwords: state.passwords.map(
                    password => password.id === action.payload.id
                        ? action.payload.password
                        : password
                ),
                active: action.payload.password
            }
        case types.passwordsDelete:
            return {
                ...state,
                active: null,
                passwords: state.passwords.filter(password => password.id !== action.payload)
            }
        case types.passwordsLogoutCleaning:
            return {
                ...state,
                active: null,
                notes: []
            }

        case types.setActivePassword:
            return {
                ...state,
                active: null,
            }

        default:
            return state
    }

}

