import React, { useRef, useEffect, useState } from 'react'
import { NotesAppBar } from './NotesAppBar'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { activePhotos, setActivePhoto, startDeleting, startSavePhoto, startUploading } from '../../actions/photos';

import { en, es } from '../../types/traslate'
import { removeError, setError } from '../../actions/ui'
import 'moment/locale/es';
import 'moment/locale/en-au';
import moment from "moment";
import { setHelperEdit, setModalCancel, setModalOpen } from '../../actions/helpers'



export const NoteScreen = () => {

    const dispatch = useDispatch();
    const [file, setFile] = useState(null);

    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.photos);
    const { photos } = useSelector(state => state.photos);


    const [formValues, handleInputChange, reset] = useForm(active);
    const { body, title, id } = formValues;
    const { msgError } = useSelector(state => state.ui);

    const { isNew, photoUrl } = useSelector(state => state.helpers);


    const activeId = useRef(active.id);
    const [err, setErr] = useState({
        title: false,
        file: false,
    });

    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');


    useEffect(() => {
        if (active.id !== activeId.current) {
            reset(active);
            activeId.current = active.id;
        }
    }, [active, reset])

    useEffect(() => {
        dispatch(activePhotos(formValues.id, { ...formValues }));
    }, [formValues, dispatch]);

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const handleVolver = () => {
        if (isNew) {
            if (title.trim().length === 0) {
                setErr({ ...err, title: true });
                dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'));
            } else if (!active.url && photoUrl === "") {
                setErr({ ...err, file: true });
                dispatch(setError(ui.spanish ? 'Se requiere imagen' : 'Image is required'));
            }

            dispatch(setModalCancel(true));

        } else if (photos.length > 0) {
            const photo = photos.filter(photo => photo.id === active.id)[0];
            if (photo?.title !== active.title || photo?.body !== active.body || photo?.url !== active.url) {
                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));
            } else {
                dispatch(setActivePhoto());
            }
        }

        //dispatch(setActivePhoto());
        //dispatch(setHelperEdit(false));
    }


    const handleSave = () => {
        if (isFormValid()) {
            dispatch(startSavePhoto(active));
            /*  dispatch(startUploading(file)); */
        }
        //setFile(null);
    }


    const isFormValid = () => {
        if (title.trim().length === 0) {
            setErr({ ...err, title: true });
            dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))
            return false;
        }

        if (!active.url && photoUrl === "") {
            setErr({ ...err, file: true });
            dispatch(setError(ui.spanish ? 'Se requiere imagen' : 'Image is required'))
            return false;
        }

        dispatch(removeError());
        return true;
    }

    useEffect(() => {
        if (photoUrl !== "") {
            dispatch(setError(ui.spanish ? 'Imagen cargada' : 'Uploaded image'))
        }

    }, [photoUrl, dispatch, ui.spanish])



    useEffect(() => {

        if (photos.length > 0) {
            const photo = photos.filter(photo => photo.id === active.id)[0];

            if (photo?.title !== active.title || photo?.body !== active.body || photo?.url !== active.url || (photoUrl !== "" && active.url)) {
                dispatch(setHelperEdit(true));
            }
        }


    }, [active.id, photos, active, dispatch, photoUrl])


    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>
            {/* <NotesAppBar date={active.date} /> */}

            <div className="notes__content">
                <div className="notes__content-col">
                    <div className={ui.darkMode ? 'form__group__darkMode ' : 'form__group '} style={{ border: err.title && active.title === "" ? '1px solid red' : '' }}>
                        <input
                            type="text"
                            placeholder={ui.spanish ? es.photoVault.form.name : en.photoVault.form.name}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            autoComplete="off"
                            name="title"

                            value={title}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.photoVault.form.name : en.photoVault.form.name}</label>

                    </div>
                    <br />
                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <textarea
                            placeholder={ui.spanish ? es.photoVault.form.note : en.photoVault.form.note}
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            name="body"
                            value={body}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.photoVault.form.note : en.photoVault.form.note}</label>


                    </div>

                    {/* <select className = "notes__title-input">
                        <option value ="Banking">Banking</option>
                        <option value ="Bussiness">Bussiness</option>
                        <option value ="Dining">Dining</option>
                        <option value ="E-Commerce">E-Commerce</option>
                        <option value ="Education">Education</option>
                        <option value ="Productivity Tools">Prudctivity Tools</option>
                        <option value ="Secure Notes">Secure Notes</option>
                        <option value ="Shopping">Shopping</option>
                        <option value ="Social Media">Social Media</option>
                        <option value ="Telecom">Telecom</option>
                        <option value ="TimeSheets">TimeSheets</option>
                    </select> */}
                </div>
                {
                    (active.url && photoUrl === "")
                        ? <div className="notes__image">
                            <img
                                src={active.url}
                                alt="imagen"
                            />
                        </div>
                        : (active.url && photoUrl)

                            ? <div className="notes__image">
                                <img
                                    src={photoUrl}
                                    alt="imagen"
                                />
                            </div> : (photoUrl && !active.url)
                                ? <div className="notes__image">
                                    <img
                                        src={photoUrl}
                                        alt="imagen"
                                    />
                                </div>
                                : null

                }
            </div>

            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"} style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }

            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3  >
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.photoVault.form.save : en.photoVault.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.photoVault.form.delete : en.photoVault.form.delete} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <NotesAppBar /* setFile={setFile}  */ /* date={active.date} */ />
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>


            </div>


            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>




        </div>

    )
}
