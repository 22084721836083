import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTraslate } from '../../../actions/ui';

import { en, es } from '../../../types/traslate';
import { Sidebarlist } from '../../Sidebarlist';
import { NavBarSettings } from '../NavBar';
import { SettingAppBar } from './SettingAppBar';

import Select from 'react-select';

export const SettingScreen = () => {
    const ui = useSelector(state => state.ui);
    const dispatch = useDispatch();



    const onChangeSelected = (e) => {
        if (e.value === "true") {
            dispatch(setTraslate(true));
            localStorage.setItem('language', 'Español');
        } else {
            dispatch(setTraslate(false));
            localStorage.setItem('language', 'English');
        }
    }

    return (
        <div className="global__main-content animate__animated animate__fadeIn">
            <div className="global__main_sidebar" >
                <Sidebarlist />
            </div>
            <div className={ui.darkMode ? "global__main-col darkMode" : "global__main-col"} >
                <NavBarSettings />
                <main>
                    <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>
                        {/* <SettingAppBar /> */}
                        <div className="notes__content">
                            <div className="notes__content-col">
                                <h3 style={{ color: ui.darkMode ? '#d2d3d0' : '#363636' }}>
                                    {ui.spanish ? es.language : en.language}
                                </h3>
                                <br />
                                <Select
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            width: '30%',
                                            backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                            color: ui.darkMode ? '#fff' : '#121212',
                                            border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #d2d3d0',
                                                boxShadow: 'none',
                                            },
                                            '&:active': {
                                                border: '1px solid #d2d3d0',
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                border: '1px solid #d2d3d0',
                                                boxShadow: 'none',
                                            },
                                        }),
                                        menu: (base, state) => ({
                                            ...base,
                                            width: '30%',
                                            backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                            color: ui.darkMode ? '#fff' : '#121212', border: '1px solid #d2d3d0',
                                            boxShadow: 'none',
                                            '&:active': {
                                                border: '1px solid #121212',
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                border: '1px solid #121212',
                                                boxShadow: 'none',
                                            },
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            backgroundColor: ui.darkMode ? '#121212' : '#fff',
                                            color: ui.darkMode ? '#fff' : '#121212',
                                            boxShadow: 'none',
                                            '&:active': {
                                                border: '1px solid #d2d3d0',
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                border: '1px solid #d2d3d0',
                                                boxShadow: 'none',
                                            },
                                        }),
                                    }}
                                    placeholder={ui.spanish ? 'Spanish' : 'English'}
                                    value={ui.spanish}
                                    onChange={(e) => onChangeSelected(e)}
                                    options={[
                                        { value: 'false', label: ui.spanish ? 'Inglés' : 'English' },
                                        { value: 'true', label: ui.spanish ? 'Español' : 'Spanish' },
                                    ]} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
