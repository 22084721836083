import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from '../../hooks/useForm'
import { startSaveNote, activeNotes, startDeleting, setActiveNotes } from '../../actions/notes'
import { NotesAppBar1 } from './NotesAppBar1'
import { en, es } from '../../types/traslate'
import { removeError, setError } from '../../actions/ui'
import 'moment/locale/es';
import 'moment/locale/en-au';
import moment from "moment";
import { setHelperEdit, setModalCancel, setModalOpen } from '../../actions/helpers'

export const NoteScreen1 = () => {

    const dispatch = useDispatch();


    const handleSave = () => {
        if (isFormValid()) {
            dispatch(startSaveNote(active));
            dispatch(setHelperEdit(false));
        }
    }

    const ui = useSelector(state => state.ui);
    const { active } = useSelector(state => state.notes);
    const { notes } = useSelector(state => state.notes);
    const [formValues, handleInputChange, reset] = useForm(active);
    const { body, title, id } = formValues;
    const { msgError } = useSelector(state => state.ui);

    const { isNew } = useSelector(state => state.helpers);

    const updatedEN = moment(active.updatedAt ?? active.date).locale('en-au').format('Do YYYY MMMM, h:mm:ss a');
    const updatedES = moment(active.updatedAt ?? active.date).locale('es').format('Do YYYY MMMM, h:mm:ss a');

    const activeId = useRef(active.id);
    const [err, setErr] = useState({
        title: false,
    });


    useEffect(() => {
        if (active.id !== activeId.current) {
            reset(active);
            activeId.current = active.id
        }
    }, [active, reset])

    useEffect(() => {
        dispatch(activeNotes(formValues.id, { ...formValues }));
    }, [formValues, dispatch])

    const handleDelete = () => {
        dispatch(startDeleting(id));
        dispatch(setHelperEdit(false));
    }

    const isFormValid = () => {

        if (title.trim().length === 0) {
            setErr({ ...err, title: true });
            dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'))
            return false;
        }

        dispatch(removeError());
        return true;
    }

    const handleVolver = () => {
        if (isNew) {
            if (title.trim().length === 0) {
                setErr({ ...err, title: true });
                dispatch(setError(ui.spanish ? 'Se requiere el nombre' : 'Name is required'));
            }

            dispatch(setModalCancel(true));


        } else if (notes.length > 0) {
            const filterNotes = notes.filter(note => note.id === active.id)[0];
            if (filterNotes?.title !== active.title || filterNotes?.body !== active.body) {
                dispatch(setHelperEdit(true));
                dispatch(setModalOpen(true));
            } else {
                dispatch(setActiveNotes());
            }
        }
        //dispatch(setHelperEdit(false));
    }

    useEffect(() => {

        if (notes.length > 0) {
            const filterNotes = notes.filter(note => note.id === active.id)[0];
            if (filterNotes?.title !== active.title || filterNotes?.body !== active.body) {

                dispatch(setHelperEdit(true));
            }
        }


    }, [active.id, notes, active, dispatch])

    return (
        <div className={ui.darkMode ? "notes__main-content darkMode" : "notes__main-content"}>
            {/* <NotesAppBar1 date={active.date} /> */}

            <div className="notes__content">
                <div className="notes__content-col">
                    {/*  <div className={ui.darkMode ? "notes__label__darkMode" : "notes__label"}>
                        {ui.spanish ? es.notes.form.name : en.notes.form.name}
                    </div> */}

                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} style={{ border: err.title && active.title === "" ? '1px solid red' : '' }} >
                        <input
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            type="text"
                            placeholder={ui.spanish ? es.notes.form.name : en.notes.form.name}
                            autoComplete="off"
                            name="title"
                            value={title}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.notes.form.name : en.notes.form.name}</label>
                    </div>
                    <br />
                    <div className={ui.darkMode ? 'form__group__darkMode' : 'form__group'} >
                        <textarea
                            className={ui.darkMode ? "form__field__darkMode" : "form__field"}
                            placeholder={ui.spanish ? es.notes.form.notes : en.notes.form.notes}
                            autoComplete="off"
                            name="body"
                            value={body}
                            onChange={handleInputChange}
                        />
                        <label className="form__label">{ui.spanish ? es.notes.form.notes : en.notes.form.notes}</label>
                    </div>


                    {/*   <div className={ui.darkMode ? "notes__label__darkMode" : "notes__label"}>
                        {ui.spanish ? es.notes.form.notes : en.notes.form.notes}
                    </div>
                    <textarea
                        placeholder={ui.spanish ? es.notes.form.notes : en.notes.form.notes}
                        className={ui.darkMode ? "notes__textarea__darkMode" : "notes__textarea"}
                        name="body"
                        value={body}
                        onChange={handleInputChange}
                    ></textarea> */}
                </div>
                {
                    (active.url) &&
                    <div className="notes__image">
                        <img
                            src={active.url}
                            alt="imagen"
                        />
                    </div>
                }
            </div>
            {
                msgError &&
                (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={ui.darkMode ? "auth__alert-error__dark" : "auth__alert-error"} style={{ width: '300px', textAlign: 'center' }}>
                            {msgError}
                        </div>
                    </div>
                )
            }


            <div className='notes__content'>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-save"
                        onClick={handleSave}
                    >
                        <h3  >
                            <i className="far fa-save"></i>
                            <span> {ui.spanish ? es.notes.form.save : en.notes.form.save} </span>
                        </h3>
                    </button>
                </div>
                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleDelete}
                    >
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? es.notes.form.delete : en.notes.form.delete} </span>
                        </h3>
                    </button>
                </div>

                <div className="notes__btn-content mb-5">
                    <button
                        className="btn btn-danger"
                        onClick={handleVolver}>
                        <h3  >
                            <i className="far fa-trash-alt"></i>
                            <span> {ui.spanish ? 'Cancelar' : 'Cancel'} </span>
                        </h3>
                    </button>
                </div>


            </div>
            <div style={{ color: ui.darkMode ? '#c4bdbd' : '#000', marginLeft: '20px', marginBottom: '20px' }}>
                <span> {ui.spanish ? `Actualizado: ${updatedES}` : `Updated: ${updatedEN}`} </span>
            </div>
        </div>
    )
}
